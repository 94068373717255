import React, { useEffect, useState } from "react";
import { PublicTokenContext } from "./PublicTokenContext";
import { postTokenCliente } from "../aut/services/postTokenCliente";
import Spinner from "../ui/components/utils/Spinner";
import { Box } from "@mui/material";

const PublicTokenProvider = ({ children }) => {
  const [tokenCliente, setTokenCliente] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Eliminar la cookie JSESSIONID
    document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.omnivisionseguridad.com";
    asyncPostTokenCliente();
  }, []);

  const asyncPostTokenCliente = async () => {
    setIsLoading(true);
    try {
      const tokenPublico = await postTokenCliente();
      if (tokenPublico) {
        setTokenCliente(tokenPublico);
      } else {
        setTokenCliente(null);
      }
    } catch (err) {
      console.log(err);
      setTokenCliente(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <PublicTokenContext.Provider value={{ tokenCliente, isLoading }}>
      {children}
    </PublicTokenContext.Provider>
  );
};

export default PublicTokenProvider;
