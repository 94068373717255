import { Link as RouterLink } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Link,
  InputAdornment,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  CircularProgress,
  Backdrop,
  Alert,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { AutLayout } from "../layout/AutLayout";
import { useFormulario } from "../../hooks/useFormulario";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import CustomCaptcha from "../../ui/components/utils/CustomCaptcha"; // Asegúrate de que esta ruta es correcta
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";

export const RecuperarPage = () => {
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarCompletado, setMostrarCompletado] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [erroresForm, setErroresForm] = useState({ nif: "" });
  const [captchaValido, setCaptchaValido] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(true);

  const { cad, idioma } = useContext(IdiomaContext);
  const { cfg } = useContext(AppContext);

  const { estadoForm, onCambiaCampo } = useFormulario({ nif: "", tipo: "" });
  const [selectTipoNif, setSelectTipoNif] = useState(false);
  const [tipoResidente, setTipoResidente] = useState("");

  const esMovil = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    loadCaptchaEnginge(8); // Cargar el captcha con un código de 8 caracteres
  }, []);

  const isValidNIF = (nif) => {
    const nifRegex = /^[0-9]{8}[A-Z]$/;
    return nifRegex.test(nif);
  };

  const isValidCIF = (cif) => {
    const cifRegex = /^[ABCDEFGHJNPQRSUVW][0-9]{7}[A-J0-9]$/i;
    return cifRegex.test(cif);
  };

  const validarNif = (value) => {
    const resultado =
      !value || value.length <= 5 ? cad("ERR_NIF_CIF_INCORRECTO") : "";
    return resultado;
  };

  const onBlurNif = ({ target }) => {
    const { name, value } = target;
    const msg = validarNif(value);
    const isNIF = isValidNIF(value);
    const isCIF = isValidCIF(value);

    setSelectTipoNif(isNIF);

    setErroresForm((prev) => ({
      ...prev,
      [name]: msg,
    }));

    if (isNIF) {
      setTipoResidente("");
    } else if (isCIF) {
      setTipoResidente("E"); // Empresas solo tienen CIF
    } else {
      setTipoResidente("");
    }
  };

  const handleCaptchaChange = (value) => {
    const esValido = validateCaptcha(value, false);
    setCaptchaValido(esValido);
    setErroresForm((previo) => ({
      ...previo,
      captcha: esValido ? null : cad("ERR_CAMPO_OBLIGATORIO"),
    }));
    if (esValido) {
      setCaptchaVisible(false);
    }
  };

  const isButtonDisabled = () => {
    const { nif } = estadoForm;
    const isNIF = isValidNIF(nif);
    const isCIF = isValidCIF(nif);

    if (isNIF) {
      return !nif || !estadoForm.tipo || !captchaValido;
    }

    if (isCIF) {
      return !nif || !captchaValido;
    }

    return true;
  };

  const onCambiaNif = ({ target }) => {
    const { name, value } = target;
    const nValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");

    const isNIF = isValidNIF(nValue);
    const isCIF = isValidCIF(nValue);

    setSelectTipoNif(isNIF);

    if (isNIF) {
      setTipoResidente(""); // Tipo de residente debe ser seleccionado
    } else if (isCIF) {
      setTipoResidente("E"); // CIF siempre es de tipo Empresa
    } else {
      setTipoResidente("");
    }

    onCambiaCampo({ target: { name, value: nValue } });
  };

  const onCambiaRadio = (event) => {
    setTipoResidente(event.target.value);
    onCambiaCampo(event);
  };

  const solicitudRecuperacion = async (json) => {
    let respuesta = {
      datos: null,
      error: null,
    };
    try {
      const url = cfg.apiUrl + "/auth/recuperar";
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: cfg.autToken,

          // Deshabilitar provisionalmente la caché
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0"
        },

        body:
          encodeURIComponent("nif") +
          "=" +
          encodeURIComponent(json?.nif || "") +
          "&" +
          encodeURIComponent("tipo") +
          "=" +
          encodeURIComponent(json?.tipo || ""),
      });

      if (resp.status === 200) {
        respuesta = { ...respuesta, datos: {} };
      } else {
        respuesta = { ...respuesta, error: "Error: " + resp.status };
      }
    } catch (error) {
      respuesta = { ...respuesta, error };
    }
    return respuesta;
  };

  const onRecuperar = async (event) => {
    event.preventDefault();

    if (erroresForm.nif || erroresForm.captcha) {
      return;
    }

    if (validarNif(estadoForm.nif)) {
      onBlurNif({ target: { name: "nif", value: estadoForm.nif } });
      return;
    }

    setMostrarCargando(true);

    try {
      console.log("Datos enviados: ", {
        nif: estadoForm.nif,
        tipo: tipoResidente,
      });

      await solicitudRecuperacion({
        nif: estadoForm.nif,
        tipo: tipoResidente,
      });

      setMostrarCompletado(true);
    } catch (err) {
      setMostrarError("Error al enviar los datos.");
    }

    setMostrarCargando(false);
  };

  return (
    <AutLayout>
      <Grid container justifyContent="center">
        <Grid
          item
          className={"box-shadow"}
          lg={3}
          sm={12}
          sx={{
            backgroundColor: "rgba(255,255,255,0.95)",
            borderColor: "secondary.main",
            borderWidth: 2,
            borderStyle: "solid",
            padding: 2,
            borderRadius: 2,
            mt: 0,
          }}
        >
          {!esMovil && (
            <Typography
              variant="h5"
              color={"secondary.main"}
              sx={{ mb: 2, color: "black.main" }}
            >
              {cad("RECUPERACION_CONTRASEÑA")}
            </Typography>
          )}

          {mostrarCompletado && (
            <>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {cad("SOLICITUD_RECUPERACION_COMPLETADA")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {cad("APR_TEXTO_RECUPERAR_CONTRASEÑA_2")}
              </Typography>
            </>
          )}

          {!mostrarCompletado && (
            <>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {cad("APR_TEXTO_RECUPERAR_CONTRASEÑA_1")}.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {cad("APR_TEXTO_RECUPERAR_CONTRASEÑA_2")}.
              </Typography>

              <form onSubmit={onRecuperar} autoComplete={"off"}>
                <Grid
                  container
                  direction={esMovil ? "column" : "row"}
                  spacing={1}
                >
                  <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(erroresForm.nif)}
                      helperText={erroresForm.nif}
                      label={cad("NIF_CIF")}
                      name="nif"
                      type="text"
                      placeholder={cad("NIF_CIF")}
                      onChange={onCambiaNif}
                      onBlur={onBlurNif}
                      value={estadoForm.nif}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { textTransform: "uppercase" },
                        maxLength: 15,
                      }}
                    />
                  </Grid>

                  {selectTipoNif && (
                    <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          {cad("TIPO_RESIDENTE")}
                        </FormLabel>
                        <RadioGroup
                          sx={{ display: "flex", flexDirection: "row", ml: 1 }}
                          aria-label="tipo"
                          name="tipo"
                          value={tipoResidente}
                          onChange={onCambiaRadio}
                        >
                          <FormControlLabel
                            value="P"
                            control={<Radio />}
                            label={cad("ES_PARTICULAR")}
                          />
                          <FormControlLabel
                            value="A"
                            control={<Radio />}
                            label={cad("ES_AUTONOMO")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}

                  {captchaVisible && (
                    <Grid item xs={12}>
                      <CustomCaptcha
                        reloadText={cad("GENERAR_OTRO_CAPTCHA")}
                        reloadColor="#347445"
                        onCaptchaChange={handleCaptchaChange}
                        erroresForm={erroresForm}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      disabled={isButtonDisabled()}
                      fullWidth
                      startIcon={<LockOpenIcon />}
                      type={"submit"}
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white.main",
                        opacity: !captchaValido || !tipoResidente ? 0.6 : 1,
                        transition: "opacity 0.3s ease-in-out",
                        "&.hover": {
                          backgroundColor: "primary.hover",
                          color: "white.hover",
                          opacity: 1,
                        },
                      }}
                    >
                      {cad("RECUPERACION_CONTRASEÑA")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
          >
            <Link
              component={RouterLink}
              color="inherit"
              to="/"
              sx={{ mt: 2, fontSize: esMovil ? "0.875rem" : "1rem" }}
            >
              {cad("INICIO")}
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={mostrarCargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs={8} mt={1}>
        {mostrarError && (
          <Alert variant="filled" severity="error">
            {mostrarError}
          </Alert>
        )}
      </Grid>
    </AutLayout>
  );
};

export default RecuperarPage;
