import { cfg } from "../../configuracion";

export const buscarCadenasPorClave = async (idioma, token) => {
  try {
    const url = cfg.apiUrl + "/cadenas";
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "accept-language": idioma,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });
    if (resp.status == 200) {
      const data = await resp.json();
      // console.log("%Data", data)
      return data.datos;
    }

    throw new Error(resp.statusText);
  } catch (error) {
    throw error;
  }
};
