import { Routes, Route } from "react-router-dom";
import { AutRoutes } from "../aut/routes/AutRoutes";
import { AprRoutes } from "../apr/routes/AprRoutes";
import { PrivadaRoute } from "./PrivadaRoute";
import { PublicaRoute } from "./PublicaRoute";

export const AppRouter = () => {
  return (
    <Routes>
      {/* Login y Registro */}
      <Route
        path="/aut/*"
        element={
          <PublicaRoute>
            <AutRoutes />
          </PublicaRoute>
        }
      />

      {/* APR App */}
      <Route
        path="/*"
        element={
          <PrivadaRoute>
            <AprRoutes />
          </PrivadaRoute>
        }
      />
    </Routes>
  );
};
