import { ThemeProvider } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { MainPalette } from "./MainPalette";
import { useContext } from "react"; // Importar el hook useContext
import { AppContext } from "../context/AppContext"; // Importar el contexto

export const AppTheme = ({ children }) => {
  const { paletaColores } = useContext(AppContext);

  try {
    // Comprobamos si llega la paleta a MainPalette
    const theme = MainPalette(paletaColores);

    /*     
    // Verificar el tema generado

    console.log("Color primario:", theme.palette.primary.main);
    console.log("Color secundario:", theme.palette.secondary.main);
    console.log("Color terciario:", theme.palette.tertiary.main); 
    */

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    );
  } catch (error) {
    console.error("Error al aplicar el tema:", error);
  }
};
