import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { IdiomaContext } from "../context/IdiomaContext";
import { AutContext } from "../aut/context/AutContext";

export const FormModificarPerfilAltaResidente = ({
  perfil,
  deshabilitado = false,
  onEmitirDatos,
  onError,
  datosModificacionResidente,
  setDatosModificacionResidente,
}) => {
  const { cad } = useContext(IdiomaContext);
  const { usuario } = useContext(AutContext);

  useEffect(() => {
    setDatosModificacionResidente({
      tipo: perfil?.tipo || "",
      nombre: perfil?.nombre || "",
      apellido1: perfil?.apellido1 || "",
      apellido2: perfil?.apellido2 || "",
    });
  }, [perfil, setDatosModificacionResidente]);

  const [erroresForm, setErroresForm] = useState({
    nombre: "",
    apellido1: "",
    apellido2: "",
  });

  const validarCampo = (name, value) => {
    let msg = "";
    let min = 0;
    const presente = Boolean(value);

    switch (name) {
      case "nombre":
        min = 3;
        break;
      case "apellido1":
        min = 2;
        break;
      case "apellido2":
        min = 2;
        break;
    }

    if (!presente && name !== "apellido2") return "ERR_CAMPO_OBLIGATORIO";
    if (min > 0 && value.length < min && name !== "apellido2")
      return "ERR_LONGITUD_INSUFICIENTE";

    return msg;
  };

  const onBlur = ({ target }) => {
    const { name, value } = target;
    const msg = validarCampo(name, value);

    setErroresForm((previo) => ({
      ...previo,
      [name]: msg,
    }));
  };

  const onCambiaCampo = ({ target }) => {
    const { name, value } = target;
    setDatosModificacionResidente((previo) => ({
      ...previo,
      [name]: value,
    }));
  };

  const onEntrar = (event) => {
    event.preventDefault();

    if (Object.values(erroresForm).some((error) => error)) {
      onError("Hay errores en el formulario");
      return;
    }

    onEmitirDatos();
  };

  const camposDeshabilitado = deshabilitado || usuario?.estado === "0";
  const tipoResidenteDeshabilitado = true;

  return (
    <form onSubmit={onEntrar} autoComplete={"off"}>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        direction="column"
        sx={{ p: 0, m: 0, width: "100%" }}
      >
        <Grid item xs={12} sx={{ pr: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="tipo-select-label">
              {cad("TIPO_RESIDENTE")}
            </InputLabel>
            <Select
              labelId="tipo-select-label"
              id="tipo-select"
              value={datosModificacionResidente.tipo}
              name="tipo"
              label={cad("TIPO_RESIDENTE")}
              onChange={onCambiaCampo}
              onBlur={onBlur}
              disabled={tipoResidenteDeshabilitado}
            >
              <MenuItem value="P">{cad("ES_PARTICULAR")}</MenuItem>
              <MenuItem value="E">{cad("ES_EMPRESA")}</MenuItem>
              <MenuItem value="A">{cad("ES_AUTONOMO")}</MenuItem>
            </Select>
            <FormHelperText>{cad(erroresForm.tipo)}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ pr: 2 }}>
          <TextField
            error={Boolean(erroresForm.nombre)}
            helperText={cad(erroresForm.nombre)}
            disabled={camposDeshabilitado}
            label={cad("NOMBRE")}
            name="nombre"
            type="text"
            placeholder={cad("NOMBRE")}
            onChange={onCambiaCampo}
            onBlur={onBlur}
            value={datosModificacionResidente.nombre}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sx={{ pr: 2 }}>
          <TextField
            error={Boolean(erroresForm.apellido1)}
            helperText={cad(erroresForm.apellido1)}
            disabled={camposDeshabilitado}
            label={cad("APELLIDO")}
            name="apellido1"
            type="text"
            placeholder={cad("APELLIDO")}
            onChange={onCambiaCampo}
            onBlur={onBlur}
            value={datosModificacionResidente.apellido1}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sx={{ pr: 2 }}>
          <TextField
            error={Boolean(erroresForm.apellido2)}
            helperText={cad(erroresForm.apellido2)}
            disabled={camposDeshabilitado}
            label={cad("APELLIDO2")}
            name="apellido2"
            type="text"
            placeholder={cad("APELLIDO2")}
            onChange={onCambiaCampo}
            onBlur={onBlur}
            value={datosModificacionResidente.apellido2}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
};
