import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AprLayout } from "../layout/AprLayout";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { IdiomaContext } from "../../context/IdiomaContext";

export const AprInicioPage = () => {
  const { cad } = useContext(IdiomaContext);
  const esMovil = useMediaQuery("(max-width:640px)");
  const esMovilLayoutHorizontal = useMediaQuery(
    "(max-width:930px) and (orientation: landscape)"
  );
  const esDesktop = !esMovil && !esMovilLayoutHorizontal;

  return (
    <AprLayout>
      <Grid
        container
        item
        xs={12}
        display={"flex"}
        flexDirection={esMovil ? "column" : "row"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          mt: esMovilLayoutHorizontal ? 3 : esMovil ? 6 : 6,
          mb: esMovilLayoutHorizontal ? 0 : esMovil ? 6 : 6,
        }}
        gap={esMovilLayoutHorizontal ? 20 : esMovil ? 6 : 30}
      >
        <Grid item>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 5,
              borderColor: "primary.main",
              maxWidth: esMovil ? 350 : "none",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent
              sx={{
                py: esMovilLayoutHorizontal ? 0 : esMovil ? 0 : 3,
              }}
            >
              <Typography
                component={Link}
                to="/peticiones"
                color="secondary.main"
                sx={{
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <EmailOutlined
                  sx={{
                    fontSize: esDesktop ? "18em" : esMovil ? "9em" : "7em",
                  }}
                />
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center", paddingTop: 0 }}>
              <Button component={Link} to="/peticiones" size="large">
                {cad("PETICIONES")}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 5,
              borderColor: "primary.main",
              maxWidth: esMovil ? 350 : "none",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent
              sx={{
                py: esMovilLayoutHorizontal ? 0 : esMovil ? 0 : 3,
              }}
            >
              <Typography
                component={Link}
                to="/permisos"
                color="secondary.main"
                sx={{
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <KeyOutlinedIcon
                  sx={{
                    fontSize: esDesktop ? "18em" : esMovil ? "9em" : "7em",
                  }}
                />
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center", paddingTop: 0 }}>
              <Button component={Link} to="/permisos" size="large">
                {cad("PERMISOS")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </AprLayout>
  );
};
