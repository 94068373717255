import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  useTheme,
} from "@mui/material";
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { IdiomaContext } from "../../../context/IdiomaContext";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { useFileContext } from "../../../context/FileContext";

const ModalDocumentosRegistro = ({
  modalAbierto,
  setModalAbierto,
  tiposDocumentos,
  documentosCargados,
  setDocumentosCargados,
  tipoResidente,
}) => {
  const { cad } = useContext(IdiomaContext);
  const [error, setError] = useState("");
  const esMovil = useMediaQuery("(max-width:768px)");
  const theme = useTheme();
  const handleClose = () => {
    setModalAbierto(false);
  };

  console.log("Tipos de Documentos:", tiposDocumentos);
  console.log("Documentos Cargados:", documentosCargados);

  const { resizeFile } = useFileContext();

  const handleCargarFichero = (event, id) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Fichero a guardar:", file);
      const maxTamMb = 1;
      const archTam = file.size / (1024 * 1024); // Convertir a MB
      const archExt = file.name.split(".").pop().toLowerCase();

      if (archExt === "jpg" || archExt === "jpeg" || archExt === "png") { 
        resizeFile(file, 1920, 1080, (compressedFile) => {
          setDocumentosCargados((prevFicheros) => [
            ...prevFicheros.filter((fichero) => fichero.id !== id),
            { id, fichero: compressedFile },
          ]);
        });
      } else {
        // Si el archivo supera 1MB, cancelar envío y mostrar error
        if (archTam > maxTamMb) {
          console.error("Error, El archivo " + file.name + " supera el tamaño máximo permitido de 1MB.");
        } else {
          setDocumentosCargados((prevFicheros) => [
            ...prevFicheros.filter((fichero) => fichero.id !== id),
            { id, fichero: file },
          ]);
        }
      }
    }
  };

  const handleBorrarFichero = (id) => {
    setDocumentosCargados((prevFicheros) =>
      prevFicheros.filter((fichero) => fichero.id !== id)
    );
  };

  const todosDocumentosCargados = () => {
    if (!tiposDocumentos || !documentosCargados) {
      return false;
    }

    const documentosCargadosIds = documentosCargados.map((doc) => doc.id);
    const tiposDocumentosIds = Object.keys(tiposDocumentos);
    return tiposDocumentosIds.every((id) => documentosCargadosIds.includes(id));
  };

  useEffect(() => {
    const todosCargados = todosDocumentosCargados();
    if (!todosCargados) {
      setError(cad("ERROR_FALTAN_DOCUMENTOS"));
    } else {
      setError("");
    }
  }, [documentosCargados, tiposDocumentos, cad]);

  const renderDocumentosMovil = (documentos) =>
    documentos &&
    Object.keys(documentos).map((key) => {
      const documentoCargado = documentosCargados.find((f) => f.id === key);
      const todosCargados = documentoCargado && documentoCargado.fichero;
      const documentoTituloColor = todosCargados ? "green" : theme.palette.error.main;
      const iconoEstado = todosCargados ? <CheckIcon color="success" /> : <ClearIcon color="error" />;

      return (
        <Accordion key={key} sx={{ mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${key}-content`}
            id={`panel-${key}-header`}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {iconoEstado}
              <Typography sx={{ color: documentoTituloColor, ml: 1, fontWeight: "medium" }}>
                {documentos[key]}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Card variant="outlined">
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {cad("FICHERO")}:
                    </Typography>
                    <Typography variant="body2">
                      {documentoCargado ? documentoCargado.fichero.name : ""}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {cad("ADD_FICHERO")}:
                    </Typography>
                    <label htmlFor={`file-input-${key}`}>
                      <input
                        type="file"
                        onChange={(event) => handleCargarFichero(event, key)}
                        style={{ display: "none" }}
                        id={`file-input-${key}`}
                      />
                      <IconButton component="span" sx={{ padding: 0 }}>
                        <CloudUploadIcon />
                      </IconButton>
                    </label>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {cad("ESTADO")}:
                    </Typography>
                    {documentoCargado ? (
                      <CheckIcon color="success" />
                    ) : (
                      <ClearIcon color="error" />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {cad("DELETE_FICHERO")}:
                    </Typography>
                    {documentoCargado ? (
                      <IconButton
                        onClick={() => handleBorrarFichero(key)}
                        sx={{ padding: 0 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <DeleteIcon color="disabled" />
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </AccordionDetails>
        </Accordion>
      );
    });

  const renderDocumentosDesktop = (documentos) => (
    <Table size="medium">
      <TableHead>
        <TableRow>
          <TableCell>{cad("DOCUMENTOS")}</TableCell>
          <TableCell align="center">{cad("ADD_FICHERO")}</TableCell>
          <TableCell align="center">{cad("DELETE_FICHERO")}</TableCell>
          <TableCell>{cad("FICHERO")}</TableCell>
          <TableCell align="center">{cad("ESTADO")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documentos &&
          Object.keys(documentos).map((key) => {
            const documentoCargado = documentosCargados.find(
              (f) => f.id === key
            );
            const iconoEstado = documentoCargado ? (
              <CheckIcon color="success" />
            ) : (
              <ClearIcon color="error" />
            );

            return (
              <TableRow key={key}>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {iconoEstado}
                    <Typography sx={{ ml: 1 }}>
                      {documentos[key]}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <label htmlFor={`file-input-${key}`}>
                    <input
                      type="file"
                      onChange={(event) => handleCargarFichero(event, key)}
                      style={{ display: "none" }}
                      id={`file-input-${key}`}
                    />
                    <IconButton component="span" sx={{ padding: 0 }}>
                      <CloudUploadIcon />
                    </IconButton>
                  </label>
                </TableCell>
                <TableCell align="center">
                  {documentoCargado ? (
                    <IconButton onClick={() => handleBorrarFichero(key)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <DeleteIcon color="disabled" />
                  )}
                </TableCell>
                <TableCell>
                  {documentoCargado ? documentoCargado.fichero.name : ""}
                </TableCell>
                <TableCell align="center">
                  {documentoCargado ? (
                    <CheckIcon color="success" />
                  ) : (
                    <ClearIcon color="error" />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );

  const getTipoResidente = () => {
    switch (tipoResidente) {
      case "P":
        return cad("ES_PARTICULAR");
      case "E":
        return cad("ES_EMPRESA");
      case "A":
        return cad("ES_AUTONOMO");
      default:
        return "";
    }
  };

  return (
    <Modal
      open={modalAbierto}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ zIndex: 2000 }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: esMovil ? "95%" : 700,
          maxHeight: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: esMovil ? 2 : 4,
          overflowY: "auto",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: esMovil ? 1 : 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 1 }}>
            {cad("DOCUMENTOS_REGISTRO")}
          </Typography>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1" sx={{ mb: esMovil ? 2 : 3 }}>
          {cad("APR_DESCRIPCION_DOCUMENTOS_REGISTRO")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: esMovil ? 2 : 3,
            ml: esMovil ? 0 : 1,
            textAlign: "left",
            fontWeight: "400",
          }}
        >
          {getTipoResidente()}
        </Typography>

        <Box
          sx={{
            mt: 2,
            maxHeight: "400px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          {esMovil
            ? renderDocumentosMovil(tiposDocumentos)
            : renderDocumentosDesktop(tiposDocumentos)}
        </Box>

        <Typography
          sx={{
            mt: 2,
            mb: 2,
            textDecoration: "underline",
            textUnderlineOffset: "0.3em",
            textDecorationThickness: "1px",
            fontSize: esMovil ? 12 : 14,
            textAlign: "center",
          }}
        >
          {cad("APR_DESCRIPCION_DOCUMENTOS_REGISTRO_FORMATOS")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: esMovil ? "column" : "row",
            justifyContent: esMovil ? "center" : "space-between",
            alignItems: "center",
            mt: esMovil ? 1 : 0,
            gap: esMovil ? 2 : 0,
          }}
        >
          {error && (
            <Typography
              sx={{
                color: "red",
                fontSize: esMovil ? 14 : 16,
                textAlign: "center",
              }}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<BookmarkAddedIcon />}
            onClick={handleClose}
            disabled={!todosDocumentosCargados()}
            sx={{
              width: esMovil ? "100%" : "auto",
            }}
          >
            {cad("CONTINUAR")}
          </Button>
        </Box>
      </Box>
    </Modal>

    
  );
};

export default ModalDocumentosRegistro;
