import { Routes, Route, Navigate } from "react-router-dom";
import { EntrarPage, RegistrarPage, RecuperarPage } from "../pages";
import { CambiarClavePage } from "../pages/CambiarClavePage";
import { ConfirmarCorreo } from "../pages/ConfirmarCorreo";
import ReenviarCorreoPage from "../pages/ReenviarCorreoPage";

export const AutRoutes = () => {
  return (
    <Routes>
      <Route path="entrar" element={<EntrarPage />} />
      <Route path="registrar" element={<RegistrarPage />} />
      <Route path="recuperar" element={<RecuperarPage />} />
      <Route path="cambiarclave" element={<CambiarClavePage />} />
      <Route path="confirmarcorreo" element={<ConfirmarCorreo />} />
      <Route path="reenviarcorreo" element={<ReenviarCorreoPage />} />
      <Route path="/*" element={<Navigate to="/aut/entrar" />} />
    </Routes>
  );
};
