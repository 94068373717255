import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AutContext } from "../aut/context/AutContext";
import { cfg } from "../configuracion";

export const PrivadaRoute = ({ children }) => {
  const { sesionIniciada } = useContext(AutContext);
  const { pathname, search } = useLocation();

  //const ultimaRuta = pathname + search;
  //localStorage.setItem(cfg.prefijoLocalStorage + 'ultimaRuta', ultimaRuta);

  return sesionIniciada 
    ? children : <Navigate to="/aut/entrar" />;
};
