/*
	Validador de NIF, CIF, y Tarjeta de residencia por javascript.
	Sin licencia.
	Distribuido en: http://www.microteching.com/
	Autor: ticnology coop.v. (http://www.ticnology.es/)
*/

const NIF_Letters = "TRWAGMYFPDXBNJZSQVHLCKET";
const NIF_regExp = "^\\d{8}[a-zA-Z]{1}$";
const CIF_regExp = "^[a-zA-Z]{1}\\d{7}[a-jA-J0-9]{1}$";

const checkPorTipoPersona = (value, esJuridica) => {
	let ok = false;

	if (esJuridica) {
		if (checkCIF(value)) { // Comprueba el CIF
			ok = true;
		}
	}
	else {
		// Comprueba tarjeta de residencia
		// Comprueba el NIF
		if (checkTR(value) || checkNIF(value)) { 
			ok = true;
		}
	}
	return ok;
}

const checkAll = (value) => {
	if (checkCIF(value)) { // Comprueba el CIF
		return true;
	} else if (checkTR(value)) { // Comprueba tarjeta de residencia
		return true;
	} else if (checkNIF(value)) { // Comprueba el NIF
		return true;
	} else {           // Si no pasa por ninguno es false.
		return false;
	}
}

// VALIDA EL NIF
export const  checkNIF = (nif) => {
	// Comprueba la longitud. Los DNI antiguos tienen 7 digitos.
	//if ((nif.length!=8) && (nif.length!=9)) return false;
	//if (nif.length == 8) nif = '0' + nif; // Ponemos un 0 a la izquierda y solucionado

	let pad = "000000000";
	if (nif.length < 9) {
		nif = pad.substring(0, pad.length - nif.length) + nif;
	}

	// Comprueba el formato
	let regExp=new RegExp(NIF_regExp);
	if (!nif.match(regExp)) return false;

	let letra1 = nif.charAt(nif.length-1);
	let dni = nif.substring(0,nif.length-1)
	let letra2 = NIF_Letters.charAt(Number(dni) % 23);
	return (letra2==letra1.toUpperCase());
}

// VALIDA TARJETA DE RESIDENCIA
export const checkTR = (tr) => {
	if ((tr.length!=10) && (tr.length!=9)) return false;
	if ((tr.charAt(0).toUpperCase() != "X") && (tr.charAt(0).toUpperCase() != "Y") && (tr.charAt(0).toUpperCase() != "Z")) return false;
	
	let leftNum = '0';
	if (tr.charAt(0).toUpperCase() == "Y") leftNum = '1';
	
	if (tr.length==9) {
		return checkNIF(leftNum + tr.substring(1,tr.length));
	} else {
		return checkNIF(tr.substring(1,tr.length));
	}
}

// VALIDA TARJETA DE RESIDENCIA
export const checkCIF = (cif) => {
	let v1 = new Array(0,2,4,6,8,1,3,5,7,9);
	let tempStr = cif.toUpperCase(); // pasar a may�sculas
	let temp = 0;
	let temp1;
	let dc;

	// Comprueba el formato
	let regExp=new RegExp(CIF_regExp);
	if (!tempStr.match(regExp)) return false;    // Valida el formato?
	if (!/^[ABCDEFGHKLMNPQS]/.test(tempStr)) return false;  // Es una letra de las admitidas ?

	for( let i = 2; i <= 6; i += 2 ) {
		temp = temp + v1[ parseInt(cif.substr(i-1,1)) ];
		temp = temp + parseInt(cif.substr(i,1));
	};
	temp = temp + v1[ parseInt(cif.substr(7,1)) ];
	temp = (10 - ( temp % 10));
	if (temp==10) temp=0;
	dc  = cif.toUpperCase().charAt(8);
	
	return (dc==temp) || (temp==1 && dc=='A') || (temp==2 && dc=='B') || (temp==3 && dc=='C') || (temp==4 && dc=='D') || (temp==5 && dc=='E') || (temp==6 && dc=='F') || (temp==7 && dc=='G') || (temp==8 && dc=='H') || (temp==9 && dc=='I') || (temp==0 && dc=='J');
}


