import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({titulo = '', texto = '', onCerrar}) {
  const [open, setOpen] = React.useState(Boolean(titulo || texto));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (onCerrar) {
      onCerrar();
    }
  };

  React.useEffect(() => {;
    setOpen(Boolean(titulo || texto));
  },[titulo, texto]);

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {texto}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
    </Dialog>
  );
}
