import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { IdiomaContext } from "../../context/IdiomaContext";

export const TablaDocumentosInfo = ({ documentos }) => {
  const { cad } = useContext(IdiomaContext);

  return (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
      <Table aria-label="simple table" sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "primary.main", width: '40%' }}>
              {cad("DOCUMENTOS")}
            </TableCell>
            <TableCell sx={{ color: "primary.main", width: '35%' }}>
              {cad("FICHEROS")}
            </TableCell>
            <TableCell
              align="center"
              sx={{ color: "primary.main", width: '25%' }}
            >
              {cad("ESTADO")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documentos &&
            documentos.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ pt: 0.5, pr: 0.5, pb: 0.5, wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {cad(row.nombreDocumento)}
                </TableCell>
                <TableCell sx={{ pt: 0.5, pr: 0.5, pb: 0.5, wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {row.nombreArchivo}
                </TableCell>
                <TableCell align="center" sx={{ pt: 0.5, pr: 0.5, pb: 0.5 }}>
                  {row.estado === "0" && (
                    <Tooltip title={cad("PENDIENTE")}>
                      <HourglassEmptyIcon />
                    </Tooltip>
                  )}
                  {row.estado === "1" && (
                    <Tooltip title={"ACEPTADA"}>
                      <CheckIcon />
                    </Tooltip>
                  )}
                  {row.estado === "2" && (
                    <Tooltip title={"RECHAZADA"}>
                      <ClearIcon />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
