import { useContext, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { IdiomaContext } from "../../context/IdiomaContext";

export const TablaAreas = ({
  areas,
  areasSeleccionadas,
  seleccionable = false,
  onCambiaSeleccion,
}) => {
  const { cad } = useContext(IdiomaContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "primary.main" }}>{cad("AREA")}</TableCell>
            {seleccionable && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {areas &&
            Object.entries(areas).map(([id, nombre]) => (
              <TableRow
                key={id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ pr: 0, pt: 0, pb: 0 }}
                >
                  {nombre}
                </TableCell>
                {seleccionable && (
                  <TableCell sx={{ p: 0 }}>
                    <Checkbox
                      checked={
                        areasSeleccionadas && areasSeleccionadas.includes(id)
                      }
                      onChange={() => {
                        onCambiaSeleccion && onCambiaSeleccion(id);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
