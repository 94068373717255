export const getPalette = (paletaColores) => {
  if (
    !paletaColores ||
    !paletaColores.colorPrimario ||
    !paletaColores.colorSecundario ||
    !paletaColores.colorTerciario
  ) {
    throw new Error("Fallo cargando la paleta de colores: Valores faltantes.");
  }

  if(paletaColores){
  return {
    colorPrimario: paletaColores.colorPrimario,
    colorSecundario: paletaColores.colorSecundario,
    colorTerciario: paletaColores.colorTerciario,
  };
  }
};