import { cfg } from "../../configuracion";
import { md5 } from "../md5";

export const updateResidenteCorregido = async (idioma, json, token) => {
    let respuesta = {
        datos: null,
        error: null
    };
    try {
        const url = `${cfg.apiUrl}/residentecorregido`;
        console.log("%Guardando registro en:", url);
        console.log("%Datos enviados:", json); // Log de los datos enviados

        const formData = new FormData();
        formData.append("id", json.id);
        formData.append("nif", json.nif);
        formData.append("correoe", json.correoe);
        formData.append("tipo", json.tipo);
        formData.append("nombre", json.nombre);
        formData.append("apellido1", json.apellido1);
        formData.append("apellido2", json.apellido2);

        // Si tienes documentos cargados
        if (json.documentosCargados && Array.isArray(json.documentosCargados)) {
            const ficheros = json.documentosCargados
                .map((e) => e.id + "_" + e.fichero?.name)
                .join(",");
            formData.append("ficheros", ficheros);

            json.documentosCargados.forEach((doc) => {
                formData.append("ficherosContenido", doc.fichero);
            });
        }

        // Log para verificar los valores de FormData
        for (let [key, value] of formData.entries()) {
            console.log(`%Valores de formdata: ${key}: ${value}`);
        }

        const resp = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept-Language': idioma,
                'Authorization': `Bearer ${token}`,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            },
            body: formData
        });

        console.log("%Respuesta del servidor:", resp); // Log de la respuesta del servidor
        if (resp.ok) {
            let datos = await resp.json();
            console.log("%Datos recibidos del servidor:", datos); // Log de los datos recibidos
            respuesta = { ...respuesta, datos };
        } else {
            let errorMessage = `Error: ${resp.status}`;
            try {
                const errorData = await resp.json();
                if (errorData.mensaje) {
                    errorMessage = errorData.mensaje;
                }
            } catch (e) {
                console.error("Error parsing error response", e);
            }
            respuesta = { ...respuesta, error: errorMessage };
            console.log("%Error en la respuesta del servidor:", errorMessage); // Log del error en la respuesta
        }
    } catch (error) {
        console.error("Error en el envío de datos:", error); // Log del error capturado
        respuesta = { ...respuesta, error: error.message };
    }
    return respuesta;
};
