import { useContext, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  useMediaQuery,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FlagIcon from "@mui/icons-material/Flag";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import BadgeIcon from "@mui/icons-material/Badge";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import ArticleIcon from "@mui/icons-material/Article";
import { useTheme } from "@mui/material";

import { IdiomaContext } from "../../context/IdiomaContext";
import { useFormulario } from "../../hooks/useFormulario";
import { IdiomaSelector } from "./IdiomaSelector";
import { checkCIF, checkNIF, checkTR } from "../../aut/NifValidator";
import CustomCaptcha from "./utils/CustomCaptcha";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import ModalDocumentosRegistro from "./../components/utils/ModalDocumentosRegistro";
import { useQuery } from "@tanstack/react-query";
import getDocumentosRegistroPorTipoResidente from "../../aut/services/getDocumentosRegistroPorTipoResidente";
import { PublicTokenContext } from "../../context/PublicTokenContext";

export const PerfilForm = ({
  perfil,
  deshabilitado = false,
  privacidad = true,
  onEmitirDatos,
  onError,
}) => {
  const { cad, idioma } = useContext(IdiomaContext);
  const { tokenCliente } = useContext(PublicTokenContext);

  const theme = useTheme();

  const [esPrimeraCarga, setEsPrimeraCarga] = useState(true);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(true);
  const [openTipoSelect, setOpenTipoSelect] = useState(false);
  const [backdropAbierto, setBackdropAbierto] = useState(false);
  const [documentoInfo, setDocumentoInfo] = useState({
    esNif: false,
    esNie: false,
    esCif: false,
    esValido: false,
  });
  const [erroresForm, setErroresForm] = useState({
    nif: "",
    nombre: "",
    apellido1: "",
    apellido2: "",
    clave: "",
    clave2: "",
    correoe: "",
    cp: "",
    direccion: "",
    pais: "",
    poblacion: "",
    provincia: "",
    telefono: "",
    tipo: "",
    captcha: "",
  });
  const [documentosCargados, setDocumentosCargados] = useState([]);
  const [documentosValidos, setDocumentosValidos] = useState(false);

  const { estadoForm, onCambiaCampo, cambiarEstadoForm } = useFormulario({
    nif: perfil?.nif || "",
    nombre: perfil?.nombre || "",
    apellido1: perfil?.apellido1 || "",
    apellido2: perfil?.apellido2 || "",
    clave: "",
    clave2: "",
    correoe: perfil?.correoe || "",
    cp: perfil?.cp || "",
    direccion: perfil?.direccion || "",
    pais: perfil?.pais || "",
    poblacion: perfil?.poblacion || "",
    provincia: perfil?.provincia || "",
    telefono: perfil?.telefono || "",
    tipo: perfil?.tipo || "",
  });

  // Servicios
  const { data: tiposDocumentos, isLoading: tiposDocumentosCargando } =
    useQuery({
      queryKey: [
        "getDocumentosRegistroPorTipoResidente",
        { tipo: estadoForm.tipo, idioma: idioma, token: tokenCliente },
      ],
      queryFn: () =>
        getDocumentosRegistroPorTipoResidente(
          estadoForm.tipo,
          idioma,
          tokenCliente
        ),
    });

  // Efectos
  useEffect(() => {
    comprobarDocumento(estadoForm.nif);
  }, []);

  useEffect(() => {
    console.log("Cargando documentos:", tiposDocumentosCargando);
    setBackdropAbierto(tiposDocumentosCargando);
  }, [tiposDocumentosCargando]);

  useEffect(() => {
    corregirTipoResidente();
  }, [documentoInfo]);

  useEffect(() => {
    if (
      estadoForm.tipo &&
      documentoInfo.esValido &&
      !perfil &&
      !documentosValidos
    ) {
      setModalAbierto(true);
    }
  }, [documentoInfo, documentosValidos]);

  useEffect(() => {
    setOpenTipoSelect(false);
    setDocumentosCargados([]);
  }, [estadoForm.tipo]);

  /* useEffect(() => {
    if (idioma !== idiomaPrevio) {
      if (
        estadoForm.nif.length > 0 &&
        !todosDocumentosCargados() &&
        !esPrimeraCarga
      ) {
        setModalAbierto(true);
      }
      setIdiomaPrevio(idioma);
    }
  }, [
    idioma,
    idiomaPrevio,
    estadoForm.nif,
    documentosCargados,
    tiposDocumentos,
  ]); */

  useEffect(() => {
    if (estadoForm.nif.length > 0 && !documentosValidos && !esPrimeraCarga) {
      setModalAbierto(true);
    }
  }, [idioma, documentosValidos]);

  useEffect(() => {
    if (tiposDocumentosCargando) return;
    setDocumentosValidos(validarDocumentos());
  }, [documentosCargados, tiposDocumentos]);

  useEffect(() => {
    if (!esPrimeraCarga) {
      setErroresForm((prev) => {
        return { ...prev };
      });
    } else {
      setEsPrimeraCarga(false);
    }
  }, [cad]);

  // Validaciones
  const validarDocumentos = () => {
    if (!(documentosCargados && tiposDocumentos)) return false;

    const idsDocsCargados = documentosCargados
      .map((doc) => doc.id?.toString())
      .sort();

    const idsTiposDocs = Array.from(Object.keys(tiposDocumentos)).sort();

    return JSON.stringify(idsDocsCargados) === JSON.stringify(idsTiposDocs);
  };

  const validarCampo = (name, value) => {
    let msg = "";
    let req = true;
    let min = 0;
    let max = 0;
    const presente = Boolean(value);
    const esEdicion = Boolean(perfil);

    switch (name) {
      case "nif":
        min = 6;
        msg = documentoInfo.esValido ? "" : "ERR_NIF_CIF_INCORRECTO"; // Falta comprobar nif válido
        break;
      case "clave":
        min = 8;
        req = !esEdicion;
        break;
      case "clave2":
        min = 8;
        req = !esEdicion;
        msg = value === estadoForm.clave ? "" : "ERR_CLAVES_NO_COINCIDEN";
        break;
      case "nombre":
        min = 3;
        break;
      case "apellido1":
        min = 2;
        req = !documentoInfo.esCif;
        break;
      case "apellido2":
        min = 2;
        req = false;
        break;
      case "correoe":
        min = 6;
        msg =
          /(.+)@(.+){2,}\.(.+){2,}/.test(value) || !presente
            ? ""
            : "Formato incorrecto"; // 'ERR_FORMATO_INCORRECTO';
        break;
      case "cp":
        min = 5;
        max = 5;
        break;
      case "direccion":
        min = 6;
        break;
      case "pais":
        min = 2;
        break;
      case "poblacion":
        min = 4;
        break;
      case "provincia":
        min = 4;
        break;
      case "telefono":
        min = 8;
        break;
    }

    if (msg) return msg;
    if (!presente && !req) return "";

    if (!presente && req) {
      msg = "ERR_CAMPO_OBLIGATORIO";
    } else if (min > 0 && value.length < min) {
      msg = "ERR_LONGITUD_INSUFICIENTE";
    } else if (max > 0 && value.length > max) {
      msg = "ERR_LONGITUD_EXCESIVA";
    }

    return msg;
  };

  const existenErroresForm = () => {
    return Object.values(erroresForm).some((error) => error);
  };

  // Handlers
  const onCambiaNumerico = ({ target }) => {
    const { name, value } = target;
    console.log(name, value, target);
    const nValue = value.toUpperCase().replace(/[^0123456789]/g, "");
    onGuardaCambio({ target: { name, value: nValue } });
  };

  const comprobarDocumento = (doc) => {
    let nfo = {
      esNif: checkNIF(doc),
      esNie: checkTR(doc),
      esCif: checkCIF(doc),
      esValido: false,
    };
    nfo.esValido = nfo.esNif || nfo.esCif || nfo.esNie;
    setDocumentoInfo(nfo);

    if (nfo.esCif) {
      onCambiaCampo({ target: { name: "tipo", value: "E" } });
      setModalAbierto(true);
    } else if (nfo.esNif) {
      setOpenTipoSelect(true);
    } else {
      onCambiaCampo({ target: { name: "tipo", value: "" } });
      setOpenTipoSelect(false);
    }
  };

  const corregirTipoResidente = () => {
    const nfo = documentoInfo;
    console.log("Info", nfo);
    console.log("Tipo_old", estadoForm.tipo);
    if (!nfo.esValido) return;
    let tipo = estadoForm.tipo;
    if (nfo.esCif) tipo = "E"; // Si es un CIP el tipo tiene que ser Empresa
    else if (tipo === "E") tipo = "P"; // Si no no es cif. Tiene que ser P ó A. Por defecto elegimos Particular. Si ya era P o A se queda como estaba.
    console.log("Tipo_new", tipo);

    onCambiaCampo({ target: { name: "tipo", value: tipo } });

    console.log("Form: ", estadoForm);
  };

  const onGuardaCambio = (event) => {
    const { target } = event;
    const { name, value } = target;

    const upperCaseNIF = name === "nif" ? value.toUpperCase() : value;

    switch (name) {
      case "nif":
        comprobarDocumento(value);
        break;
      case "tipo":
        setOpenTipoSelect(false);
        setModalAbierto(true);
        break;
      default:
        break;
    }

    onCambiaCampo({ target: { name, value: upperCaseNIF } });
  };

  const onBlur = ({ target }) => {
    const { name, value } = target;
    const msg = validarCampo(name, value);

    setErroresForm((previo) => {
      return {
        ...previo,
        [name]: msg,
      };
    });
    setEsPrimeraCarga(false);
  };

  const comprobarTodos = () => {
    let errs = {};
    let texts = "";
    let text;
    for (let campo in erroresForm) {
      if (perfil) continue;

      text = validarCampo(campo, estadoForm[campo]);
      errs[campo] = text;
      if (text) {
        texts += campo + ": " + text + "\n";
      }
    }

    setErroresForm(errs);

    return texts;
  };

  const handleOpenTipoSelect = () => {
    if (documentoInfo.esValido) {
      setOpenTipoSelect(true);
    }
  };

  // Comprueba si es CIF.
  const esCif = () => {
    return documentoInfo.esCif;
  };

  const todosCamposRellenos = () => {
    const camposObligatorios = [
      "nif",
      "nombre",
      "correoe",
      "cp",
      "direccion",
      "pais",
      "poblacion",
      "provincia",
      "telefono",
      "tipo",
    ];

    // Excepción: Inserta como campo obligatorio "apellido1" si es NIF.
    if (!esCif()) {
      camposObligatorios.push("apellido1");
    }

    const camposLlenos = camposObligatorios.every(
      (campo) => !!estadoForm[campo]
    );

    if (perfil) {
      const contraseñasCoinciden = estadoForm.clave === estadoForm.clave2;
      const contraseñasValidas =
        !estadoForm.clave.length === 0 ||
        !estadoForm.clave2.length === 0 ||
        (estadoForm.clave.length >= 8 && estadoForm.clave2.length >= 8);
      return camposLlenos && contraseñasCoinciden && contraseñasValidas;
    }

    const contraseñasCoinciden = estadoForm.clave === estadoForm.clave2;
    const contraseñasValidas =
      estadoForm.clave &&
      estadoForm.clave2 &&
      estadoForm.clave.length >= 8 &&
      estadoForm.clave2.length >= 8;
    return camposLlenos && contraseñasCoinciden && contraseñasValidas;
  };

  const onEntrar = (event) => {
    event.preventDefault();

    if (!privacidad) {
      onError(cad("ERR_DEBE_ACEPTAR_POLITICA_PRIVACIDAD"));
      return;
    }

    const errores = comprobarTodos();
    if (errores) {
      console.log("Saliendo SIN enviar", errores, erroresForm);
      return;
    }

    const {
      nif,
      nombre,
      apellido1,
      apellido2,
      tipo,
      clave,
      correoe,
      cp,
      direccion,
      pais,
      poblacion,
      provincia,
      telefono,
    } = estadoForm;

    const ret = {
      ...perfil,
      nif,
      nombre,
      apellido1: documentoInfo?.esCif ? "" : apellido1,
      apellido2: documentoInfo?.esCif ? "" : apellido2,
      tipo,
      clave,
      correoe,
      cp,
      direccion,
      pais,
      poblacion,
      provincia,
      telefono,
      documentosCargados,
    };

    console.log("Emitiendo datos: ", ret);
    onEmitirDatos(ret);
  };

  const formatearFecha = (strFecha) => {
    const fecha = new Date(strFecha);

    const dia = String(fecha.getUTCDate()).padStart(2, "0");
    const mes = String(fecha.getUTCMonth() + 1).padStart(2, "0");
    const año = fecha.getUTCFullYear();

    return `${dia}/${mes}/${año}`;
  };

  const handleCaptchaChange = (value) => {
    const esValido = validateCaptcha(value, false);
    setCaptchaValido(esValido);
    if (esValido) {
      setErroresForm((previo) => {
        return { ...previo, captcha: false };
      });
      onGuardaCambio({ target: { name: "captcha", value } });
      setCaptchaVisible(false);
    }
  };

  const esMovil = useMediaQuery("(max-width:768px)");

  return (
    <>
      <form onSubmit={onEntrar} autoComplete={"off"}>
        <Grid container spacing={2} sx={{ pt: 2, pl: 0, pr: 0 }}>
          <Grid item xs={12} lg={2}>
            <TextField
              error={Boolean(erroresForm.nif)}
              helperText={cad(erroresForm.nif)}
              disabled={deshabilitado || perfil}
              label={cad("NIF_CIF")}
              name="nif"
              type="text"
              placeholder={cad("NIF_CIF")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.nif}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <FormControl fullWidth error={Boolean(erroresForm.tipo)}>
              <InputLabel id="demo-simple-select-label">
                {cad("TIPO_RESIDENTE")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={estadoForm.tipo || ""}
                name="tipo"
                label="Tipo Residente"
                onChange={(event) => {
                  onGuardaCambio(event);
                  setModalAbierto(true); // Abre el modal cuando se selecciona un tipo
                }}
                onBlur={onBlur}
                disabled={perfil || !documentoInfo.esValido}
              >
                {!documentoInfo.esCif && (
                  <MenuItem key="P" value="P">
                    {cad("ES_PARTICULAR")}
                  </MenuItem>
                )}
                {documentoInfo.esCif && (
                  <MenuItem key="E" value="E">
                    {cad("ES_EMPRESA")}
                  </MenuItem>
                )}
                {!documentoInfo.esCif && (
                  <MenuItem key="A" value="A">
                    {cad("ES_AUTONOMO")}
                  </MenuItem>
                )}
              </Select>
              <FormHelperText>{cad(erroresForm.tipo)}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              error={Boolean(erroresForm.clave)}
              helperText={cad(erroresForm.clave)}
              disabled={deshabilitado}
              label={cad("CONTRASEÑA")}
              name="clave"
              type="password"
              onChange={onGuardaCambio}
              onBlur={onBlur}
              placeholder={cad("CONTRASEÑA")}
              value={estadoForm.clave}
              fullWidth
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              error={Boolean(erroresForm.clave2)}
              helperText={cad(erroresForm.clave2)}
              disabled={deshabilitado}
              label={cad("CONTRASEÑA2")}
              name="clave2"
              type="password"
              onChange={onGuardaCambio}
              onBlur={onBlur}
              placeholder={cad("CONTRASEÑA2")}
              value={estadoForm.clave2}
              fullWidth
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              error={Boolean(erroresForm.nombre)}
              helperText={cad(erroresForm.nombre)}
              disabled={deshabilitado}
              label={cad("NOMBRE")}
              name="nombre"
              type="text"
              placeholder={cad("NOMBRE")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.nombre}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              error={Boolean(erroresForm.apellido1)}
              helperText={cad(erroresForm.apellido1)}
              disabled={deshabilitado}
              label={cad("APELLIDO")}
              name="apellido1"
              type="text"
              placeholder={cad("APELLIDO")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.apellido1}
              sx={{ display: documentoInfo.esCif ? "none" : "" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              error={Boolean(erroresForm.apellido2)}
              helperText={cad(erroresForm.apellido2)}
              disabled={deshabilitado}
              label={cad("APELLIDO2")}
              name="apellido2"
              type="text"
              placeholder={cad("APELLIDO2")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.apellido2}
              sx={{ display: documentoInfo.esCif ? "none" : "" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={8}>
            <TextField
              error={Boolean(erroresForm.correoe)}
              helperText={cad(erroresForm.correoe)}
              disabled={deshabilitado}
              label={cad("CORREO_E")}
              name="correoe"
              type="text"
              placeholder={cad("CORREO_E")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.correoe}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              error={Boolean(erroresForm.telefono)}
              helperText={cad(erroresForm.telefono)}
              disabled={deshabilitado}
              label={cad("TELEFONO")}
              name="telefono"
              type="text"
              placeholder={cad("TELEFONO")}
              onChange={onCambiaNumerico}
              onBlur={onBlur}
              value={estadoForm.telefono}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>

          <Grid item xs={12} lg={10}>
            <TextField
              error={Boolean(erroresForm.direccion)}
              helperText={cad(erroresForm.direccion)}
              disabled={deshabilitado}
              label={cad("DIRECCION")}
              name="direccion"
              type="text"
              placeholder={cad("DIRECCION")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.direccion}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <TextField
              error={Boolean(erroresForm.cp)}
              helperText={cad(erroresForm.cp)}
              disabled={deshabilitado}
              label={cad("CP")}
              name="cp"
              type="text"
              placeholder={cad("CP")}
              onChange={onCambiaNumerico}
              onBlur={onBlur}
              value={estadoForm.cp}
              fullWidth
              inputProps={{ maxLength: 5 }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextField
              error={Boolean(erroresForm.poblacion)}
              helperText={cad(erroresForm.poblacion)}
              disabled={deshabilitado}
              label={cad("POBLACION")}
              name="poblacion"
              type="text"
              placeholder={cad("POBLACION")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.poblacion}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextField
              error={Boolean(erroresForm.provincia)}
              helperText={cad(erroresForm.provincia)}
              disabled={deshabilitado}
              label={cad("PROVINCIA")}
              name="provincia"
              type="text"
              placeholder={cad("PROVINCIA")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.provincia}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextField
              error={Boolean(erroresForm.pais)}
              helperText={cad(erroresForm.pais)}
              disabled={deshabilitado}
              label={cad("PAIS")}
              name="pais"
              type="text"
              placeholder={cad("PAIS")}
              onChange={onGuardaCambio}
              onBlur={onBlur}
              value={estadoForm.pais}
              fullWidth
            />
          </Grid>

          {perfil && perfil?.fechaCaducidad && (
            <Grid item xs={12} lg={3}>
              <TextField
                disabled={true}
                label={cad("FECHA_CADUCIDAD")}
                name="fechaCaducidad"
                type="text"
                value={formatearFecha(perfil?.fechaCaducidad)}
                fullWidth
              />
            </Grid>
          )}

          {!perfil && (
            <Grid item xs={12}>
              <Divider sx={{ mt: 1, mb: 0 }} />
            </Grid>
          )}
          {!perfil && captchaVisible && (
            <Grid item xs={12}>
              <CustomCaptcha
                reloadText={cad("GENERAR_OTRO_CAPTCHA")}
                reloadColor="#347445"
                loadCaptchaEnginge={loadCaptchaEnginge}
                erroresForm={erroresForm}
                deshabilitado={deshabilitado}
                onGuardaCambio={onGuardaCambio}
                onBlur={onBlur}
                estadoForm={estadoForm}
                onCaptchaChange={handleCaptchaChange}
                label={cad("TECLEE_EL_TEXTO")}
              />
            </Grid>
          )}

          {!perfil && (
            <ModalDocumentosRegistro
              modalAbierto={modalAbierto}
              setModalAbierto={setModalAbierto}
              tiposDocumentos={tiposDocumentos}
              documentosCargados={documentosCargados}
              setDocumentosCargados={setDocumentosCargados}
              tipoResidente={estadoForm.tipo}
            />
          )}

          {!perfil && (
            <Grid item xs={12} container justifyContent="center">
              <Button
                sx={{
                  margin: 0,
                  width: "fit-content",
                  backgroundColor: documentosValidos
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: documentosValidos
                      ? theme.palette.success.hover
                      : theme.palette.error.hover,
                  },
                }}
                variant="contained"
                disabled={!Boolean(estadoForm.tipo)}
                fullWidth
                startIcon={<ArticleIcon />}
                type={"button"}
                onClick={() => setModalAbierto(true)}
              >
                {cad("DOCUMENTOS")}
              </Button>
            </Grid>
          )}

          {!perfil && (
            <Grid item xs={12}>
              <Divider sx={{ mt: 1, mb: 0 }} />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            container
            spacing={1}
            justifyContent={esMovil ? "center" : "flex-end"}
            sx={{ m: 0 }}
          >
            <Grid
              item
              xs={12}
              container
              justifyContent={esMovil ? "center" : "flex-end"}
              sx={{ mt: 0 }}
            >
              <Button
                variant="contained"
                disabled={
                  deshabilitado ||
                  (!perfil &&
                    (!captchaValido ||
                      !documentosValidos ||
                      existenErroresForm() ||
                      !todosCamposRellenos())) ||
                  (perfil && (!todosCamposRellenos() || existenErroresForm()))
                }
                fullWidth={esMovil}
                sx={{
                  width: esMovil ? "100%" : "fit-content",
                  justifySelf: esMovil ? "center" : "flex-end",
                }}
                startIcon={perfil ? <SaveIcon /> : <HistoryEduIcon />}
                type={"submit"}
              >
                {perfil ? cad("GUARDAR_CAMBIOS") : cad("REGISTRARSE")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={backdropAbierto}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
