import { types } from "./types";

export const appReducer = (state = {}, action) => {
    switch (action.type) {
        case types.guardarDatosGenerales:
            const { datos } = action.payload;
            return {
                ...state,
                datosGenerales: datos
            };
        default:
            return state;
    }
};