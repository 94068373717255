import { cfg } from "../../configuracion"

export const getMotivos = async (tipoResidente, tipoDuracion, idioma, token, idResidente) => {

    try{
        const url = cfg.apiUrl + '/motivos'
        const resp = await fetch(`${url}/${tipoResidente}/${tipoDuracion}/${idResidente}`,
            {
                method: 'GET',
                headers: {
                    'Accept-Language': idioma,
                    'Authorization': `Bearer ${token}`,

                    // Deshabilitar provisionalmente la caché
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    Pragma: "no-cache",
                    Expires: "0"
                }
            }
        )
    
        if (resp.status == 200) {
            const data = await resp.json();
            return data.datos;
        }
    
        throw new Error(resp.statusText);
    }

    catch(err){
        throw err;
    }


}
