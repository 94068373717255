import { useState } from "react";

export const useFormulario = (formInicial = {}) => {
  const [estadoForm, setEstadoForm] = useState(formInicial);

  const onCambiaCampo = ({ target }) => {
    const { name, value } = target;
    setEstadoForm({
      ...estadoForm,
      [name]: value,
    });
  };

  const cambiarEstadoForm = (nuevoEstado) => {
    setEstadoForm((previo) => {
      return {
        ...previo,
        ...nuevoEstado,
      };
    });
  };

  const onReiniciar = () => {
    setEstadoForm(formInicial);
  };

  return {
    ...estadoForm,
    estadoForm,
    onCambiaCampo,
    cambiarEstadoForm,
    onReiniciar,
  };
};
