import { cfg } from "../../configuracion";
import { md5 } from "../md5";

const saveResidente = async (
  {
    nif,
    nombre,
    apellido1,
    apellido2,
    clave,
    correoe,
    cp,
    direccion,
    pais,
    poblacion,
    provincia,
    telefono,
    tipo,
    // ficheros, ficherosContenido
    documentosCargados,
  },
  idioma, token
) => {
  try {
    const url = `${cfg.apiUrl}/residente`;

    const formData = new FormData();
    formData.append("nif", nif);
    formData.append("nombre", nombre);
    formData.append("apellido1", apellido1);
    formData.append("apellido2", apellido2);
    formData.append("clave", md5(clave));
    formData.append("correoe", correoe);
    formData.append("cp", cp);
    formData.append("direccion", direccion);
    formData.append("pais", pais);
    formData.append("poblacion", poblacion);
    formData.append("provincia", provincia);
    formData.append("telefono", telefono);
    formData.append("tipo", tipo);
    formData.append("documentosCargados", documentosCargados);
    formData.append("idioma", idioma)

    const ficheros = documentosCargados
      ?.map((e) => e.id + "_" + e.fichero?.name)
      .join(",");
    formData.append("ficheros", ficheros);

    documentosCargados.forEach((doc) => {
      formData.append("ficherosContenido", doc.fichero);
    });

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
      body: formData,
    });

    if (!resp.ok) {
      const errorData = await resp.json();
      throw new Error(errorData.mensaje || `Error ${resp.status}: ${resp.statusText}`);
    }

    const data = await resp.json();
    if (!data.ok || !data.datos) {
      throw new Error(data.mensaje || 'Error desconocido');
    }

    const documentos = Object.values(data.datos);
    return documentos;
  } catch (error) {
    throw error;
  }
};

export default saveResidente;
