import { Link as RouterLink, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Link,
  Alert,
  LinearProgress,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { AutLayout } from "../layout/AutLayout";
import { useFormulario } from "../../hooks/useFormulario";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";

const leerToken = (search) => {
  let token = "";
  if (!search) return token;
  let spl = search.split("?");
  token = spl.length === 2 ? spl[1] : spl[0];
  spl = token.split("&");
  token = "";
  let spl2;
  for (let i = 0; i < spl.length; i++) {
    spl2 = spl[i].split("=");
    if (spl2[0] === "token") {
      token = spl2[1];
    }
  }
  return token;
};

export const ConfirmarCorreo = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarCompletado, setMostrarCompletado] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [token, setToken] = useState("");
  const [correo, setCorreo] = useState("");

  const { cfg } = useContext(AppContext);
  const { cad } = useContext(IdiomaContext);

  const esMovil = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    setToken(leerToken(search));
  }, []);

  const solicitudConfirmarCorreo = async (idioma, token) => {
    let respuesta = {
      datos: null,
      error: null,
    };
    try {
      const url = cfg.apiUrl + "/auth/confirmarcorreo";
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Accept-Language": idioma,
          Authorization: cfg.autToken,

          // Deshabilitar provisionalmente la caché
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
        body: encodeURIComponent("token") + "=" + encodeURIComponent(token),
      });
      if (resp.status == 200) {
        respuesta = { ...respuesta, datos: { correo: true } };
      } else {
        respuesta = { ...respuesta, error: "Error: " + resp.status };
      }
    } catch (error) {
      respuesta = { ...respuesta, error };
    }
    return respuesta;
  };

  useEffect(() => {
    if (!token) return;

    setMostrarCargando(true);
    setMostrarError(null);
    solicitudConfirmarCorreo("es", token).then((resp) => {
      setMostrarCargando(false);
      if (resp?.datos) {
        setMostrarCompletado(true);
        setCorreo("");
      }
      if (resp?.error) {
        setMostrarError(resp?.error);
      }
    });
  }, [token]);

  const desktopView = () => (
    <Grid container item xs={5} mt={4} justifyContent="space-evenly">
      <Grid
        item
        className={"box-shadow"}
        xs={11}
        sx={{
          backgroundColor: "rgba(255,255,255,0.95)",
          borderColor: "secondary.main",
          borderWidth: 2,
          borderStyle: "solid",
          padding: 3,
          borderRadius: 2,
        }}
      >
        <Typography color={"black.main"} variant="h5" sx={{ mb: 1 }}>
          {cad("CONFIRMAR_CORREO_ELECTRONICO")}
        </Typography>

        {!token && (
          <Typography variant="body1" sx={{ mb: 1 }}>
            {cad("CLAVE_CONFIRMACION_ERRONEA")}
          </Typography>
        )}
        {token && mostrarCompletado && (
          <Typography variant="body1" sx={{ mb: 1 }}>
            {cad("CORREO_ELECTRONICO_CONFIRMADO") + "."}
          </Typography>
        )}
        {token && !mostrarCompletado && (
          <Typography variant="body1" sx={{ mb: 1 }}>
            {cad("CONFIRMANDO_CORREO_ELECTRONICO") + "..."}
          </Typography>
        )}

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <Link component={RouterLink} color="inherit" to="/" sx={{ mt: 2 }}>
            {cad("INICIO")}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );

  const mobileView = () => (
    <Grid container item xs={12} mt={4} justifyContent="center">
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "rgba(255,255,255,0.95)",
          borderColor: "secondary.main",
          borderWidth: 2,
          borderStyle: "solid",
          padding: 2,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography color={"black.main"} variant="h6" sx={{ mb: 1 }}>
          {cad("CONFIRMAR_CORREO_ELECTRONICO")}
        </Typography>

        {!token && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            {cad("CLAVE_CONFIRMACION_ERRONEA")}
          </Typography>
        )}
        {token && mostrarCompletado && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            {cad("CORREO_ELECTRONICO_CONFIRMADO")}
          </Typography>
        )}
        {token && !mostrarCompletado && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            {cad("CONFIRMANDO_CORREO_ELECTRONICO") + "..."}
          </Typography>
        )}

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <Link
            component={RouterLink}
            color="inherit"
            to="/"
            sx={{ mt: 2, fontSize: "0.875rem" }}
          >
            {cad("INICIO")}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <AutLayout>
      {esMovil ? mobileView() : desktopView()}

      <Grid item mt={10}>
        {mostrarCargando && (
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{ backgroundColor: "white.main" }}
          >
            {cad("POR_FAVOR_ESPERE")}
            <LinearProgress color="success" />
          </Alert>
        )}
      </Grid>
      <Grid item xs={8} mt={1}>
        {mostrarError && (
          <Alert variant="filled" severity="error">
            {mostrarError}
          </Alert>
        )}
      </Grid>
    </AutLayout>
  );
};
