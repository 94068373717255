import { cfg } from "../../configuracion";

const getDocumentosRegistroPorTipo = async (tipo, idioma, token) => {
    if(!tipo) return null;
    try {
        const url = `${cfg.apiUrl}/residentes/documentos/${tipo}`;
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Language': idioma,
                'Authorization': `Bearer ${token}`,
                
                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            }
        });
        if (!resp.ok) {
            throw new Error(`Error ${resp.status}: ${resp.statusText}`);
        }

        const data = await resp.json();
        if (!data.ok || !data.datos) {
            throw new Error("Error en la respuesta del servidor");
        }
        return data.datos;

    } catch (error) {
        throw error;
    }
};

export default getDocumentosRegistroPorTipo;
