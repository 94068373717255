import { cfg } from "../../configuracion";

export const postLogin = async (usuario, clave, tipo, idioma, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const url = cfg.apiUrl + "/auth/login";

    // console.log("Idioma enviado:", idioma);

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
      body:
        encodeURIComponent("user") +
        "=" +
        encodeURIComponent(usuario) +
        "&" +
        encodeURIComponent("password") +
        "=" +
        encodeURIComponent(clave) +
        "&" +
        encodeURIComponent("tipo") +
        "=" +
        encodeURIComponent(tipo) +
        "&" +
        encodeURIComponent("cliente") +
        "=" +
        encodeURIComponent(cfg.apiCliente) +
        "&" +
        encodeURIComponent("secreto") +
        "=" +
        encodeURIComponent(cfg.apiSecreto),
    });
    if (resp.status === 200) {
      let datos = await resp.json();
      if (datos?.codigo !== "200") {
        respuesta = {
          ...datos,
          error: datos?.mensaje || "Error: Unknown",
        };
      } else {
        respuesta = { ...datos };
      }
    } else {
      const errorData = await resp.json();
      respuesta = {
        ...respuesta,
        error: errorData.error || "Error: " + resp.status,
      };
    }
  } catch (error) {
    respuesta = { ...respuesta, error: error.mensaje };
  }
  return respuesta;
};
