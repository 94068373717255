import { cfg } from "../../configuracion";

export const getVersionApi = async (token) => {
  try {
    const url = `${cfg.apiUrl}/version`;
    console.log(`URL versión API: ${url}`);
    
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": "es",
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    const data = await resp.json();
    console.log(data)
    return data || "N/A";

  } catch (error) {
    return "Error";
  }
};
