import { Link as RouterLink, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Link,
  Alert,
  LinearProgress,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import { AutLayout } from "../layout/AutLayout";
import { md5 } from "../md5";
import { useFormulario } from "../../hooks/useFormulario";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import "animate.css";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const leerToken = (search) => {
  let token = "";
  if (!search) return token;
  let spl = search.split("?");
  token = spl.length === 2 ? spl[1] : spl[0];
  spl = token.split("&");
  token = "";
  let spl2;
  for (let i = 0; i < spl.length; i++) {
    spl2 = spl[i].split("=");
    if (spl2[0] === "token") {
      token = spl2[1];
    }
  }
  return token;
};

export const CambiarClavePage = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarCompletado, setMostrarCompletado] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [mostrarClave, setMostrarClave] = useState(false);
  const [erroresForm, setErroresForm] = useState({});
  const [token, setToken] = useState("");

  const { cfg } = useContext(AppContext);
  const { cad, idioma } = useContext(IdiomaContext);
  const esMovil = useMediaQuery("(max-width:768px)");
  const esDesktop = !esMovil;

  const { estadoForm, onCambiaCampo } = useFormulario({ correoe: "" });

  useEffect(() => {
    setToken(leerToken(search));
  }, []);

  const RECAPTCHA_KEY = cfg.recaptchaKey;

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    /*
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`, function () {
          //console.log("Script loaded!");
        });
        */
  }, []);

  const solicitudCambiarClave = async (idioma, token, clave) => {
    let respuesta = {
      datos: null,
      error: null,
    };
    try {
      const url = cfg.apiUrl + "/auth/cambiarclave";
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Accept-Language": idioma,
          Authorization: cfg.autToken,

          // Deshabilitar provisionalmente la caché
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },

        body:
          encodeURIComponent("token") +
          "=" +
          encodeURIComponent(token) +
          "&" +
          encodeURIComponent("clave") +
          "=" +
          encodeURIComponent(clave),
      });
      if (resp.status == 200) {
        //let datos = await resp.json();
        respuesta = { ...respuesta, datos: {} };
      } else {
        respuesta = { ...respuesta, error: "Error: " + resp.status };
      }
    } catch (error) {
      respuesta = { ...respuesta, error };
    }
    return respuesta;
  };

  const validarClave = (value) => {
    return !value || value.length < 8 ? cad("ERR_CONTRASEÑA_CORTA") : "";
  };

  const onBlurClave = ({ target }) => {
    const { name, value } = target;
    const msg = validarClave(value);

    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const onBlurClave2 = ({ target }) => {
    const { name, value } = target;
    let msg = validarClave(value);
    if (!msg && value !== estadoForm.clave) {
      msg = cad("ERR_CONTRASEÑAS_NO_COINCIDENTES");
    }
    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const onCambiaMostrarClave = () => {
    setMostrarClave(!mostrarClave);
  };

  const onCambiar = (event) => {
    event.preventDefault();

    //navigate('/aut/cambiarclave', { replace: true });

    if (
      !token ||
      !estadoForm.clave ||
      erroresForm.clave ||
      erroresForm.clave2
    ) {
      return;
    }

    setMostrarCargando(true);
    setMostrarError(null);
    solicitudCambiarClave("es", token, md5(estadoForm.clave)).then((resp) => {
      setMostrarCargando(false);
      if (resp?.datos) {
        setMostrarCompletado(true);
        //navigate('/aut/cambiarclave', { replace: true });
      }
      if (resp?.error) {
        setMostrarError(resp?.error);
      }
    });
  };

  const contraseñasNoCoincidentes = () => {
    if (validarClave(estadoForm.clave) || validarClave(estadoForm.clave2)) {
      return true;
    }
    return estadoForm.clave !== estadoForm.clave2;
  };

  const desktopView = () => (
    <AutLayout>
      <Grid container item xs={5} mt={4} justifyContent="space-evenly">
        <Grid
          item
          className={"box-shadow"}
          xs={11}
          sx={{
            backgroundColor: "rgba(255,255,255,0.95)",
            borderColor: "secondary.main",
            borderWidth: 2,
            borderStyle: "solid",
            padding: 3,
            borderRadius: 2,
          }}
        >
          <Typography color={"black.main"} variant="h5" sx={{ mb: 1 }}>
            {cad("CAMBIAR_CONTRASEÑA")}
          </Typography>

          {!token && (
            <Typography variant="body1" sx={{ mb: 1 }}>
              {cad("CLAVE_RECUPERACION_ERRONEA")}
            </Typography>
          )}
          {token && mostrarCompletado && (
            <Typography variant="body1" sx={{ mb: 1 }}>
              {cad("CAMBIO_CLAVE_EXITOSO")}
            </Typography>
          )}

          {token && !mostrarCompletado && (
            <>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {cad("TEXTO_CAMBIO_CONTRASEÑA_1")}
              </Typography>

              <form onSubmit={onCambiar}>
                <Grid container direction={"column"}>
                  <Grid item xs={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      error={Boolean(erroresForm.clave)}
                      helperText={erroresForm.clave}
                      label={cad("CONTRASEÑA")}
                      name="clave"
                      type={mostrarClave ? "text" : "password"}
                      onChange={onCambiaCampo}
                      onBlur={onBlurClave}
                      placeholder={cad("CONTRASEÑA")}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onCambiaMostrarClave}
                              onMouseDown={onCambiaMostrarClave}
                            >
                              {mostrarClave ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      error={Boolean(erroresForm.clave2)}
                      helperText={erroresForm.clave2}
                      label={cad("REPETIR_CONTRASEÑA")}
                      name="clave2"
                      type={mostrarClave ? "text" : "password"}
                      onChange={onCambiaCampo}
                      onBlur={onBlurClave2}
                      placeholder={cad("REPETIR_CONTRASEÑA")}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onCambiaMostrarClave}
                              onMouseDown={onCambiaMostrarClave}
                            >
                              {mostrarClave ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    sx={{ mt: 3 }}
                    justifyContent={"center"}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        fullWidth
                        type={"submit"}
                        disabled={contraseñasNoCoincidentes()}
                      >
                        {cad("CAMBIAR_CONTRASEÑA")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
          >
            <Link component={RouterLink} color="inherit" to="/">
              {cad("VOLVER")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={10}>
        {mostrarCargando && (
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{ backgroundColor: "white.main" }}
          >
            {cad("POR_FAVOR_ESPERE")}
            <LinearProgress color="success" />
          </Alert>
        )}
      </Grid>
      <Grid item xs={8} mt={1}>
        {mostrarError && (
          <Alert variant="filled" severity="error">
            {mostrarError}
          </Alert>
        )}
      </Grid>
    </AutLayout>
  );

  const mobileView = () => (
    <AutLayout>
      <Grid container item xs={12} mt={4} justifyContent="center">
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "rgba(255,255,255,0.95)",
            borderColor: "secondary.main",
            borderWidth: 2,
            borderStyle: "solid",
            padding: 2,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography color={"black.main"} variant="h6" sx={{ mb: 1 }}>
            {cad("CAMBIAR_CONTRASEÑA")}
          </Typography>

          {!token && (
            <Typography variant="body2" sx={{ mb: 1 }}>
              {cad("CLAVE_RECUPERACION_ERRONEA")}
            </Typography>
          )}
          {token && mostrarCompletado && (
            <Typography variant="body2" sx={{ mb: 1 }}>
              {cad("CAMBIO_CLAVE_EXITOSO")}
            </Typography>
          )}

          {token && !mostrarCompletado && (
            <>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {cad("TEXTO_CAMBIO_CONTRASEÑA_1")}
              </Typography>

              <form onSubmit={onCambiar}>
                <Grid container direction={"column"}>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(erroresForm.clave)}
                      helperText={erroresForm.clave}
                      label={cad("CONTRASEÑA")}
                      name="clave"
                      type={mostrarClave ? "text" : "password"}
                      onChange={onCambiaCampo}
                      onBlur={onBlurClave}
                      placeholder={cad("CONTRASEÑA")}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onCambiaMostrarClave}
                              onMouseDown={onCambiaMostrarClave}
                            >
                              {mostrarClave ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(erroresForm.clave2)}
                      helperText={erroresForm.clave2}
                      label={cad("REPETIR_CONTRASEÑA")}
                      name="clave2"
                      type={mostrarClave ? "text" : "password"}
                      onChange={onCambiaCampo}
                      onBlur={onBlurClave2}
                      placeholder={cad("REPETIR_CONTRASEÑA")}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={onCambiaMostrarClave}
                              onMouseDown={onCambiaMostrarClave}
                            >
                              {mostrarClave ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ mt: 3 }}
                    container
                    justifyContent={"center"}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      type={"submit"}
                      sx={{ fontSize: "0.875rem" }}
                      disabled={contraseñasNoCoincidentes()}
                    >
                      {cad("CAMBIAR_CONTRASEÑA")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
          >
            <Link component={RouterLink} color="inherit" to="/" sx={{ mt: 2, fontSize: "0.875rem"}}>
              {cad("VOLVER")}
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item mt={4}>
        {mostrarCargando && (
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{ backgroundColor: "white.main" }}
          >
            {cad("POR_FAVOR_ESPERE")}
            <LinearProgress color="success" />
          </Alert>
        )}
      </Grid>

      <Grid item xs={12} mt={1}>
        {mostrarError && (
          <Alert variant="filled" severity="error">
            {mostrarError}
          </Alert>
        )}
      </Grid>
    </AutLayout>
  );

  return esMovil ? mobileView() : desktopView();
};
