import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Alert,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Chip,
  Divider,
  Link,
  Box,
  useMediaQuery,
} from "@mui/material";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import ArticleIcon from "@mui/icons-material/Article";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { AutContext } from "../../aut/context/AutContext";
import { AprLayout } from "../layout/AprLayout";
import { IdiomaContext } from "../../context/IdiomaContext";
import ModalDocumentosRechazados from "../../ui/components/utils/ModalDocumentosRechazados";
import getDocumentosRechazoResidente from "../../aut/services/getDocumentosRechazoResidente";
import getMotivoRechazoResidente from "../../aut/services/getMotivoRechazoResidente";
import { useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Spinner from "../../ui/components/utils/Spinner";
import { updateResidenteCorregido } from "../../aut/services/updateResidenteCorregido";
import { FormModificarPerfilAltaResidente } from "../../ui/FormModificarPerfilAltaResidente";
import { amber } from "@mui/material/colors";

const ModificacionPerfilPage = () => {
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [mostrarCompletado, setMostrarCompletado] = useState(null);
  const [openModal, setOpenModal] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [motivoDialogOpen, setMotivoDialogOpen] = useState(false);
  const { cad, idioma } = useContext(IdiomaContext);
  const { usuario, cerrarSesion } = useContext(AutContext);
  const [documentosCargados, setDocumentosCargados] = useState([]);
  const [documentosValidos, setDocumentosValidos] = useState(false);
  const [modalAbierto, setModalAbierto] = useState(false);

  const [
    modalDocumentosRechazadosAbierto,
    setModalDocumentosRechazadosAbierto,
  ] = useState(false);
  const [datosModificacionResidente, setDatosModificacionResidente] = useState({
    tipo: usuario?.tipo || "",
    nombre: usuario?.nombre || "",
    apellido1: usuario?.apellido1 || "",
    apellido2: usuario?.apellido2 || "",
  });
  const [loader, setLoader] = useState(false);

  const { authToken } = usuario;
  const theme = useTheme(); // Obtener el tema actual

  const {
    data: motivoRechazo,
    refetch: fetchMotivo,
    isLoading: isLoadingMotivo,
  } = useQuery({
    queryKey: [
      "getMotivoRechazoResidente",
      { idResidente: usuario.id, idioma, token: authToken },
    ],
    queryFn: () => getMotivoRechazoResidente(usuario.id, idioma, authToken),
    enabled: false,
  });

  const esMovil = useMediaQuery("(max-width:768px)");

  /*

  const { data: tiposDocumentos, isLoading: tiposDocumentosCargando } =
    useQuery({
      queryKey: [
        "getDocumentosRegistroPorTipoResidente",
        { tipo: usuario.tipo, idioma: idioma, token: authToken },
      ],
      queryFn: () =>
        getDocumentosRegistroPorTipoResidente(usuario.tipo, idioma, authToken),
    });
  
  */

  const {
    data: rechazoDocs,
    isLoading: documentosRechazadosCargando,
  } = useQuery({
    queryKey: [
      "getDocumentosRechazoResidente",
      { idResidente: usuario.id, idioma: idioma, token: authToken },
    ],
    queryFn: () => getDocumentosRechazoResidente(usuario.id, idioma, authToken),
  });

  /*   useEffect(() => {
    if (tiposDocumentosCargando) return;
    const idsDocsCargados = documentosCargados
      .map((doc) => doc.id?.toString())
      .sort();
    const idsTiposDocs = Array.from(Object.keys(tiposDocumentos)).sort();
    setDocumentosValidos(
      JSON.stringify(idsDocsCargados) === JSON.stringify(idsTiposDocs)
    );
  }, [documentosCargados, tiposDocumentos]);
 */

  useEffect(() => {
    if (!documentosCargados || !rechazoDocs?.documentosRechazados) {
      setDocumentosValidos(false);
      return;
    }
  
    let idsDocsCargados = documentosCargados.map((doc) => doc.id?.toString()).sort();
    let idsDocsRechazados = Object.keys(rechazoDocs.documentosRechazados).sort();
  
    if(idsDocsRechazados > 0) {
    console.log("Documentos rechazados:", idsDocsRechazados);
    console.log("Documentos cargados:", idsDocsCargados);
    }

    // Comparar ambos arrays para determinar si son válidos
    setDocumentosValidos(
      JSON.stringify(idsDocsCargados) === JSON.stringify(idsDocsRechazados)
    );
  }, [documentosCargados, rechazoDocs]);

  const onDatosEmitidos = async () => {
    console.log(
      "Datos enviados desde el formulario:",
      datosModificacionResidente
    );
    setMostrarCargando(true);
    setMostrarError(null);

    const res = await updateResidenteCorregido(
      idioma,
      {
        ...datosModificacionResidente,
        id: usuario.id,
        correoe: usuario.correoe,
        nif: usuario.nif,
        documentosCargados,
      },
      authToken
    );
    if (res?.datos) {
      console.log("Datos enviados", res.datos);
      setMostrarCompletado("Sus datos han sido guardados con éxito");
      setMostrarCargando(false);
      setTimeout(() => {
        setLoader(true);
        setMostrarCompletado(null);
        setTimeout(() => {
          cerrarSesion();
        }, 2000);
      }, 3000);
    } else {
      setMostrarError(res.error || "Error al actualizar los datos.");
      setMostrarCargando(false);
    }
  };

  const handleCloseModal = () => {
    if ((usuario.estado !== "2" && usuario.estado !== "3") || checkboxChecked) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (!openModal && usuario.estado === "0") {
      cerrarSesion();
    }
  }, [openModal, usuario.estado]);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleVerMotivo = async (event) => {
    event.preventDefault();
    setMotivoDialogOpen(true);
    await fetchMotivo();
  };

  const handleCloseMotivoDialog = () => {
    setMotivoDialogOpen(false);
  };

  const getEstadoChip = (estado) => {
    if (estado === "0") {
      return (
        <Chip
          icon={<HourglassEmptyIcon style={{ color: amber[500] }} />}
          label={cad("PENDIENTE")}
          color="warning"
          variant="outlined"
          sx={{
            ml: 2,
            borderColor: amber[500],
            color: amber[500],
            "& .MuiChip-icon": {
              color: amber[500],
            },
          }}
        />
      );
    } else if (estado === "2") {
      return (
        <Chip
          icon={<ErrorIcon style={{ color: "#FF0000" }} />}
          label={cad("RECHAZADO")}
          sx={{
            color: "#FF0000",
            borderColor: "#FF0000",
            "& .MuiChip-icon": {
              color: "#FF0000",
            },
          }}
          variant="outlined"
        />
      );
    } else if (estado === "3") {
      return (
        <Chip
          icon={<ErrorIcon style={{ color: "#FF0000" }} />}
          label={cad("RENOVAR")}
          sx={{
            color: "#FF0000",
            borderColor: "#FF0000",
            "& .MuiChip-icon": {
              color: "#FF0000",
            },
          }}
          variant="outlined"
        />
      );
    } else {
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={cad("Estado desconocido")}
          color="default"
          variant="outlined"
          sx={{ ml: 2 }}
        />
      );
    }
  };

  return (
    <AprLayout>
      <ModalDocumentosRechazados
        modalAbierto={modalDocumentosRechazadosAbierto}
        setModalAbierto={setModalDocumentosRechazadosAbierto}
        tiposDocumentos={rechazoDocs?.documentosRechazados}
        documentosCargados={documentosCargados}
        setDocumentosCargados={setDocumentosCargados}
        idResidente={usuario.id}
      />

      <Dialog
        open={openModal}
        onClose={null}
        disableEscapeKeyDown
        disableBackdropClick={{
          onClick: (e) => e.stopPropagation(),
        }}
        sx={{ padding: 3 }}
      >
        <DialogContent sx={{ padding: 4 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Typography variant="h6">
              {cad("INFORMACION_IMPORTANTE")}
            </Typography>
          </Grid>

          <Divider />
          {console.log('Datos doc rech:', rechazoDocs)}
          {console.log('Usuario ===>:', usuario)}

          {usuario.estado === "0" && (
            <Typography sx={{ mt: 2 }}>
              {`${cad("APR_PENDIENTE_MENSAJE_1")}.`}
            </Typography>
          )}

          {usuario.estado === "2" && (
            <Typography sx={{ mt: 2 }}>
              {`${cad("APR_RECHAZADO_MENSAJE_1")}.`}
            </Typography>
          )}

          {usuario.estado === "3" && (
            <Typography sx={{ mt: 2 }}>
              {`${cad("APR_RENOVACION_MENSAJE_1")}.`}
            </Typography>
          )}

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>
                {cad("ESTADO")}
              </Typography>
            </Grid>

            <Grid item>{getEstadoChip(usuario.estado)}</Grid>
          </Grid>

          {usuario.estado === "0" && (
            <>
              <Typography sx={{ mt: 2 }}>
                {`${cad("APR_PENDIENTE_MENSAJE_2")}.`}
              </Typography>
            </>
          )}

          {usuario.estado === "2" && (
            <>
              <Typography sx={{ mt: 2 }}>
                {`${cad("APR_RECHAZADO_MENSAJE_2")} -> `}
                <Link
                  href="#"
                  onClick={handleVerMotivo}
                  color="secondary"
                  sx={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {cad("VER_MOTIVO")}
                </Link>
              </Typography>

              <Grid
                container
                direction="column"
                alignItems="flex-start"
                sx={{ mt: 2 }}
              >
                <Typography>{`${cad("APR_RECHAZADO_MENSAJE_3")}.`}</Typography>
              </Grid>
            </>
          )}

          {usuario.estado === "3" && (
            <>
              <Typography sx={{ mt: 2 }}>
                {`${cad("APR_RENOVACION_MENSAJE_2")}`}
              </Typography>

              <Grid
                container
                direction="column"
                alignItems="flex-start"
                sx={{ mt: 2 }}
              >
                <Typography>{`${cad("APR_RENOVACION_MENSAJE_3")}.`}</Typography>
              </Grid>
            </>
          )}

          {(usuario.estado === "2" || usuario.estado === "3") && (
            <FormControlLabel
              sx={{
                mt: 2,
                "& .MuiFormControlLabel-label": { fontSize: "0.875em" },
              }}
              control={
                <Checkbox
                  sx={{ pr: 1 }}
                  checked={checkboxChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label={`${cad("APR_RECHAZADO_CHECKBOX_MENSAJE")}.`}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            onClick={handleCloseModal}
            color="primary"
            disabled={(usuario.estado === "2" || usuario.estado === "3") && !checkboxChecked}
            variant="contained"
            startIcon={<BookmarkAddedIcon />}
          >
            {cad("CONTINUAR")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={motivoDialogOpen}
        onClose={handleCloseMotivoDialog}
        sx={{ padding: 5 }}
      >
        <DialogTitle sx={{ padding: 3 }}>
          {cad("MOTIVO_DEL_RECHAZO")}
        </DialogTitle>

        <Divider sx={{ ml: 3, mr: 3 }} />

        <DialogContent sx={{ padding: 3 }}>
          {isLoadingMotivo ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Spinner />
            </Grid>
          ) : (
            <Typography
              component="div"
              sx={{
                p: 2,
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                backgroundColor: "#f8f8f8",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: motivoRechazo }} />
            </Typography>
          )}
        </DialogContent>

        <Divider sx={{ ml: 3, mr: 3 }} />

        <DialogActions sx={{ padding: 3, justifyContent: "flex-end" }}>
          <Button
            onClick={handleCloseMotivoDialog}
            color="primary"
            variant="contained"
          >
            {cad("CERRAR")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        item
        lg={6}
        xs={12}
        sx={{
          mt: esMovil ? 4 : 8,
          mx: 3,
          p: 2,
          borderRadius: 2,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "secondary.main",
        }}
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pt: 1,
            pb: 2,
            pl: esMovil ? 1 : 0,
            ml: esMovil ? 1 : 2,
            mr: esMovil ? 1 : 2,
            gap: esMovil ? 1 : 2,
          }}
        >
          <Grid
            item
            sx={{
              color: "primary.main",
            }}
          >
            <Typography variant="h4" sx={{ color: "black.main" }}>
              {cad("MIS_DATOS")}
            </Typography>
          </Grid>

          <Grid item>{getEstadoChip(usuario.estado)}</Grid>
        </Grid>

        <Grid item xs={12} alignItems="center">
          <FormModificarPerfilAltaResidente
            perfil={usuario}
            onEmitirDatos={onDatosEmitidos}
            onError={setMostrarError}
            datosModificacionResidente={datosModificacionResidente}
            setDatosModificacionResidente={setDatosModificacionResidente}
          />
        </Grid>

        {(usuario.estado === "2" || usuario.estado === "3") && (
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {rechazoDocs && rechazoDocs.documentosRechazados &&
              Object.keys(rechazoDocs.documentosRechazados).length > 0 && (
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Button
                    sx={{
                      width: "100%",
                      backgroundColor: documentosValidos
                        ? "success.main"
                        : "error.main",
                      "&:hover": {
                        backgroundColor: documentosValidos
                          ? "success.hover"
                          : "error.hover",
                      },
                      fontSize: "0.875rem",
                    }}
                    variant="contained"
                    startIcon={<ArticleIcon />}
                    type={"button"}
                    onClick={() => setModalDocumentosRechazadosAbierto(true)}
                  >
                    {cad("DOCUMENTOS")}
                  </Button>
                </Grid>
            )}

            <Grid item xs={12} sm={4} md={3} lg={1.5}>
              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.875rem",
                  backgroundColor: "button.secondary.main",
                  "&:hover": {
                    backgroundColor: "button.secondary.hover",
                  },
                }}
                variant="contained"
                color="info"
                startIcon={<InfoIcon />}
                type="button"
                onClick={() => setOpenModal(true)}
              >
                {cad("INFO")}
              </Button>
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={1.5}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  fontSize: "0.875rem",
                  backgroundColor: "button.primary.main",
                  "&:hover": {
                    backgroundColor: "button.primary.hover",
                  },
                }}
                startIcon={<SaveIcon />}
                onClick={onDatosEmitidos}
              >
                {cad("ENVIAR")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={8} mt={5}>
        {mostrarCargando && (
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{ backgroundColor: "white" }}
          >
            {cad("POR_FAVOR_ESPERE")}
            <LinearProgress color="success" />
          </Alert>
        )}
        {loader && (
          <>
            <Alert variant="filled" severity="success">
              {mostrarCompletado}
            </Alert>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <Spinner />
            </Box>
          </>
        )}
      </Grid>
      <Grid item xs={8} mt={1}>
        {mostrarError && (
          <Alert variant="filled" severity="error">
            {mostrarError}
          </Alert>
        )}
      </Grid>
      <Grid item xs={8} mt={1}>
        {mostrarCompletado && !loader && (
          <Alert variant="filled" severity="success">
            {mostrarCompletado}
          </Alert>
        )}
      </Grid>
    </AprLayout>
  );
};

export default ModificacionPerfilPage;
