import { AprLayout } from "../layout/AprLayout"
import { cfg } from '../../configuracion'
import { useContext, useEffect, useState } from "react";
import { AutContext } from "../../aut/context/AutContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useFormulario } from "../../hooks/useFormulario";
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from "../../ui/components/AlertDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIconOutlined from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIconOutlined from '@mui/icons-material/ThumbDownOutlined';

const solicitudPermisosPendientesValidar = async (idioma) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const url = cfg.apiUrl + '/permisospendientes';
        const resp = await fetch(url, {
            method: 'GET',
            headers: { 
                'Accept-Language': idioma,
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            }
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
}

const borrarPermiso = async (idioma, id) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const resp = await fetch(cfg.apiUrl +'/permiso?id=' + id, {
            method: 'DELETE',
            headers: { 
                'Accept-Language': idioma,
                'Content-Type': 'application/json',
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            },
            //body: JSON.stringify(json)
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
}

const validarPermiso = async (idioma, permiso) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const resp = await fetch(cfg.apiUrl +'/permiso', {
            method: 'PUT',
            headers: { 
                'Accept-Language': idioma,
                'Content-Type': 'application/json',
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            },
            body: JSON.stringify(permiso)
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
}

export const ValPermisosPage = () => {
    const { datosGenerales } = useContext(AppContext);
    const { sesionIniciada, usuario, iniciarSesion, cerrarSesion } = useContext(AutContext);
    const { cad } = useContext(IdiomaContext);
    const [permisos, setPermisos] = useState([]);
    const [mostrarError, setMostrarError] = useState(null);
    const [erroresForm, setErroresForm] = useState({});
    const [alert, setAlert] = useState({ titulo: '', texto:'' });
    const {estadoForm, onCambiaCampo} = useFormulario({area: 0, matricula: ''});
    const [idPermisoCambiar, setIdPermisoCambiar] = useState(0);
    const [abrirDialogoBorrar,  setAbrirDialogoBorrar]  = useState(false);
    const [abrirDialogoAprobar, setAbrirDialogoAprobar] = useState(false);
    const [abrirDialogoDenegar, setAbrirDialogoDenegar] = useState(false);

    useEffect( () => {
        solicitudPermisosPendientesValidar('es')
        .then(({datos}) => {
            console.log(datos);
            setPermisos(datos);
        })
        .catch(error => console.error(error));
    }, []);

    const onEnviar = (event) => {
        event.preventDefault();
        
        if (erroresForm.matricula || erroresForm.area) {
            return;
        }

        let area = estadoForm?.area;
        if (!area && areas?.length && areas?.length==1) {
            area = areas[0].id;
        }

        const json = {
            "id": 0,
            "idArea": area,
            "matricula": estadoForm.matricula,
            "fechaDesde": null,
            "fechaHasta": null,
            "idResidente": usuario.id,
            "validado": null
        };

        const idPermiso = 3333333;
        const aprobado = true;
        /*
        validarPermiso('es', idPermiso, aprobado).then(resp => {
            if (resp?.datos) {
                //agregarPermisoPermanenteLocal(json);
                setAlert({ titulo: 'Permiso guardado correctamente', texto: '' });
            }
            if (resp?.error) {
                setAlert({ titulo: 'Se ha producido un error guardando permiso', texto: '' });
            }
        }).catch(error => {
            setAlert({ titulo: 'Se ha producido un error guardando permiso', texto: '' });
        });
        */
    }

    const eliminarPermisoDelEstado = (id) => {
        setPermisos(permisos.filter(per => per.id !== id));
    }

    const cambiarPermisoDelEstado = (id, validacion) => {
        setPermisos(
            [
                ...permisos.filter(per => per.id !== id),
                {
                    ...permisos.find(per => per.id === id),
                    validado: validacion ? '1' : '0'
                }
            ]
        );
    }

    const onCerrarDialogoBorrar=(aceptar) => {
        if (aceptar) {
            borrarPermiso('es', idPermisoCambiar)
            .then(() => {
                console.log('Borrar ' + idPermisoCambiar);
                eliminarPermisoDelEstado(idPermisoCambiar);
                setIdPermisoCambiar(0);
            })
            .catch(error => console.error(error))
            .finally(() => {setAbrirDialogoBorrar(false);});
        } else {
            setIdPermisoCambiar(0);
            setAbrirDialogoBorrar(false);
        }
    }

    const onCerrarDialogoAprobar=(aceptar) => {
        if (aceptar) {
            validarPermiso('es', {
                ...permisos.find(per => per.id === idPermisoCambiar),
                validado: '1'
            })
            .then(() => {
                console.log('Val. Aprobar ' + idPermisoCambiar);
                cambiarPermisoDelEstado(idPermisoCambiar, true);
                setIdPermisoCambiar(0);
            })
            .catch(error => console.error(error))
            .finally(() => {setAbrirDialogoAprobar(false);});
        } else {
            setIdPermisoCambiar(0);
            setAbrirDialogoAprobar(false);
        }
    }

    const onCerrarDialogoDenegar=(aceptar) => {
        if (aceptar) {
            validarPermiso('es', {
                ...permisos.find(per => per.id === idPermisoCambiar),
                validado: '0'
            })
            .then(() => {
                console.log('Val. Denegar ' + idPermisoCambiar);
                cambiarPermisoDelEstado(idPermisoCambiar, false);
                setIdPermisoCambiar(0);
            })
            .catch(error => console.error(error))
            .finally(() => {setAbrirDialogoDenegar(false);});
        } else {
            setIdPermisoCambiar(0);
            setAbrirDialogoDenegar(false);
        }
    }


    const onBorrarClick = (id) => {
        console.log('Borrando... ' + id);
        setIdPermisoCambiar(id);
        setAbrirDialogoBorrar(true);
    }

    const onAprobarClick = (id) => {
        console.log('Aprobando... ' + id);
        setIdPermisoCambiar(id);
        setAbrirDialogoAprobar(true);
    }

    const onRechazarClick = (id) => {
        console.log('Rechazando... ' + id);
        setIdPermisoCambiar(id);
        setAbrirDialogoDenegar(true);
    }
    
    const areas = Object.entries(datosGenerales?.zonas).map(( [k, v] ) => ({ id: Number(k), nombre: v }));
    
    return(
        <>
        <AprLayout>
            <Grid container item xs={10} sx={{ mt:3 }}>
                <Typography variant="h4" color="primary.main" >Verificar Permisos</Typography>
            </Grid>
            <Grid container item xs={10}
                justifyContent={"space-between"}
                sx={{
                    mt:3,
                    pt: 2, pl: 4, pb: 4, pr: 4,
                    borderRadius: 2,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'secondary.main',
                    //backgroundColor: 'primary.main',                    
                }}> 

                {false && (<Grid item xs={6} >
                    <FormControl fullWidth
                        error = { Boolean(erroresForm.area) } 
                    > 
                        <InputLabel id="demo-simple-select-label">Área</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                                estadoForm.area
                                || ((areas?.length == 1) ? areas[0].id : '0')
                            }
                            name="area"
                            label="Área"
                            disabled={ (!areas?.length) || areas?.length==1 }
                            onChange={onCambiaCampo}
                            //onBlur={onBlurArea}
                        >
                            {
                                (Boolean(!areas?.length) || areas.length >1)
                                && (<MenuItem key="0" value="0"><em>(Seleccione opción...)</em></MenuItem>)
                            }
                            {
                                (areas?.length) ?
                                    areas.map(area => (
                                        <MenuItem key={area.id} value={area.id}>{area.nombre}</MenuItem>
                                    ))
                                    : ''
                            }
                        </Select>
                        <FormHelperText>{ erroresForm.area }</FormHelperText>
                    </FormControl>
                </Grid>)}


                <Grid container item xs={8} sx={{ mt:2 }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Borrar</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }}>Matricula</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Área</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Fecha</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Validar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {permisos && permisos.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">
                                        <IconButton onClick={() => onBorrarClick(row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">{row.matricula}</TableCell>
                                    <TableCell align="center">
                                        {(areas.find(area => area.id === row.idArea)?.nombre) || '(' + row.idArea + ')'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.fechaDesde && row.fechaDesde.split(' ')[0]}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.validado && (<IconButton>
                                            {row.validado === '1' 
                                                ? <ThumbUpIconOutlined color={'secondary'} /> 
                                                : <ThumbDownIconOutlined color={'error'} />
                                            }
                                            </IconButton>)
                                        }
                                        {!row.validado && (<>
                                            <IconButton onClick={() => onAprobarClick(row.id)}>
                                                <ThumbUpIcon color={'secondary'} />
                                            </IconButton>
                                            <IconButton onClick={() => onRechazarClick(row.id)}>
                                                <ThumbDownIcon color={'error'} />
                                            </IconButton>
                                        </>)}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </AprLayout>
        <AlertDialog titulo={alert.titulo} texto = {alert.texto} />
        <Dialog
            open={abrirDialogoBorrar}
            onClose={() => onCerrarDialogoBorrar(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {'¿Seguro que quiere borrar el permiso ' + 
                permisos?.find(
                    per => per.id === idPermisoCambiar)?.matricula + '?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    El usuario no será notificado. Si lo que desea es denegar la petición, utilice el icono de denegar.
                    <br />
                    ¿Desea borrarlo igualmente?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCerrarDialogoBorrar(false)}>No</Button>
                <Button onClick={() => onCerrarDialogoBorrar(true)} autoFocus>Sí</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={abrirDialogoAprobar}
            onClose={() => onCerrarDialogoAprobar(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {'¿Seguro que quiere aprobar el permiso ' + 
                permisos?.find(
                    per => per.id === idPermisoCambiar)?.matricula + '?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    El usuario será notificado de la aprobación.
                    <br />
                    ¿Desea aprobar la solicitud?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCerrarDialogoAprobar(false)}>No</Button>
                <Button onClick={() => onCerrarDialogoAprobar(true)} autoFocus>Sí</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={abrirDialogoDenegar}
            onClose={() => onCerrarDialogoDenegar(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {'¿Seguro que quiere denegar el permiso ' + 
                permisos?.find(
                    per => per.id === idPermisoCambiar)?.matricula + '?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    El usuario será notificado del rechazo.
                    <br />
                    ¿Desea denegar la solicitud?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCerrarDialogoDenegar(false)}>No</Button>
                <Button onClick={() => onCerrarDialogoDenegar(true)} autoFocus>Sí</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}