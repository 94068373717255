import { AprLayout } from "../layout/AprLayout";
import { cfg } from "../../configuracion";
import { useContext, useEffect, useState } from "react";
import { AutContext } from "../../aut/context/AutContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import { TablaAreasInfo } from "../../ui/components/TablaAreasInfo";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useFileContext } from "../../context/FileContext";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Fab,
  alpha,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SyncIcon from "@mui/icons-material/Sync";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "../../ui/components/utils/Spinner";
import { useTheme } from "@emotion/react";
import { TablaDocumentos } from "../../ui/components/TablaDocumentos";
import { useFormulario } from "../../hooks/useFormulario";
import { TablaHorarios } from "../../ui/components/TablaHorarios";
import { useNavigate } from "react-router-dom";
import { DialogSolicitarRenovacion } from "../../ui/components/utils/DialogSolicitarRenovacion";

const convertirCadenaAFecha = (strFecha) => {
  const parts = strFecha.split(/[\s/:]+/);
  return new Date(
    parseInt(parts[2]), // Año
    parseInt(parts[1]) - 1, // Mes
    parseInt(parts[0]), // Día
    parseInt(parts[3]), // Horas
    parseInt(parts[4]), // Minutos
    parseInt(parts[5]) // Segundos
  );
};

const caducadoPermiso = (fecha) => {
  if (!fecha) return false;
  const fCadu = convertirCadenaAFecha(fecha);
  const fHoy = new Date();
  return fHoy > fCadu;
};

const solicitudPermisos = async (idioma, idResidente, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const url = `${cfg.apiUrl}/permisos/${idResidente}`;
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });
    if (resp.status === 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos: datos.datos };
    } else {
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

const borrarPermiso = async (idioma, id, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const url = `${cfg.apiUrl}/permiso/${id}`;
    const resp = await fetch(url, {
      method: "DELETE",
      headers: {
        "Accept-Language": idioma,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });
    if (resp.status === 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos };
    } else {
      let errorData = await resp.json();
      respuesta = { ...respuesta, error: errorData };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

const renovarPermiso = async (idioma, permiso, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const resp = await fetch(`${cfg.apiUrl}/permiso/renovar/${permiso}`, {
      method: "POST",
      headers: {
        "Accept-Language": idioma,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });
    if (resp.status === 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos };
    } else {
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

export const PermisosPage = () => {
  const { usuario } = useContext(AutContext);
  const { cad, idioma } = useContext(IdiomaContext);
  const [permisos, setPermisos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [documentosCargados, setDocumentosCargados] = useState([]);
  const [documentosValidos, setDocumentosValidos] = useState(false);
  const [refrescarPermisos, setRefrescarPermisos] = useState(0);
  const [idPermisoCambiar, setIdPermisoCambiar] = useState(0);
  const [abrirDialogoBorrar, setAbrirDialogoBorrar] = useState(false);
  const [abrirDialogoSolicitudRenovacion, setAbrirDialogoSolicitudRenovacion] =
    useState(false);
  const [datosPeticionRenovacion, setDatosPeticionRenovacion] = useState(null);
  const [abrirDialogDatosRenovacion, setAbrirDialogDatosRenovacion] =
    useState(false);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);
  const [mostrarEmail, setMostrarEmail] = useState(false);
  const [alert, setAlert] = useState({ titulo: "", texto: "" });
  const [erroresForm, setErroresForm] = useState({});

  const esMovil = useMediaQuery("(max-width:768px)");
  const theme = useTheme();

  const { estadoForm, onCambiaCampo, cambiarEstadoForm } = useFormulario({
    ficheros: [],
    email: "",
  });

  const onCerrarAlert = () => {
    setAlert({});
  };

  const validarEmail = (value) => {
    let cadErr = "";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value && !emailRegex.test(value)) {
      cadErr = "El formato del email no es válido";
    }

    return cadErr;
  };

  const onBlurEmail = ({ target }) => {
    const { name, value } = target;
    const msg = validarEmail(value);

    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const handleEmailOptionChange = (event) => {
    setMostrarEmail(event.target.value === "si");
    if (event.target.value === "no") {
      onCambiaCampo({ target: { name: "email", value: "" } });
    }
  };

  const abrirDialogo = (titulo, texto) => {
    if (!titulo && !texto) return;
    setAlert({ titulo: titulo, texto: texto, onCerrar: onCerrarAlert });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (usuario && usuario.id) {
      setFilaSeleccionada(null);
      setLoading(true);

      solicitudPermisos(idioma, usuario.id, usuario.authToken)
        .then(({ datos, error }) => {
          if (datos) {
            setPermisos(datos);
          } else if (error) {
            console.error("Error al obtener los permisos:", error);
          } else {
            setPermisos([]);
          }
        })
        .catch((error) =>
          console.error("Error en la solicitud de permisos:", error)
        )
        .finally(() => setLoading(false));
    } else {
      console.error("usuario.id no está definido");
    }
  }, [usuario?.id, refrescarPermisos, idioma]);

  useEffect(() => {
    if (permisos && permisos.length > 0) {
      permisos.forEach((permiso) => {
        /* console.log("%Permiso:", permiso); */
      });
    }
  }, [permisos]);

  const eliminarPermisoDelEstado = (id) => {
    setPermisos((prevPermisos) => prevPermisos.filter((per) => per.id !== id));
  };

  const onCerrarDialogoBorrar = async (aceptar) => {
    const { authToken } = usuario;
    if (aceptar) {
      try {
        const respuesta = await borrarPermiso(
          idioma,
          idPermisoCambiar,
          authToken
        );
        if (respuesta.datos) {
          eliminarPermisoDelEstado(idPermisoCambiar);
          if (filaSeleccionada && filaSeleccionada.id === idPermisoCambiar) {
            setFilaSeleccionada(null);
          }
        } else {
          console.error("%Error al borrar el permiso:", respuesta.error);
        }
      } catch (error) {
        console.error("Error en la petición de borrado:", error);
      } finally {
        setAbrirDialogoBorrar(false);
      }
    } else {
      setIdPermisoCambiar(0);
      setAbrirDialogoBorrar(false);
    }
  };

  const onCerrarDialogoRenovar = async (aceptar) => {
    const { authToken } = usuario;
    if (aceptar) {
      try {
        const respuesta = await renovarPermiso(
          idioma,
          idPermisoCambiar,
          authToken
        );
        if (respuesta.datos) {
          setIdPermisoCambiar(idPermisoCambiar);
          setRefrescarPermisos((prev) => prev + 1);
        } else {
          console.error("Error al renovar el permiso:", respuesta.error);
        }
      } catch (error) {
        console.error("Error en la petición de renovación:", error);
      } finally {
        setAbrirDialogoSolicitudRenovacion(false);
      }
    } else {
      setIdPermisoCambiar(null);
      setAbrirDialogoSolicitudRenovacion(false);
    }
  };

  const onBorrarClick = (id) => {
    const permisoSeleccionado = permisos.find((p) => p.id === id);
    if (permisoSeleccionado) {
      setFilaSeleccionada(permisoSeleccionado);
    }
    setIdPermisoCambiar(id);
    setAbrirDialogoBorrar(true);
  };

  const onRenovarClick = (id) => {
    const permiso = permisos.find((p) => p.id === id);
    if (permiso) {
      try {
        const { authToken } = usuario;
        //const respuesta = await renovarPermiso(idioma, id, authToken);

        renovarPermiso(idioma, id, authToken)
        .then((respuesta) => {
          if (respuesta.datos) {
            setDatosPeticionRenovacion(respuesta.datos);
            setFilaSeleccionada(permiso);
            setAbrirDialogoSolicitudRenovacion(true);
          } else {
            abrirDialogo("Error", "No se pudo obtener los datos de renovación");
          }
        })
        .catch((error) => {
          console.error("Error en la solicitud de renovación:", error);
          abrirDialogo("Error", "Ocurrió un error en la solicitud de renovación");
        });

        
      } catch (error) {
        console.error("Error en la solicitud de renovación:", error);
        abrirDialogo("Error", "Ocurrió un error en la solicitud de renovación");
      }
    }
  };

  const onFilaSeleccionada = (fila) => {
    setFilaSeleccionada(fila);
    /* console.log("%Fila", fila); */
  };

  const reemplazarComodines = (texto, comodines) => {
    if (typeof texto !== "string") {
      return texto || "";
    }

    let resultado = texto;
    for (const [key, value] of Object.entries(comodines)) {
      resultado = resultado.replace(`##${key}##`, value);
    }
    return resultado;
  };

  const tiposDocumentosMap = {
    RV: {
      doc1: "Certificado de Empadronamiento",
      doc2: "Titularidad del Vehículo",
    },
  };

  const tiposDocumentos = datosPeticionRenovacion?.tipoPeticion
    ? tiposDocumentosMap[datosPeticionRenovacion.tipoPeticion]
    : null;

  const areas = datosPeticionRenovacion?.datos?.motivo?.areas || {};
  const areasArray = Object.values(areas);

  const onBorrarDocumento = (idDoc) => {
    let nFicheros = estadoForm.ficheros.filter((e) => e.id !== idDoc);
    onCambiaCampo({ target: { name: "ficheros", value: nFicheros } });
  };

  const { resizeFile } = useFileContext();

  const onCargarDocumento = async (event, idDoc) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name) {
      const maxTamMb = 1;
      const archTam = selectedFile.size / (1024 * 1024); // Convertir a MB
      const archExt = selectedFile.name.split(".").pop().toLowerCase();

      if (
        !archExt.endsWith("jpg") &&
        !archExt.endsWith("jpeg") &&
        !archExt.endsWith("png") &&
        !archExt.endsWith("pdf")
      ) {
        abrirDialogo(
          "Tipo de fichero incorrecto",
          "Tipos de documento admitidos: PDF, JPG, PNG"
        );
        return;
      }


      if (archExt === "jpg" || archExt === "jpeg" || archExt === "png") { 
          // Comprime el archivo seleccionado
        //const compressedFile = await resizeFile(selectedFile, 1920, 1080);
        resizeFile(selectedFile, 1920, 1080, (compressedFile) => {
          // Actualiza los ficheros con el archivo comprimido
          let nFicheros = estadoForm.ficheros.filter((e) => e.id !== idDoc);
          nFicheros = [...nFicheros, { id: idDoc, fichero: compressedFile }];
          onCambiaCampo({ target: { name: "ficheros", value: nFicheros } });
        });
      } else {
        // Si el archivo supera 1MB, cancelar envío y mostrar error
        if (archTam > maxTamMb) {
          abrirDialogo("Error", "El archivo " + selectedFile.name + " supera el tamaño máximo permitido de 1MB.");
        } else {
          // Actualiza los ficheros con el archivo cargado
          let nFicheros = estadoForm.ficheros.filter((e) => e.id !== idDoc);
          nFicheros = [...nFicheros, { id: idDoc, fichero: selectedFile }];
          onCambiaCampo({ target: { name: "ficheros", value: nFicheros } });
        }
      }
    }
  };

  const guardarPeticion = async (idioma, formData, token) => {
    let respuesta = {
      datos: null,
      error: null,
    };
    try {
      const resp = await fetch(cfg.apiUrl + "/peticion", {
        method: "POST",
        headers: {
          "Accept-Language": idioma,
          //'Content-Type': 'application/json',
          //'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,

          // Deshabilitar provisionalmente la caché
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0"
          },
          body: formData,
      });
      if (resp.status === 200) {
        let datos = await resp.json();
        respuesta = { ...respuesta, datos };
      } else {
        respuesta = { ...respuesta, error: resp };
      }
    } catch (error) {
      respuesta = { ...respuesta, error };
    }
    return respuesta;
  };

  const onEnviarDatosRenovacion = (datosGuardados) => {
    const formDataToSend = new FormData();

    formDataToSend.append("id", datosGuardados.id);
    formDataToSend.append("idAprResidente", datosGuardados.idAprResidente);
    formDataToSend.append("matricula", datosGuardados.matricula);
    formDataToSend.append("idMotivo", datosGuardados.idMotivo);
    formDataToSend.append("areas", datosGuardados.areas.join(","));
    formDataToSend.append("fechaDesde", datosGuardados.fechaDesde);
    formDataToSend.append("fechaHasta", datosGuardados.fechaHasta);
    formDataToSend.append("correoeInteresado", datosGuardados.email || "");
    formDataToSend.append("tipo", datosGuardados.tipo);
    formDataToSend.append("tipoPeticion", datosGuardados.tipoPeticion);
    formDataToSend.append(
      "idPeticionRenovada",
      datosGuardados.idPeticionRenovada
    );

    const ficherosFormateados = datosGuardados.ficheros
      .map((fich) => `${fich.id}_${fich.fichero?.name}`)
      .join(",");

    
    formDataToSend.append("ficheros", ficherosFormateados);
    /*
    datosGuardados.ficheros.forEach((fich, index) => {
      formDataToSend.append(`archivos[${index}]`, fich.fichero);
      console.log('Agregando archivo', index, fich.fichero);
    });
    */
    datosGuardados.ficheros.forEach((fich) => {
      formDataToSend.append("archivos", fich.fichero);
    });

    /*
    const datosParaServidor = Object.fromEntries(formDataToSend.entries());
    console.log(
      "%Datos a enviar al servidor solicitando renovación",
      datosParaServidor
    );
    */

    guardarPeticion(idioma, formDataToSend, usuario.authToken).then((resp) => {
      if (resp?.datos) {
        abrirDialogo("Renovación guardada correctamente", "");
        navigate("/peticiones");
      } else if (resp?.error) {
        abrirDialogo("Error guardando la renovación", "");
      }
    });
  };

  const desktopView = () => (
    <AprLayout>
      <Grid
        container
        item
        xs={10}
        sx={{
          mt: 3,
          visibility: loading ? "hidden" : "visible",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" color="primary.main">
          {cad("PERMISOS_OTORGADOS")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={() => setRefrescarPermisos((prev) => prev + 1)}
          sx={{
            backgroundColor: "button.secondary.main",
            color: "white.main",
            opacity: 0.6,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "button.secondary.hover",
              opacity: 1,
            },
          }}
        >
          {cad("ACTUALIZAR")}
        </Button>
      </Grid>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Grid
          container
          item
          xs={10}
          justifyContent={"space-between"}
          sx={{
            mt: 3,
            pt: 2,
            pl: 4,
            pb: 4,
            pr: 4,
            borderRadius: 2,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "secondary.main",
          }}
        >
          <Grid container item xs={6} sx={{ mt: 2 }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("TIPO")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("MATRICULA")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("DESDE")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("HASTA")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("ESTADO")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permisos && permisos.length > 0 ? (
                    permisos.map((row) => (
                      <TableRow
                        key={row.id}
                        onClick={() => onFilaSeleccionada(row)}
                        selected={filaSeleccionada === row}
                        hover
                        sx={{
                          backgroundColor:
                            filaSeleccionada === row
                              ? alpha(theme.palette.secondary.main, 0.3)
                              : "inherit",
                          "&.Mui-selected": {
                            backgroundColor: alpha(
                              theme.palette.secondary.main,
                              0.1
                            ),
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: alpha(
                              theme.palette.secondary.main,
                              0.2
                            ),
                          },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {row.tipo === "T" && (
                            <Tooltip title={cad("TEMPORAL")}>
                              <CalendarMonthOutlinedIcon />
                            </Tooltip>
                          )}
                          {row.tipo === "P" && (
                            <Tooltip title={cad("PERMANENTE")}>
                              <KeyOutlined />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.matricula}
                        </TableCell>
                        <TableCell align="center">
                          {row.fechaDesde && row.fechaDesde.split(" ")[0]}
                        </TableCell>
                        <TableCell align="center">
                          {row.fechaHasta && row.fechaHasta.split(" ")[0]} 
                        </TableCell>
                        <TableCell align="center">
                          {row.tipo === "P" && row.comunicadoRenovacion === 1 && (
                              <Tooltip title={cad("SOLICITAR_RENOVACION")} disableInteractive>
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onRenovarClick(row.id);
                                    //onBorrarClick(row.id);
                                  }}
                                >
                                  <SyncIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          {(row.tipo === "T" || !caducadoPermiso(row.fechaHasta)) && (
                            <Tooltip title={cad("SOLICITAR_BAJA")} disableInteractive>
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  //onRenovarClick(row.id);
                                  onBorrarClick(row.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        sx={{ color: (theme) => theme.palette.gray.main }}
                      >
                        <Typography variant="body1" sx={{ color: "inherit" }}>
                          {cad("NO_HAY_PERMISOS")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {filaSeleccionada && (
            <Grid container item xs={4} sx={{ mt: 2, pl: 5 }}>
              <Grid item xs={12}>
                <TablaAreasInfo areas={filaSeleccionada.areas} />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {/* Dialog para borrar permiso */}
      <Dialog
        open={abrirDialogoBorrar}
        onClose={() => setAbrirDialogoBorrar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {cad("BORRAR_PERMISO")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reemplazarComodines(cad("SOLICITAR_BORRADO"), {
              MATRICULA: filaSeleccionada ? filaSeleccionada.matricula : "",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCerrarDialogoBorrar(false)} color="primary">
            {cad("CANCELAR")}
          </Button>
          <Button
            onClick={() => onCerrarDialogoBorrar(true)}
            color="primary"
            autoFocus
          >
            {cad("CONFIRMAR")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para confirmar solicitud de renovación */}
      <Dialog
        open={abrirDialogoSolicitudRenovacion}
        onClose={() => setAbrirDialogoSolicitudRenovacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {cad("SOLICITAR_RENOVACION")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reemplazarComodines(cad("CONFIRMAR_RENOVACION_MATRICULA"), {
              MATRICULA: filaSeleccionada ? filaSeleccionada.matricula : "",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCerrarDialogoRenovar(false)} color="error">
            {cad("NO")}
          </Button>
          <Button
            onClick={() => {
              setAbrirDialogDatosRenovacion(true);
              onCerrarDialogoRenovar(false);
            }}
            color="primary"
            autoFocus
          >
            {cad("SI")}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogSolicitarRenovacion
        open={abrirDialogDatosRenovacion}
        onClose={() => setAbrirDialogDatosRenovacion(false)}
        datosRenovacion={datosPeticionRenovacion?.datos}
        cad={cad}
        estadoForm={estadoForm}
        onCambiaCampo={onCambiaCampo}
        onBorrarDocumento={onBorrarDocumento}
        onCargarDocumento={onCargarDocumento}
        mostrarEmail={mostrarEmail}
        handleEmailOptionChange={handleEmailOptionChange}
        onBlurEmail={onBlurEmail}
        erroresForm={erroresForm}
        onGuardar={onEnviarDatosRenovacion}
      />
    </AprLayout>
  );

  const mobileView = () => (
    <AprLayout>
      <Grid
        container
        item
        xs={12}
        sx={{ my: 2, ml: 1, visibility: loading ? "hidden" : "visible" }}
      >
        <Typography align="left" variant="h4" color="primary.main">
          {cad("PERMISOS_OTORGADOS")}
        </Typography>
      </Grid>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Spinner />
        </Box>
      ) : permisos.length > 0 ? (
        <Grid container item xs={12} sx={{ mt: 2, gap: 1 }}>
          {permisos.map((row) => (
            <Accordion key={row.id} sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${row.id}-content`}
                id={`panel-${row.id}-header`}
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{ color: "primary.main", mr: 1 }}
                  >
                    {row.matricula} -
                  </Typography>
                  {row.tipo === "T" && (
                    <CalendarMonthOutlinedIcon color="primary" />
                  )}
                  {row.tipo === "P" && <KeyOutlined color="primary" />}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>{cad("DESDE")}:</span>{" "}
                  {row.fechaDesde && row.fechaDesde.split(" ")[0]}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>{cad("HASTA")}:</span>{" "}
                  {row.fechaHasta && row.fechaHasta.split(" ")[0]}
                </Typography>

                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>
                    {cad("AREAS_SIN_TMAX")}:
                  </span>{" "}
                  {row.areas && row.areas.length > 0
                    ? row.areas
                        .map((area) => area.nombreArea || area.nombre)
                        .join(", ")
                    : "-"}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  {row.tipo === "P" &&
                    row.renovable &&
                    row.comunicadoRenovacion === 1 && (
                      <Tooltip title={cad("RENOVACION_SOLICITADA")}>
                        <IconButton>
                          <PublishedWithChangesIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  {row.tipo === "P" || row.tipo === "T" &&
                    row.renovable &&
                    row.comunicadoRenovacion === 0 && (
                      <Tooltip title={cad("SOLICITAR_RENOVACION")}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onRenovarClick(row.id);
                          }}
                        >
                          <SyncIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    {(row.tipo === "T" || !caducadoPermiso(row.fechaHasta)) && (
                    <Tooltip title={cad("SOLICITAR_BAJA")}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onBorrarClick(row.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      ) : (
        <Grid container item xs={12} sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {cad("NO_HAY_PERMISOS")}
            </Typography>
          </Box>
        </Grid>
      )}

      <Dialog
        open={abrirDialogoBorrar}
        onClose={() => setAbrirDialogoBorrar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {cad("BORRAR_PERMISO")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reemplazarComodines(cad("SOLICITAR_BORRADO"), {
              MATRICULA: filaSeleccionada ? filaSeleccionada.matricula : "",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCerrarDialogoBorrar(false)} color="primary">
            {cad("CANCELAR")}
          </Button>
          <Button
            onClick={() => onCerrarDialogoBorrar(true)}
            color="primary"
            autoFocus
          >
            {cad("CONFIRMAR")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={abrirDialogoSolicitudRenovacion}
        onClose={() => setAbrirDialogoSolicitudRenovacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {cad("SOLICITAR_RENOVACION")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reemplazarComodines(cad("CONFIRMAR_RENOVACION_MATRICULA"), {
              MATRICULA: filaSeleccionada ? filaSeleccionada.matricula : "",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCerrarDialogoRenovar(false)} color="primary">
            {cad("CANCELAR")}
          </Button>
          <Button
            onClick={() => onCerrarDialogoRenovar(true)}
            color="primary"
            autoFocus
          >
            {cad("CONFIRMAR")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para confirmar solicitud de renovación */}
      <Dialog
        open={abrirDialogoSolicitudRenovacion}
        onClose={() => setAbrirDialogoSolicitudRenovacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {cad("SOLICITAR_RENOVACION")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reemplazarComodines(cad("CONFIRMAR_RENOVACION_MATRICULA"), {
              MATRICULA: filaSeleccionada ? filaSeleccionada.matricula : "",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCerrarDialogoRenovar(false)} color="error">
            {cad("NO")}
          </Button>
          <Button
            onClick={() => {
              setAbrirDialogDatosRenovacion(true);
              onCerrarDialogoRenovar(false);
            }}
            color="primary"
            autoFocus
          >
            {cad("SI")}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogSolicitarRenovacion
        open={abrirDialogDatosRenovacion}
        onClose={() => setAbrirDialogDatosRenovacion(false)}
        datosRenovacion={datosPeticionRenovacion?.datos}
        cad={cad}
        estadoForm={estadoForm}
        onCambiaCampo={onCambiaCampo}
        onBorrarDocumento={onBorrarDocumento}
        onCargarDocumento={onCargarDocumento}
        mostrarEmail={mostrarEmail}
        handleEmailOptionChange={handleEmailOptionChange}
        onBlurEmail={onBlurEmail}
        erroresForm={erroresForm}
        onGuardar={onEnviarDatosRenovacion}
      />

      <Box
        sx={{
          position: "fixed",
          top: 120,
          right: 16,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          zIndex: 10,
          visibility: loading ? "hidden" : "visible",
        }}
      >
        <Fab
          onClick={() => setRefrescarPermisos((prev) => prev + 1)}
          sx={{
            color: "white.main",
            backgroundColor: "button.secondary.main",
            opacity: 0.6,
            "&:hover": {
              backgroundColor: "button.secondary.hover",
              opacity: 1,
            },
            "&:active": {
              opacity: 1,
            },
          }}
        >
          <RefreshIcon />
        </Fab>
      </Box>
    </AprLayout>
  );

  return esMovil ? mobileView() : desktopView();
};
