import { Link, Link as RouterLink } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Alert,
  LinearProgress,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  useMediaQuery,
} from "@mui/material";
import { AutLayout } from "../layout/AutLayout";
import { useFormulario } from "../../hooks/useFormulario";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import { putCorreoConfirmacion } from "../services/putCorreoConfirmacion";
import { PublicTokenContext } from "../../context/PublicTokenContext";
import CustomCaptcha from "../../ui/components/utils/CustomCaptcha";
import SendIcon from "@mui/icons-material/Send";

import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";

export const ReenviarCorreoPage = () => {
  const { tokenCliente } = useContext(PublicTokenContext);
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [mostrarExito, setMostrarExito] = useState(false);
  const [selectTipoNif, setSelectTipoNif] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(true);
  const [erroresForm, setErroresForm] = useState({});

  const { cfg } = useContext(AppContext);
  const { cad } = useContext(IdiomaContext);

  const { estadoForm, onCambiaCampo } = useFormulario({
    nif_cif: "",
    tipo_residente: "",
  });

  const esMovil = useMediaQuery("(max-width:768px)");

  const isValidNIF = (nif) => {
    const nifRegex = /^[0-9]{8}[A-Z]$/;
    return nifRegex.test(nif);
  };

  const onCambiaNif = ({ target }) => {
    const { name, value } = target;
    const nValue = value
      .toUpperCase()
      .replace(/[^@#*ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]/g, "");
    const isNIF = isValidNIF(nValue);
  
    setSelectTipoNif(isNIF);
    if (!isNIF) {
      onCambiaCampo({ target: { name: "tipo_residente", value: "E" } });
    } else {
      onCambiaCampo({ target: { name: "tipo_residente", value: "" } });
    }
  
    onCambiaCampo({ target: { name, value: nValue } });
  };

  const handleCaptchaChange = (value) => {
    const esValido = validateCaptcha(value, false);
    setCaptchaValido(esValido);
    setErroresForm((previo) => ({
      ...previo,
      captcha: esValido ? null : cad("ERR_CAMPO_OBLIGATORIO"),
    }));
    if (esValido) {
      setCaptchaVisible(false);
    }
  };

  const onReenviarCorreo = async (event) => {
    event.preventDefault();

    if (erroresForm.nif_cif || erroresForm.captcha) {
      return;
    }

    if (!estadoForm.nif_cif || (selectTipoNif && !estadoForm.tipo_residente)) {
      setErroresForm((prev) => ({
        ...prev,
        nif_cif: cad("ERR_CAMPO_OBLIGATORIO"),
        captcha: !captchaValido ? cad("ERR_CAMPO_OBLIGATORIO") : null,
      }));
      return;
    }

    setMostrarCargando(true);

    try {

      const tipoResidente = estadoForm.tipo_residente || "E";

      const respuesta = await putCorreoConfirmacion(
        cad("idioma"),
        { nif: estadoForm.nif_cif, tipo: tipoResidente },
        tokenCliente
      );

      if (respuesta.error) {
        setMostrarError(respuesta.error);
      } else {
        setMostrarExito(true);
      }
    } catch (err) {
      setMostrarError(cad("Error en el envío de la solicitud"));
    }

    setMostrarCargando(false);
  };

  useEffect(() => {
    loadCaptchaEnginge(8); // Cargar el captcha con un código de 8 caracteres
  }, []);

  return (
    <AutLayout>
      <Grid container justifyContent="center">
        <Grid
          item
          className={"box-shadow"}
          lg={3}
          sm={12}
          sx={{
            backgroundColor: "rgba(255,255,255,0.95)",
            borderColor: "secondary.main",
            borderWidth: 2,
            borderStyle: "solid",
            padding: 2,
            borderRadius: 2,
            mt: 0,
          }}
        >
          {!esMovil && (
            <Typography color={"black.main"} variant="h5" sx={{ mb: 2 }}>
              {cad("REENVIAR_CORREO_CONFIRMACION")}
            </Typography>
          )}

          <Typography variant="body1" sx={{ mb: 2 }}>
            {`${cad("APR_TEXTO_CONFIRMAR_CORREO")}.`}
          </Typography>

          <form onSubmit={onReenviarCorreo} autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label={cad("NIF_CIF")}
                  type="text"
                  name="nif_cif"
                  fullWidth
                  onChange={onCambiaNif}
                  onBlur={(e) =>
                    setErroresForm((prev) => ({
                      ...prev,
                      nif_cif: !estadoForm.nif_cif
                        ? cad("ERR_CAMPO_OBLIGATORIO")
                        : null,
                    }))
                  }
                  value={estadoForm.nif_cif}
                  error={Boolean(erroresForm.nif_cif)}
                  helperText={erroresForm.nif_cif}
                />
              </Grid>

              {selectTipoNif && (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {cad("TIPO_RESIDENTE")}
                    </FormLabel>
                    <RadioGroup
                      sx={{ display: "flex", flexDirection: "row", ml: 1 }}
                      aria-label="tipo_residente"
                      name="tipo_residente"
                      value={estadoForm.tipo_residente}
                      onChange={onCambiaCampo}
                    >
                      <FormControlLabel
                        value="P"
                        control={<Radio />}
                        label={cad("ES_PARTICULAR")}
                      />
                      <FormControlLabel
                        value="A"
                        control={<Radio />}
                        label={cad("ES_AUTONOMO")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                {captchaVisible && (
                  <CustomCaptcha
                    reloadText={cad("GENERAR_OTRO_CAPTCHA")}
                    reloadColor="#347445"
                    onCaptchaChange={handleCaptchaChange}
                    erroresForm={erroresForm}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={<SendIcon />}
                  disabled={
                    !captchaValido ||
                    !estadoForm.nif_cif ||
                    (selectTipoNif && !estadoForm.tipo_residente)
                  }
                  sx={{
                    mt: 2,
                    backgroundColor: "primary.main",
                    color: "white.main",
                    opacity:
                      !captchaValido ||
                      !estadoForm.nif_cif ||
                      (selectTipoNif && !estadoForm.tipo_residente)
                        ? 0.6
                        : 1,
                    transition: "opacity 0.3s ease-in-out",
                    "&.hover": {
                      backgroundColor: "primary.hover",
                      color: "white.hover",
                      opacity: 1,
                    },
                  }}
                  fullWidth
                >
                  {cad("ENVIAR")}
                </Button>
              </Grid>
            </Grid>
          </form>

          {mostrarCargando && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Alert
                severity="info"
                icon={false}
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                {cad("POR_FAVOR_ESPERE")}
                <LinearProgress
                  color="success"
                  sx={{ ml: 2, width: "50%", height: "4px", borderRadius: 2 }}
                />
              </Alert>
            </Grid>
          )}

          <Grid
            sx={{ mt: 1 }}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
          >
            <Link
              component={RouterLink}
              to="/"
              sx={{
                mt: 2,
                fontSize: esMovil ? "0.875rem" : "1rem",
                color: "black.main",
                textDecoration: "none",
                "&:hover": {
                  color: "black.main",
                  textDecoration: "none",
                },
              }}
            >
              {cad("INICIO")}
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" mt={10}>
        {mostrarError && (
          <Grid item xs={8}>
            <Alert variant="filled" severity="error">
              {mostrarError}
            </Alert>
          </Grid>
        )}
        {mostrarExito && (
          <Grid item xs={8}>
            <Alert variant="filled" severity="success">
              {cad(
                "Solicitud completada con éxito. En el caso de que su usuario esté registrado, recibirá un correo electrónico para confirmar su cuenta"
              )}
            </Alert>
          </Grid>
        )}
      </Grid>
    </AutLayout>
  );
};

export default ReenviarCorreoPage;
