import {
  Grid,
  Box,
  Typography,
  AppBar,
  Container,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AutContext } from "../../aut/context/AutContext";
import { IdiomaSelector } from "../../ui/components/IdiomaSelector";

const getColorForEstado = (estado) => {
  switch (estado) {
    case "0":
      return "pending.main";
    case "1":
      return "white.main";
    case "2":
      return "error.main";
    default:
      return "white.main";
  }
};

const getFechaCaducidad = (usuario) => {
  if (usuario && usuario.fechaCaducidad) {
    const fecha = new Date(usuario.fechaCaducidad);
    const day = String(fecha.getDate()).padStart(2, "0");
    const month = String(fecha.getMonth() + 1).padStart(2, "0");
    const year = fecha.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return "";
};

export const AprLayout = ({ children }) => {
  const { cfg } = useContext(AppContext);
  const { idiomas, idioma, cambiarIdioma, cad } = useContext(IdiomaContext);
  const { usuario, cerrarSesion, loading } = useContext(AutContext);
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const esMovil = useMediaQuery("(max-width:768px)");
  const esMovilLayoutHorizontal = useMediaQuery(
    "(max-width:930px) and (orientation: landscape)"
  );

  const usuarioAprobado = usuario && usuario.estado === "1";

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const onCerrrarSesion = () => {
    cerrarSesion();
  };

  const theme = useTheme();

  const mobileView = () => (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          color: "white.main",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
          <Link
            to="/perfil"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.15rem",
                fontWeight: "bolder",
                color: getColorForEstado(usuario?.estado),
              }}
            >
              {usuario?.nombreCompleto}
            </Typography>
          </Link>

          <IdiomaSelector
            idiomas={idiomas}
            idiomaSeleccionado={idioma}
            onSeleccionCambia={cambiarIdioma}
          />
        </Box>
      </Box>

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
        >
          <Box>
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
              onClick={handleDrawerToggle}
            >
              <CloseIcon />
            </IconButton>

            <Box
              component={Link}
              to="/"
              onClick={handleDrawerToggle}
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 2,
              }}
            >
              <Box
                component="img"
                borderRadius={3}
                alt="Logo"
                src={cfg.apiUrl + "/public/logo2"}
                sx={{
                  width: "100px",
                  height: "auto",
                  transition: "opacity 0.2s ease-in-out",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "#f0f0f0",
              textAlign: "left",
              px: 2,
              py: 2,
            }}
          >
            <ListItem
              component={NavLink}
              to={usuarioAprobado ? "/perfil" : "/modificar-perfil"}
              activeClassName="Mui-selected"
              classes={{ selected: "Mui-selected" }}
              sx={{ p: 0, textDecoration: "none", color: "inherit" }}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bolder",
                      color: "inherit",
                      textDecoration: "none",
                      "&:visited": {
                        color: "inherit",
                      },
                      "&:hover": {
                        color: "inherit",
                      },
                      "&:active": {
                        color: "inherit",
                      },
                    }}
                  >
                    {usuario?.nombreCompleto}
                  </Typography>
                }
              />
            </ListItem>

            <Typography variant="body2" color="black.main" sx={{mt:1}}>
              {cad("FECHA_CADUCIDAD")} : {getFechaCaducidad(usuario)}
            </Typography>
          </Box>

          {usuarioAprobado && (
            <List>
              <ListItem
                button
                component={NavLink}
                to="/"
                key="Inicio"
                sx={{
                  backgroundColor:
                    pathname === "/"
                      ? alpha(theme.palette.secondary.main, 0.3)
                      : "transparent",
                }}
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={cad("INICIO")} />
              </ListItem>

              <ListItem
                button
                component={NavLink}
                to="/peticiones"
                key="Peticiones"
                sx={{
                  backgroundColor:
                    pathname === "/peticiones"
                      ? alpha(theme.palette.secondary.main, 0.3)
                      : "transparent",
                }}
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={cad("PETICIONES")} />
              </ListItem>

              <ListItem
                button
                component={NavLink}
                to="/permisos"
                key="Permisos"
                sx={{
                  backgroundColor:
                    pathname === "/permisos"
                      ? alpha(theme.palette.secondary.main, 0.3)
                      : "transparent",
                }}
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={cad("PERMISOS")} />
              </ListItem>
            </List>
          )}

          <Divider />

          <ListItem
            onClick={() => {
              onCerrrarSesion();
              handleDrawerToggle();
            }}
            sx={{
              width: "100%",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <LogoutIcon sx={{ mr: 1, mt: 1, color: "inherit" }} />
            <Typography sx={{ color: "inherit" }}>
              {cad("CERRAR_SESION")}
            </Typography>
          </ListItem>
        </Box>
      </Drawer>
    </>
  );

  const desktopView = () => (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          visibility: usuarioAprobado ? "visible" : "hidden",
        }}
      >
        <Box
          component="a"
          href="/"
          sx={{
            mr: 4,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            fontSize: 25,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
            height: "10vh",
          }}
        >
          <Box
            component="img"
            borderRadius={3}
            alt="Logo"
            src={cfg.apiUrl + "/public/logo2"}
            sx={{
              transition: "opacity 0.2s ease-in-out",
              "&:hover": {
                opacity: 0.8,
              },
              visibility: "visible"
            }}
          />
        </Box>
        <Button
          component={NavLink}
          to="/"
          key={"Inicio"}
          sx={{
            my: 2,
            color: pathname === "/" ? "secondary.main" : "white.main",
            display: "block",
            "&:hover": {
              color: pathname === "/" ? "secondary.main" : "white.hover",
              backgroundColor: "transparent",
            },
          }}
        >
          {cad("INICIO")}
        </Button>
        <Button
          component={NavLink}
          to="/peticiones"
          key={"Peticiones"}
          sx={{
            my: 2,
            color: pathname === "/peticiones" ? "secondary.main" : "white.main",
            display: "block",
            "&:hover": {
              color: pathname === "/" ? "secondary.main" : "white.hover",
              backgroundColor: "transparent",
            },
          }}
        >
          {cad("PETICIONES")}
        </Button>
        <Button
          component={NavLink}
          to="/permisos"
          key={"Permisos"}
          sx={{
            my: 2,
            color: pathname === "/permisos" ? "secondary.main" : "white.main",
            display: "block",
            "&:hover": {
              color: pathname === "/" ? "secondary.main" : "white.hover",
              backgroundColor: "transparent",
            },
          }}
        >
          {cad("PERMISOS")}
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          p: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "4px",
          }}
        >
          <Link
            to="/perfil"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.35rem",
                fontWeight: "bolder",
                color: getColorForEstado(usuario?.estado),
                "&:hover": {
                  color:
                    usuario?.estado === "1" &&
                    pathname !== "/perfil" &&
                    "white.hover",
                },
                textAlign: "center",
                mr: 1,
              }}
            >
              {usuario?.nombreCompleto}
            </Typography>
          </Link>

          <IdiomaSelector
            idiomas={idiomas}
            idiomaSeleccionado={idioma}
            onSeleccionCambia={cambiarIdioma}
            sx={{
              flexShrink: 0,
              overflow: "hidden",
              maxWidth: "40px",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.90rem",
              color: "white.main",
              whiteSpace: "nowrap",
              mr: 2,
            }}
          >
            {cad("FECHA_CADUCIDAD")} : {getFechaCaducidad(usuario)}
          </Typography>

          <Tooltip title={cad("CERRAR_SESION") || ""}>
            <IconButton
              sx={{
                p: 0,
                color: "error.main",
                "&:hover": {
                  color: "error.hover",
                },
              }}
              key={"Salir"}
              onClick={onCerrrarSesion}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      {!loading && (
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#454545",
            height: esMovilLayoutHorizontal
              ? "20vh"
              : esMovil
              ? "15vh"
              : "13vh",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Container maxWidth="xxl">
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px",
                width: "100%",
              }}
            >
              {esMovil || esMovilLayoutHorizontal
                ? mobileView()
                : desktopView()}
            </Toolbar>
          </Container>
        </AppBar>
      )}

      <Grid
        container
        p={1}
        sx={{
          minHeight: esMovilLayoutHorizontal
            ? "88vh"
            : esMovil
            ? "85vh"
            : "87vh",
          backgroundColor: "tertiary.main",
        }}
      >
        {!loading && (
          <Grid
            container
            item
            justifyContent={"center"}
            alignContent={"flex-start"}
          >
            {children}
          </Grid>
        )}
      </Grid>
    </>
  );
};
