import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  Divider,
  Box,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  AccordionSummary,
} from "@mui/material";
import { TablaHorarios } from "../TablaHorarios";
import { TablaDocumentos } from "../TablaDocumentos";
import Spinner from "./Spinner";

export const DialogSolicitarRenovacion = ({
  open,
  onClose,
  datosRenovacion,
  cad,
  estadoForm,
  onCambiaCampo,
  onBorrarDocumento,
  onCargarDocumento,
  mostrarEmail,
  handleEmailOptionChange,
  onBlurEmail,
  erroresForm,
  onGuardar,
}) => {
  const esMovil = useMediaQuery("(max-width:768px)");

  const formatearFecha = (strFecha) => {
    const fecha = new Date(strFecha);

    const dia = String(fecha.getUTCDate()).padStart(2, "0");
    const mes = String(fecha.getUTCMonth() + 1).padStart(2, "0");
    const año = fecha.getUTCFullYear();

    return `${dia}/${mes}/${año}`;
  };

  const guardarDatos = () => {
    const datosGuardados = {
      id: 0,
      idAprResidente: datosRenovacion.idAprResidente,
      matricula,
      motivo: motivoNombre,
      idMotivo,
      areas: areasIds,
      fechaDesde,
      fechaHasta,
      email: mostrarEmail ? estadoForm.email : null,
      documentos,
      ficheros: estadoForm.ficheros,
      tipo: "P",
      tipoPeticion: "RV",
      idPeticionRenovada,
    };

    onGuardar(datosGuardados);
    onClose();
  };

  // Si no hay datos de renovación, mostrar el spinner
  if (!datosRenovacion) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={esMovil ? "sm" : "md"}
        fullWidth
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <Spinner />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  const {
    matricula,
    motivo: {
      nombre: motivoNombre,
      id: idMotivo,
      horarios,
      documentos: documentos,
    },
    areasAprobadas,
    fechaDesde,
    fechaHasta,
    peticionOriginal: idPeticionRenovada,
  } = datosRenovacion;

  const areasArray = areasAprobadas
    ? areasAprobadas.map((area) => area.nombre)
    : [];
  const areasIds = areasAprobadas ? areasAprobadas.map((area) => area.id) : [];

  const todosDocumentosAportados =
    documentos && Object.keys(documentos).length > 0
      ? Object.keys(documentos).every((docKey) =>
          estadoForm.ficheros.some((fichero) => String(fichero.id) === docKey)
        )
      : true;

  // Versión de escritorio (desktopView)
  const desktopView = () => (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={esMovil ? "sm" : "md"}
      fullWidth
      sx={{ minWidth: esMovil ? "100%" : "800px" }}
    >
      <DialogTitle>{cad("SOLICITAR_RENOVACION")}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ mb: 3 }} />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ mb: 1, ml: 1 }}>
              <strong>{cad("MATRICULA")}:</strong> {matricula}
            </Typography>
            <Typography sx={{ mb: 1, ml: 1 }}>
              <strong>{cad("MOTIVO")}:</strong>{" "}
              {motivoNombre || "Motivo no disponible"}
            </Typography>
            <Typography sx={{ mb: 1, ml: 1 }}>
              <strong>{cad("AREAS_SIN_TMAX")}:</strong> {areasArray.join(", ")}
            </Typography>

            {documentos && Object.keys(documentos).length > 0 && (
              <Grid item xs={12}>
                <Divider sx={{ mt: 3 }} />
              </Grid>
            )}

            {documentos && Object.keys(documentos).length > 0 && (
              <Grid item xs={12} sm={12} sx={{ my: 3 }}>
                <Typography>
                  {cad("APR_TEXTO_RENOVACION_DOCUMENTOS")}:
                </Typography>
              </Grid>
            )}

            {documentos && Object.keys(documentos).length > 0 && (
              <Grid container item xs={12} sm={12} spacing={2}>
                <Grid item xs={12} sm={todosDocumentosAportados ? 6 : 12}>
                  <Typography sx={{ mb: 1, ml: 0 }}>
                    <strong>{cad("DOCUMENTOS")}:</strong>
                  </Typography>
                  <TablaDocumentos
                    documentos={documentos}
                    ficheros={estadoForm.ficheros || []}
                    onBorrar={onBorrarDocumento}
                    onCargar={onCargarDocumento}
                  />
                </Grid>

                {todosDocumentosAportados && (
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ mb: 1, ml: 0 }}>
                      <strong>{cad("HORARIOS")}:</strong>
                    </Typography>
                    <TablaHorarios horarios={horarios} />
                  </Grid>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider sx={{ mt: 3, mb: 3 }} />
            </Grid>

            <Grid
              item
              container
              justifyContent={"Flex-start"}
              alignItems={"flex-start"}
              sx={{ mx: 1 }}
            >
              <Grid item xs={12} sm={5}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {cad("AÑADIR_CORREO")} - ({cad("CAMPO_OPCIONAL")})
                  </FormLabel>
                  <RadioGroup
                    sx={{ display: "flex", flexDirection: "row", mb: 1 }}
                    aria-label="añadirCorreo"
                    name="añadirCorreo"
                    value={mostrarEmail ? "si" : "no"}
                    onChange={handleEmailOptionChange}
                  >
                    <FormControlLabel
                      value="si"
                      control={<Radio />}
                      label={cad("SI")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={cad("NO")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {mostrarEmail && (
                <Grid item xs={12} sm={5} ml={5}>
                  <TextField
                    error={Boolean(erroresForm.email)}
                    helperText={erroresForm.email}
                    label={cad("CORREO_E")}
                    name="email"
                    type="email"
                    placeholder={cad("CORREO_E")}
                    onChange={onCambiaCampo}
                    onBlur={onBlurEmail}
                    autoComplete="off"
                    value={estadoForm.email}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <Grid item xs={12}>
        <Divider sx={{ mt: 3, mb: 2 }} />
      </Grid>

      <DialogActions>
        <Button onClick={onClose} color="error">
          {cad("CANCELAR")}
        </Button>
        <Button
          disabled={!todosDocumentosAportados}
          onClick={() => guardarDatos()}
          color="primary"
          autoFocus
        >
          {cad("ENVIAR")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Provisional

  const mobileView = () => (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ minWidth: "100%" }}
    >
      <DialogTitle>{cad("SOLICITAR_RENOVACION")}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ mb: 3 }} />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ mb: 1, ml: 1 }}>
              <strong>{cad("MATRICULA")}:</strong> {matricula}
            </Typography>
            <Typography sx={{ mb: 1, ml: 1 }}>
              <strong>{cad("MOTIVO")}:</strong>{" "}
              {motivoNombre || "Motivo no disponible"}
            </Typography>
            <Typography sx={{ mb: 1, ml: 1 }}>
              <strong>{cad("AREAS_SIN_TMAX")}:</strong> {areasArray.join(", ")}
            </Typography>

            <Grid item xs={12}>
              <Divider sx={{ mt: 3 }} />
            </Grid>

            <Grid item xs={12} sm={12} sx={{ my: 3 }}>
              <Typography>{cad("APR_TEXTO_RENOVACION_DOCUMENTOS")}:</Typography>
            </Grid>

            <Grid container item xs={12} sm={12} spacing={2}>
              <Grid item xs={12} sm={todosDocumentosAportados ? 6 : 12}>
                <Typography sx={{ mb: 1, ml: 0 }}>
                  <strong>{cad("DOCUMENTOS")}:</strong>
                </Typography>
                <TablaDocumentos
                  documentos={documentos}
                  ficheros={
                    Array.isArray(estadoForm.ficheros)
                      ? estadoForm.ficheros
                      : null
                  }
                  onBorrar={onBorrarDocumento}
                  onCargar={onCargarDocumento}
                />
              </Grid>

              {todosDocumentosAportados && (
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ mb: 1, ml: 0 }}>
                    <strong>{cad("HORARIOS")}:</strong>
                  </Typography>
                  <TablaHorarios horarios={horarios} />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mt: 3, mb: 3 }} />
            </Grid>

            <Grid
              item
              container
              justifyContent={"Flex-start"}
              alignItems={"flex-start"}
              sx={{ mx: 1 }}
            >
              <Grid item xs={12} sm={5}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {cad("AÑADIR_CORREO")} - ({cad("CAMPO_OPCIONAL")})
                  </FormLabel>
                  <RadioGroup
                    sx={{ display: "flex", flexDirection: "row", mb: 1 }}
                    aria-label="añadirCorreo"
                    name="añadirCorreo"
                    value={mostrarEmail ? "si" : "no"}
                    onChange={handleEmailOptionChange}
                  >
                    <FormControlLabel
                      value="si"
                      control={<Radio />}
                      label={cad("SI")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={cad("NO")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {mostrarEmail && (
                <Grid item xs={12} sm={5} ml={5}>
                  <TextField
                    error={Boolean(erroresForm.email)}
                    helperText={erroresForm.email}
                    label={cad("CORREO_E")}
                    name="email"
                    type="email"
                    placeholder={cad("CORREO_E")}
                    onChange={onCambiaCampo}
                    onBlur={onBlurEmail}
                    autoComplete="off"
                    value={estadoForm.email}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <Grid item xs={12}>
        <Divider sx={{ mt: 3, mb: 2 }} />
      </Grid>

      <DialogActions>
        <Button onClick={onClose} color="error">
          {cad("CANCELAR")}
        </Button>
        <Button
          disabled={!todosDocumentosAportados}
          onClick={() => guardarDatos()}
          color="primary"
          autoFocus
        >
          {cad("ENVIAR")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return esMovil ? mobileView() : desktopView();
};
