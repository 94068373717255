import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IdiomaContext } from "../../context/IdiomaContext";

export const IdiomaSelector = ({
  idiomas,
  deshabilitado = false,
  idiomaSeleccionado = "es",
  onSeleccionCambia,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(idiomaSeleccionado);
  const { cad } = useContext(IdiomaContext);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, index) => {
    if (index === selectedIndex) return;
    setSelectedIndex(index);
    setAnchorEl(null);
    onSeleccionCambia(index);
  };

  useEffect(() => {
    setSelectedIndex(idiomaSeleccionado);
  }, [idiomaSeleccionado]);

  if (!idiomas) return;

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        disabled={deshabilitado}
      >
        <Avatar
          alt={idiomas[selectedIndex]}
          src={"/img/banderas/" + selectedIndex + ".svg"}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Object.entries(idiomas).map(([key, value]) => (
          <MenuItem
            key={key}
            value={key}
            onClick={(event) => handleMenuItemClick(event, key)}
          >
            <ListItemIcon sx={{ mr: 1 }}>
              <Avatar alt={value} src={"/img/banderas/" + key + ".svg"} />
            </ListItemIcon>
            <ListItemText>{value}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
