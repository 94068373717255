import React, { useContext, useEffect, useState } from "react";
import { loadCaptchaEnginge, LoadCanvasTemplate } from "react-simple-captcha";
import { Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import { IdiomaContext } from "../../../context/IdiomaContext";

const CustomCaptcha = ({
  reloadText,
  reloadColor,
  captchaLength = 7,
  erroresForm = {},
  deshabilitado = false,
  onGuardaCambio,
  onBlur,
  onCaptchaChange,
  setSubmitDisabled,
}) => {
  useEffect(() => {
    loadCaptchaEnginge(captchaLength);
  }, [captchaLength]);

  // Estado para el valor del captcha
  const [captchaValue, setCaptchaValue] = useState("");

  // Función para manejar el cambio en el valor del captcha
  const handleCaptchaChange = (event) => {
    const newValue = event.target.value;
    console.log("Nuevo valor del captcha:", newValue);
    setCaptchaValue(newValue);
    onCaptchaChange(newValue); // Pasamos el nuevo valor del captcha como argumento
  };

  const { cad } = useContext(IdiomaContext);

  const esMovil = useMediaQuery("(max-width:768px)");

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 0,
          width: "100%",
          pt: 1,
          pb: 1,
        }}
      >
        <Grid
          container

          justifyContent = {esMovil ? "center" : "flex-start"}
          alignItems="center"
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              sm: "column",
            },
            gap: {
              lg: "0.5em",
              xs: "1em"
            },
            alignItems: "center",
            marginTop: { sm: 0 },
          }}
        >
          <Grid item>
            <TextField
              error={Boolean(erroresForm.captcha)}
              helperText={erroresForm.captcha}
              disabled={deshabilitado}
              label={cad("TECLEE_EL_TEXTO")}
              name="captcha"
              id="user_captcha_input"
              type="text"
              placeholder="********"
              onChange={handleCaptchaChange}
              onBlur={onBlur}
              value={captchaValue}
              sx={{
                marginTop: { sm: 0 },
              }}
            />
          </Grid>
          <Grid item>
            <LoadCanvasTemplate
              reloadText={reloadText}
              reloadColor={reloadColor}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomCaptcha;
