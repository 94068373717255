import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AutProvider } from "./aut/context/AutProvider";
import { IdiomaProvider } from "./context";
import { AppProvider } from "./context/AppProvider";
import { AppRouter } from "./router/AppRouter";
import { AppTheme } from "./theme/AppTheme";
import PublicTokenProvider from "./context/PublicTokenProvider";
import { FileProvider } from "./context/FileContext";
//import { LocalizationProvider } from '@date-io/type'

//import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const queryClient = new QueryClient();

export const SofiasAprApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PublicTokenProvider>
        <AppProvider>
          {/* Añadir el FileProvider aquí */}
          <FileProvider>
            <AutProvider>
              <IdiomaProvider>
                <AppTheme>
                  <AppRouter />
                </AppTheme>
              </IdiomaProvider>
            </AutProvider>
          </FileProvider>
        </AppProvider>
      </PublicTokenProvider>
    </QueryClientProvider>
  );
};
