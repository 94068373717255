import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { IdiomaContext } from "../../context/IdiomaContext";

export const TablaMotivoInfo = ({ nombreMotivo }) => {
  const { cad } = useContext(IdiomaContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="tabla motivo">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "primary.main" }}>
              {cad("MOTIVO")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0, pt: 1, pb: 1 } }}>
            <TableCell component="th" scope="row">
              {nombreMotivo || "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
