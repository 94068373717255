import { types } from "../types/types";

export const autReducer = (state = {},  action) => {
    switch (action.type) {
        case types.iniciar:
            return {
                ...state,
                sesionIniciada: true,
                usuario: action.payload
            };
        case types.cerrar:
            return {
                ...state,
                sesionIniciada: false,
                usuario : null
            };
        default:
            return state;
    }

}