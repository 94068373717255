import { AprLayout } from "../layout/AprLayout";
import { cfg } from "../../configuracion";
import { useContext, useEffect, useState } from "react";
import { AutContext } from "../../aut/context/AutContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import { TablaAreas } from "../../ui/components/TablaAreas";
import { TablaDocumentos } from "../../ui/components/TablaDocumentos";
import { TablaHorarios } from "../../ui/components/TablaHorarios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormulario } from "../../hooks/useFormulario";
import AddIcon from "@mui/icons-material/Add";
import AlertDialog from "../../ui/components/AlertDialog";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useQuery } from "@tanstack/react-query";
import { getMotivos } from "../../aut/services/getMotivos";
import { useNavigate } from "react-router-dom";
import { useFileContext } from "../../context/FileContext";

const solicitudContadorPermisos = async (idioma, idResidente, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const url = cfg.apiUrl + "/permisos/" + idResidente + "/cont";
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    if (resp.status == 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos: datos.datos };
    } else {
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

const formatearFecha = (fecha) => {
  if (!fecha) return;
  fecha = fecha.trim();
  if (!fecha) return;

  const splFechaHora = fecha.split(" ");
  let splFecha = splFechaHora[0].split("-");
  if (splFecha.length !== 3) {
    splFecha = splFechaHora.split("/");
  }

  const mes = splFecha[1].padStart(2, "0");
  let dia;
  let anio;

  if (splFecha[0].length === 4) {
    anio = splFecha[0].padStart(4, "0");
    dia = splFecha[2].padStart(2, "0");
  } else {
    anio = splFecha[2].padStart(4, "0");
    dia = splFecha[0].padStart(2, "0");
  }

  return `${dia}/${mes}/${anio} 00:00:00`;
};

const guardarPeticion = async (idioma, formData, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const resp = await fetch(cfg.apiUrl + "/peticion", {
      method: "POST",
      headers: {
        "Accept-Language": idioma,
        //'Content-Type': 'application/json',
        //'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
      body: formData,
    });
    if (resp.status === 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos };
    } else {
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

const borrarPeticion = async (idioma, id) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const resp = await fetch(cfg.apiUrl + "/peticion/" + id, {
      method: "DELETE",
      headers: {
        "Accept-Language": idioma,
        "Content-Type": "application/json",
        Authorization: cfg.autToken,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
      //body: JSON.stringify(json)
    });
    if (resp.status == 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos };
    } else {
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

const formatearFechaParaDatepicker = (fecha, finDelDia) => {
  const dia = fecha.getDate().toString().padStart(2, "0");
  const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const anio = fecha.getFullYear().toString().padStart(4, "0");
  const horas = finDelDia ? "23" : "00";
  const minutos = finDelDia ? "59" : "00";
  const segundos = finDelDia ? "59" : "00";
  return `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
};

export const CrearPeticionPage = () => {
  const { usuario } = useContext(AutContext);
  const { cad, idioma } = useContext(IdiomaContext);
  const esMovil = useMediaQuery("(max-width:768px)");
  const [contadorPermisos, setContadorPermisos] = useState(null);
  const [fechaMinima, setFechaMinima] = useState(null);
  const [erroresForm, setErroresForm] = useState({});
  const [mostrarEmail, setMostrarEmail] = useState(false);
  const [alert, setAlert] = useState({ titulo: "", texto: "" });
  const { estadoForm, onCambiaCampo, cambiarEstadoForm } = useFormulario({
    motivo: 0,
    matricula: "",
    tipo: "T",
    fechaDesde: formatearFechaParaDatepicker(new Date()),
    fechaHasta: formatearFechaParaDatepicker(new Date(), true),
    areas: [],
    ficheros: [],
    email: "",
  });

  const { data: dataMotivos } = useQuery({
    queryKey: [
      "getMotivos",
      {
        tipoResidente: usuario.tipo,
        tipoDuracion: estadoForm.tipo,
        idioma: idioma,
        token: usuario.authToken,
        idResidente: usuario.id,
      },
    ],
    queryFn: () =>
      getMotivos(usuario.tipo, estadoForm.tipo, idioma, usuario.authToken, usuario.id),
  });

  const validarFormulario = () => {
    const matriculaValida =
      estadoForm.matricula && estadoForm.matricula.length > 5;

    const motivoValido = estadoForm.motivo && estadoForm.motivo > 0;

    const fechasValidas = estadoForm.fechaDesde && estadoForm.fechaHasta;

    const areasValidas = estadoForm.areas && estadoForm.areas.length > 0;

    const documentosRequeridos =
      dataMotivos && Array.isArray(dataMotivos)
        ? dataMotivos.find((e) => e.id === estadoForm.motivo)?.documentos
        : null;

    const documentosAportados = estadoForm.ficheros.map((doc) => doc.id);
    const documentosValidos =
      !documentosRequeridos ||
      Object.keys(documentosRequeridos).every((docId) =>
        documentosAportados.includes(parseInt(docId))
      );

    const emailValido =
      !mostrarEmail ||
      (estadoForm.email &&
        estadoForm.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));

    return (
      matriculaValida &&
      motivoValido &&
      fechasValidas &&
      areasValidas &&
      documentosValidos &&
      emailValido
    );
  };

  const [idPeticionBorrar, setIdPeticionBorrar] = useState(0);
  const [abrirDialogoBorrar, setAbrirDialogoBorrar] = useState(false);
  const [esFormValido, setEsFormValido] = useState(false);

  const permitirCrearTemporales = () => {
    if (!contadorPermisos) return false;
    return contadorPermisos.cnt < contadorPermisos.max;
  };

  const cadenaContadorTemporales = () => {
    if (!contadorPermisos) return "";
    return " (" + contadorPermisos.cnt + "/" + contadorPermisos.max + ")";
  };

  useEffect(() => {
    setEsFormValido(validarFormulario());
  }, [estadoForm, mostrarEmail]);

  useEffect(() => {
    solicitudContadorPermisos("es", usuario.id)
      .then(({ datos }) => {
        setContadorPermisos(datos);
        setFechaMinima(datos.fechaPreviaPetTemp);
        const tipoPerm =
          !datos || !datos.cnt || !datos.max || datos.cnt >= datos.max
            ? "P"
            : "T";
        onCambiaCampo({ target: { name: "tipo", value: tipoPerm } });
      })
      .catch((error) => console.error(error));
  }, []);

  const fechaLimiteCalendario = () => {
    return fechaMinima || formatearFechaParaDatepicker(new Date());
  };

  const restarDias = (fecha, dias) => {
    const fechaResultado = new Date(fecha);
    fechaResultado.setDate(fechaResultado.getDate() - dias);
    return fechaResultado;
  };

  const onCambiaMatricula = ({ target }) => {
    const { name, value } = target;
    const nValue = value
      .toUpperCase()
      .replace(/[^@#*ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]/g, "");
    onCambiaCampo({ target: { name, value: nValue } });
  };

  const validarMotivo = (value) => {
    let cadErr = !value || value <= 0 ? cad("Debe seleccionar un motivo") : "";
    return cadErr;
  };

  const onBlurMotivo = ({ target }) => {
    const { name, value } = target;
    const msg = validarMotivo(value);
    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const validarMatricula = (value) => {
    let cadErr = !value || value.length <= 5 ? cad("MATRICULA_NO_VALIDA") : "";
    return cadErr;
  };

  const onBlurMatricula = ({ target }) => {
    const { name, value } = target;
    const msg = validarMatricula(value);

    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const validarFecha = (value) => {
    if (estadoForm.tipo === "P") return "";
    let cadErr = value ? validarOrdenFechas() : cad("Fecha no válida");
    return cadErr;
  };

  const validarOrdenFechas = () => {
    if (estadoForm.tipo === "P") return "";
    if (!estadoForm.fechaDesde || !estadoForm.fechaHasta)
      return "Hay que rellenar las dos fechas";
    const dDesde = new Date(estadoForm.fechaDesde);
    const dHasta = new Date(estadoForm.fechaHasta);
    return dHasta < dDesde
      ? "La fecha final tiene que ser igual o posterior a la fecha de inicio"
      : "";
  };

  const onBlurFecha = ({ target }) => {
    const { name, value } = target;
    const msg = validarFecha(value);
    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const validarAreas = () => {
    const areas = estadoForm.areas;
    let cadErr =
      !areas || areas.length <= 0 ? "Debe seleccionar al menos un área" : "";
    return cadErr;
  };

  const validarDocumentos = () => {
    const docReq =
      dataMotivos && Array.isArray(dataMotivos)
        ? dataMotivos.find((e) => e.id === estadoForm.motivo)?.documentos
        : null;

    // Permitir si no hay documentos requeridos (motivos que no requieren documentos)
    if (docReq === null) return "";

    const docSel = estadoForm.ficheros;
    if (!docSel || docSel.length <= 0)
      return "Debe aportar todos los documentos requeridos";

    const docReqIds = Object.entries(docReq).map(([k, v]) => Number(k));
    const docSelIds = estadoForm.ficheros.map((e) => e.id);
    const todosPresentes = docReqIds.every((doc) => docSelIds.includes(doc));

    return todosPresentes ? "" : "Debe aportar todos los documentos requeridos";
  };

  const validarEmail = (value) => {
    let cadErr = "";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value && !emailRegex.test(value)) {
      cadErr = "El formato del email no es válido";
    }

    return cadErr;
  };

  const onBlurEmail = ({ target }) => {
    const { name, value } = target;
    const msg = validarEmail(value);

    setErroresForm({
      ...erroresForm,
      [name]: msg,
    });
  };

  const { resizeFile } = useFileContext();

  const onEnviar = (event) => {
    event.preventDefault();

    console.log("Estado del formulario:", estadoForm);

    let motivo = estadoForm?.motivo;
    if (!motivo && motivos?.length && motivos?.length === 1) {
      motivo = motivos[0].id;
    }

    let txtError = "";
    txtError = txtError ? txtError : validarMatricula(estadoForm.matricula);
    txtError = txtError ? txtError : validarMotivo(motivo);
    txtError = txtError ? txtError : validarFecha(estadoForm.fechaDesde);
    txtError = txtError ? txtError : validarFecha(estadoForm.fechaHasta);
    txtError = txtError ? txtError : validarAreas();
    txtError = txtError ? txtError : validarDocumentos();

    // Validación del correoeInteresado solo si hay un valor proporcionado
    if (estadoForm.email) {
      txtError = txtError ? txtError : validarEmail(estadoForm.email);
    }

    if (txtError) {
      onBlurMotivo({ target: { name: "motivo", value: motivo } });
      onBlurMatricula({
        target: { name: "matricula", value: estadoForm.matricula },
      });
      onBlurFecha({
        target: { name: "fechaDesde", value: estadoForm.fechaDesde },
      });
      onBlurFecha({
        target: { name: "fechaHasta", value: estadoForm.fechaHasta },
      });
      if (estadoForm.email) {
        onBlurEmail({ target: { name: "email", value: estadoForm.email } });
      }
      abrirDialogo("Datos incorrectos", txtError);

      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("id", 0);
    formDataToSend.append("idMotivo", estadoForm.motivo);
    formDataToSend.append("idAprResidente", usuario.id);
    formDataToSend.append("tipo", estadoForm.tipo);
    formDataToSend.append("matricula", estadoForm.matricula);
    formDataToSend.append("fechaDesde", estadoForm.fechaDesde);
    formDataToSend.append("fechaHasta", estadoForm.fechaHasta);
    formDataToSend.append("correoeInteresado", estadoForm.email || "");
    formDataToSend.append("areas", estadoForm.areas.join(","));

    // Añadir 'ficheros' y 'archivos' solo si existen
    if (estadoForm.ficheros && estadoForm.ficheros.length > 0) {
      // Añadir los ficheros al FormData
      const ficheros = estadoForm.ficheros
        .map((e) => e.id + "_" + e.fichero?.name)
        .join(",");
      formDataToSend.append("ficheros", ficheros);

      // Añadir los archivos binarios al FormData con compresión de imágenes
      const maxTamMb = 1;
      const archivosEnviar = [];
      let huboErrorTam = false;
      estadoForm.ficheros.forEach((fich) => {
        if (huboErrorTam) return;
        const archTam = fich.fichero.size / (1024 * 1024); // Convertir a MB
        const archExt = fich.fichero.name.split(".").pop().toLowerCase();

        if (archExt === "jpg" || archExt === "jpeg" || archExt === "png") { 
          resizeFile(fich.fichero, 1920, 1080, (compressedFile) => {
            archivosEnviar.push(compressedFile);
            if (archivosEnviar.length === estadoForm.ficheros.length) {
              enviarPeticion(archivosEnviar, formDataToSend);
            }
          });
        } else {
          // Si el archivo supera 1MB, cancelar envío y mostrar error
          if (archTam > maxTamMb) {
            abrirDialogo("Error", "El archivo " + fich.fichero.name + " supera el tamaño máximo permitido de 1MB.");
            huboErrorTam = true;
          } else {
            archivosEnviar.push(fich.fichero);
            if (archivosEnviar.length === estadoForm.ficheros.length) {
              enviarPeticion(archivosEnviar, formDataToSend);
            }
          }
        }        
      });
    } else {
      // Enviar 'ficheros' como null si no hay archivos
      formDataToSend.append("ficheros", null);
      enviarPeticion(null, formDataToSend);
    }
  };

  function enviarPeticion(archivosEnviar, formDataToSend) {

    if (archivosEnviar) {
      archivosEnviar.forEach((archivo) => {
        formDataToSend.append("archivos", archivo);
      });
    }

    // Verificar los datos que se envían
    console.log(
      "Datos que se envían al crear una petición:",
      Object.fromEntries(formDataToSend.entries())
    );

    // Enviar los datos al backend después de procesar las imágenes
    guardarPeticion(idioma, formDataToSend, usuario.authToken).then(
      (resp) => {
        if (resp?.datos) {
          abrirDialogo("Petición guardada correctamente", "");
          navigate("/peticiones");
        } else if (resp?.error) {
          abrirDialogo(
            "Se ha producido un error guardando la petición",
            ""
          );
        }
      }
    );
  }

  const onAreaCambiaSeleccion = (idArea) => {
    if (estadoForm.areas.includes(idArea)) {
      onCambiaCampo({
        target: {
          name: "areas",
          value: estadoForm.areas.filter((e) => e !== idArea),
        },
      });
    } else {
      onCambiaCampo({
        target: { name: "areas", value: [...estadoForm.areas, idArea] },
      });
    }
  };

  const onCargarDocumento = (event, idDoc) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name) {
      let nombre = selectedFile.name.trim().toLowerCase();
      if (
        !nombre.endsWith(".jpg") &&
        !nombre.endsWith(".png") &&
        !nombre.endsWith(".pdf")
      ) {
        abrirDialogo(
          "Tipo de fichero incorrecto",
          "Tipos de documento admitidos: PDF, JPG, PNG"
        );
        return;
      }
    }
    let nFicheros = estadoForm.ficheros.filter((e) => e.id !== idDoc);
    nFicheros = [...nFicheros, { id: idDoc, fichero: selectedFile }];
    onCambiaCampo({ target: { name: "ficheros", value: nFicheros } });
  };

  const onBorrarDocumento = (idDoc) => {
    let nFicheros = estadoForm.ficheros.filter((e) => e.id !== idDoc);
    onCambiaCampo({ target: { name: "ficheros", value: nFicheros } });
  };

  const abrirDialogo = (titulo, texto) => {
    if (!titulo && !texto) return;
    setAlert({ titulo: titulo, texto: texto, onCerrar: onCerrarAlert });
  };

  const onCerrarAlert = () => {
    setAlert({});
  };

  const motivos =
    usuario && usuario.tipo && dataMotivos && Array.isArray(dataMotivos)
      ? dataMotivos
          .filter((mot) => mot.residenteTipos.includes(usuario.tipo))
          .sort((a, b) => {
            if (a.nombre < b.nombre) {
              return -1;
            }
            if (a.nombre > b.nombre) {
              return 1;
            }
            return 0;
          })
          .map((motivo) => ({
            id: motivo.id,
            nombre: motivo.nombre,
          }))
      : [];

  const handleEmailOptionChange = (event) => {
    setMostrarEmail(event.target.value === "si");
    if (event.target.value === "no") {
      onCambiaCampo({ target: { name: "email", value: "" } });
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <AprLayout>
        <Grid container item xs={10} sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h4" color="primary.main">
            {cad("SOLICITAR_PERMISO")}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={10}
          component="form"
          sx={{ mt: 0, ml: 0 }}
          direction={"column"}
          alignItems={"flex-start"}
          spacing={3}
          onSubmit={onEnviar}
        >
          <Grid
            item
            container
            direction={"column"}
            alignItems={"flex-start"}
            sx={{
              pr: 3,
              borderRadius: 4,
              borderColor: "secondary.main",
              borderWidth: 2,
              borderStyle: "solid",
              pb: 2,
            }}
          >
          <Grid
              item
              container
              alignItems={"flex-start"}
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    {cad("TIPO_PERMISO")}
                  </FormLabel>
                  <RadioGroup
                    sx={{ display: "flex", flexDirection: "row" }}
                    aria-label="tipo"
                    name="tipo"
                    value={estadoForm.tipo}
                    onChange={(e) => {
                      onCambiaCampo(e);
                      cambiarEstadoForm({ motivo: 0 });
                    }}
                  >
                    <FormControlLabel
                      value="T"
                      disabled={!permitirCrearTemporales()}
                      control={<Radio />}
                      label={`${cad("TEMPORAL")} ${cadenaContadorTemporales()}`}
                    />
                    <FormControlLabel
                      value="P"
                      control={<Radio />}
                      label={cad("PERMANENTE")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            
            <Grid item container alignItems={"flex-start"} spacing={2}>
              {/* Agrupando Matrícula y Motivo */}
              <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                <TextField
                  error={Boolean(erroresForm.matricula)}
                  helperText={erroresForm.matricula}
                  label={cad("MATRICULA")}
                  name="matricula"
                  type="text"
                  placeholder={cad("MATRICULA")}
                  onChange={onCambiaMatricula}
                  onBlur={onBlurMatricula}
                  autoComplete={"off"}
                  value={estadoForm.matricula}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                <FormControl fullWidth error={Boolean(erroresForm.motivo)}>
                  <InputLabel id="demo-simple-select-label">
                    {cad("MOTIVO")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={estadoForm.motivo || 0}
                    name="motivo"
                    label={cad("MOTIVO")}
                    disabled={!dataMotivos?.length || dataMotivos?.length === 0}
                    onChange={(e) => {
                      onCambiaCampo(e);
                      onBlurMotivo(e);
                    }}
                    onBlur={onBlurMotivo}
                  >
                    {(Boolean(!dataMotivos?.length) ||
                      dataMotivos.length > 0) && (
                      <MenuItem key="0" value="0">
                        <em>({cad("SELECCIONE_UNA_OPCION")})</em>
                      </MenuItem>
                    )}
                    {dataMotivos?.length
                      ? dataMotivos.map((motivo) => (
                          <MenuItem key={motivo.id} value={motivo.id}>
                            {motivo.nombre}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText>{erroresForm.motivo}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container alignItems={"flex-start"} spacing={2}>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    {cad("AÑADIR_CORREO")} - ({cad("CAMPO_OPCIONAL")})
                  </FormLabel>
                  <RadioGroup
                    sx={{ display: "flex", flexDirection: "row" }}
                    aria-label="añadirCorreo"
                    name="añadirCorreo"
                    value={mostrarEmail ? "si" : "no"}
                    onChange={handleEmailOptionChange}
                  >
                    <FormControlLabel
                      value="si"
                      control={<Radio />}
                      label={cad("SI")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={cad("NO")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            {mostrarEmail && (
              <Grid item container alignItems={"flex-start"} spacing={2}>
                <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(erroresForm.email)}
                    helperText={erroresForm.email}
                    label={cad("CORREO_E")}
                    name="email"
                    type="email"
                    placeholder={cad("CORREO_E")}
                    onChange={onCambiaCampo}
                    onBlur={onBlurEmail}
                    autoComplete="off"
                    value={estadoForm.email}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            
            {estadoForm.tipo === "T" && (
              <Grid
                item
                container
                alignItems={"flex-start"}
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="fechaDesde"
                    label={cad("DESDE")}
                    type="date"
                    onChange={(e) => {
                      const fechaFormateada = formatearFechaParaDatepicker(
                        new Date(e.target.value),
                        false
                      );
                      onCambiaCampo({
                        target: { name: "fechaDesde", value: fechaFormateada },
                      });
                    }}
                    onBlur={onBlurFecha}
                    value={estadoForm.fechaDesde.split(" ")[0]}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: fechaLimiteCalendario() }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="fechaHasta"
                    label={cad("HASTA")}
                    type="date"
                    onChange={(e) => {
                      const fechaFormateada = formatearFechaParaDatepicker(
                        new Date(e.target.value),
                        true
                      );
                      onCambiaCampo({
                        target: { name: "fechaHasta", value: fechaFormateada },
                      });
                    }}
                    onBlur={onBlurFecha}
                    value={estadoForm.fechaHasta.split(" ")[0]}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: fechaLimiteCalendario() }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              item
              alignItems={"flex-start"}
              spacing={2}
              sx={{ mt: 2 }}
            >
              {/* Tabla de Áreas */}
              {Boolean(estadoForm.motivo) && estadoForm.motivo > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={
                    dataMotivos &&
                    dataMotivos.find((e) => e.id === estadoForm.motivo)
                      ?.documentos
                      ? 4
                      : 6
                  }
                >
                  <TablaAreas
                    areas={
                      dataMotivos && Array.isArray(dataMotivos)
                        ? dataMotivos.find((e) => e.id === estadoForm.motivo)
                            ?.areas
                        : null
                    }
                    areasSeleccionadas={estadoForm.areas}
                    onCambiaSeleccion={onAreaCambiaSeleccion}
                    seleccionable={true}
                  />
                </Grid>
              )}

              {/* Tabla de Documentos */}
              {Boolean(estadoForm.motivo) &&
                estadoForm.motivo > 0 &&
                dataMotivos &&
                Array.isArray(dataMotivos) &&
                dataMotivos.find((e) => e.id === estadoForm.motivo)
                  ?.documentos && (
                  <Grid item xs={12} sm={4}>
                    <TablaDocumentos
                      documentos={
                        dataMotivos.find((e) => e.id === estadoForm.motivo)
                          ?.documentos
                      }
                      ficheros={
                        estadoForm.ficheros &&
                        Array.isArray(estadoForm.ficheros)
                          ? estadoForm.ficheros
                          : null
                      }
                      onBorrar={onBorrarDocumento}
                      onCargar={onCargarDocumento}
                    />
                  </Grid>
                )}

              {/* Tabla de Horarios */}
              {Boolean(estadoForm.motivo) && estadoForm.motivo > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={
                    dataMotivos &&
                    dataMotivos.find((e) => e.id === estadoForm.motivo)
                      ?.documentos
                      ? 4
                      : 6
                  }
                >
                  <TablaHorarios
                    horarios={
                      dataMotivos && Array.isArray(dataMotivos)
                        ? dataMotivos.find((e) => e.id === estadoForm.motivo)
                            ?.horarios
                        : null
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={1}
              sx={{ mb: 2, mt: esMovil ? 2 : 4 }}
              justifyContent={esMovil ? "center" : "flex-end"}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: esMovil ? "column" : "row",
                  gap: 2,
                  width: esMovil ? "100%" : "auto",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<KeyboardReturnIcon />}
                  sx={{
                    backgroundColor: "error.main",
                    color: "white.main",
                    opacity: 0.6,
                    transition: "opacity 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: "error.hover",
                      opacity: 1,
                    },
                  }}
                  onClick={() => navigate("/peticiones")}
                >
                  {cad("VOLVER")}
                </Button>

                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  type={"submit"}
                  sx={{
                    backgroundColor: "button.primary.main",
                    color: "white.main",
                    opacity: !esFormValido ? 0.6 : 1,
                    transition: "opacity 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: "button.primary.hover",
                      opacity: 1,
                    },
                  }}
                  disabled={!esFormValido}
                >
                  {cad("AÑADIR")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AprLayout>
      <AlertDialog
        titulo={alert.titulo}
        texto={alert.texto}
        onCerrar={onCerrarAlert}
      />
    </>
  );
};
