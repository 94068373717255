import { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { IdiomaContext } from "../../context/IdiomaContext";

export const TablaHorarios = ({ horarios }) => {
  const { cad } = useContext(IdiomaContext);
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_LUNES")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_MARTES")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_MIERCOLES")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_JUEVES")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_VIERNES")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_SABADO")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main", p: 0 }}>
              {cad("SIGLA_DOMINGO")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main" }}>
              {cad("DESDE")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main" }}>
              {cad("HASTA")}
            </TableCell>
            <TableCell align="center" sx={{ color: "primary.main" }}>
              {cad("FESTIVOS")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {horarios &&
            horarios.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.l ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.m ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.x ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.j ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.v ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.s ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.d ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {row.desde}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {row.hasta}
                </TableCell>
                <TableCell align="center" sx={{ p: 0 }}>
                  {row.festivos ? (
                    <Tooltip title={cad("PERMITIDO")}>
                      <CheckIcon sx={{ color: theme.palette.success.main }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={cad("PROHIBIDO")}>
                      <ClearIcon sx={{ color: theme.palette.error.main }} />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
