import { cfg } from "../../configuracion";

export const putCorreoConfirmacion = async (idioma, json, token) => {
    let respuesta = {
        datos: null,
        error: null
    };

    try {
        const url = `${cfg.apiUrl}/residente/${json.nif}/${json.tipo}/reenvioregistro`;

        console.log("URL:", url);
        console.log("Datos antes de enviar:", {
            idioma,
            nif: json.nif,
            tipo: json.tipo,
            token
        });

        const resp = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept-Language': idioma,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            }
        });

        if (resp.ok) {
            const datos = await resp.json();
            console.log("Datos recibidos del servidor:", datos); 
            respuesta.datos = datos;
        } else {
            let errorMessage = `Error: ${resp.status}`;
            try {
                const errorData = await resp.json();
                if (errorData.mensaje) {
                    errorMessage = errorData.mensaje;
                }
            } catch (e) {
                console.error("Error al parsear la respuesta de error", e);
            }
            respuesta.error = errorMessage;
            console.log("Error en la respuesta del servidor:", errorMessage);
        }
    } catch (error) {
        console.error("Error en el envío de datos:", error);
        respuesta.error = `Error en el envío de datos: ${error.message}`;
    }
    
    return respuesta;
};
