import { cfg } from "../../configuracion";

export const getIdiomasPorLocale = async (idioma, token) => {
    try {
        const url = cfg.apiUrl + '/idiomas?locale=' + encodeURIComponent(idioma);
        const resp = await fetch(url, {
            method: 'GET',
            headers: { 
                'Accept-Language': idioma,
                'Authorization': `Bearer ${token}`,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            }
        });
        if (resp.status == 200) {
            const data = await resp.json();
            return data.datos.idiomas;
        } 
        throw new Error(resp.statusText);
    } catch (error) {
        throw error;
    }
}