import { cfg } from "../../configuracion";

const updateIdiomaResidente = async (
  idUsuario,
  idiomaResidente,
  idioma,
  token
) => {
  // Validación inicial de los parámetros
  if (!idUsuario || !idiomaResidente || !idioma) return null;

  try {
    const url = `${cfg.apiUrl}/residente/${idUsuario}/idioma?idiomaResidente=${idiomaResidente}`;

    /* console.log(`Enviando solicitud a: ${url} con idiomaResidente: ${idiomaResidente} y Accept-Language: ${idioma}`); */

    const resp = await fetch(url, {
      method: "PUT",
      headers: {
        "accept-language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });

    if (!resp.ok) {
      throw new Error(`Error ${resp.status}: ${resp.statusText}`);
    }

    const data = await resp.json();

    if (!data.ok || !data.datos) {
      throw new Error("Error en la respuesta del servidor");
    }

    return {
      idiomaActualizado: data.datos, // Idioma al que se actualizó
      idiomaRespuesta: data.idioma, // Idioma de la respuesta
    };
  } catch (error) {
    console.error("Error al tratar de actualizar el idioma:", error);
    throw error;
  }
};

export default updateIdiomaResidente;
