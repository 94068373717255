import { useContext, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IdiomaContext } from "../../context/IdiomaContext";

const centerModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const TablaRespuestasInfo = ({ respuestas }) => {
  const { cad } = useContext(IdiomaContext);
  const esMovil = useMediaQuery("(max-width:768px)");

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const desktopView = () => (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "primary.main" }} align="center">
              {cad("ESTADO")}
            </TableCell>
            <TableCell sx={{ color: "primary.main" }} align="center">
              {cad("FECHA_ENVIO")}
            </TableCell>
            <TableCell sx={{ color: "primary.main" }} align="center">
              {cad("FECHA_RESPUESTA")}
            </TableCell>
            <TableCell sx={{ color: "primary.main" }} align="center">
              {cad("TIPO_RESPUESTA")}
            </TableCell>
            <TableCell sx={{ color: "primary.main" }}>
              {cad("DETALLE_RESPUESTA")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {respuestas &&
            respuestas.map((respuesta) => (
              <TableRow key={respuesta.id}>
                <TableCell align="center">
                  {respuesta.estado === "1"
                    ? cad("ENVIADA")
                    : cad("DESCONOCIDO")}
                </TableCell>
                <TableCell align="center">{respuesta.fechaEnvio}</TableCell>
                <TableCell align="center">{respuesta.fecha}</TableCell>
                <TableCell align="center">
                  {respuesta.tipoRespuesta >= 1 && respuesta.tipoRespuesta <= 5
                    ? cad("PET_RPTA_TIPO_" + respuesta.tipoRespuesta)
                    : cad("PET_RPTA_TIPO_0")}
                </TableCell>
                <TableCell>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: respuesta.textoRespuesta,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const mobileView = () => (
    <Box>
      {respuestas && respuestas.length > 1
        ? respuestas.map((respuesta, index) => (
            <Accordion key={respuesta.id} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" color="primary.main">
                  {cad("RESPUESTA")} {index + 1}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                  {cad("RESPUESTA")}:
                </Typography>

                <Typography variant="body2">
                  {cad("ESTADO")}:{" "}
                  {respuesta.estado === "1"
                    ? cad("ENVIADA")
                    : cad("DESCONOCIDO")}
                </Typography>
                <Typography variant="body2">
                  {cad("FECHA_ENVIO")}: {respuesta.fechaEnvio}
                </Typography>
                <Typography variant="body2">
                  {cad("FECHA_RESPUESTA")}: {respuesta.fecha}
                </Typography>
                <Typography variant="body2">
                  {cad("TIPO_RESPUESTA")}:{" "}
                  {respuesta.tipoRespuesta >= 1 && respuesta.tipoRespuesta <= 5
                    ? cad("PET_RPTA_TIPO_" + respuesta.tipoRespuesta)
                    : cad("PET_RPTA_TIPO_0")}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {cad("DETALLE_RESPUESTA")}:
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: respuesta.textoRespuesta,
                    }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        : respuestas &&
          respuestas.map((respuesta) => (
            <Card key={respuesta.id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                  {cad("RESPUESTA")}:
                </Typography>

                <Typography variant="body2">
                  {cad("ESTADO")}:{" "}
                  {respuesta.estado === "1"
                    ? cad("ENVIADA")
                    : cad("DESCONOCIDO")}
                </Typography>
                <Typography variant="body2">
                  {cad("FECHA_ENVIO")}: {respuesta.fechaEnvio}
                </Typography>
                <Typography variant="body2">
                  {cad("FECHA_RESPUESTA")}: {respuesta.fecha}
                </Typography>
                <Typography variant="body2">
                  {cad("TIPO_RESPUESTA")}:{" "}
                  {respuesta.tipoRespuesta >= 1 && respuesta.tipoRespuesta <= 5
                    ? cad("PET_RPTA_TIPO_" + respuesta.tipoRespuesta)
                    : cad("PET_RPTA_TIPO_0")}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {cad("DETALLE_RESPUESTA")}:
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: respuesta.textoRespuesta,
                    }}
                  />
                </Typography>
              </CardContent>
            </Card>
          ))}
    </Box>
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "primary.main" }}>
                {cad("RESPUESTAS")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ pt: 0.5, pb: 0.5 }}>
                {respuestas && respuestas.length > 0 ? (
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{
                      fontSize: "0.875rem",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={handleOpenModal}
                  >
                    {cad("VER_RESPUESTAS")}
                  </Typography>
                ) : (
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    sx={{ fontSize: "0.875rem" }}
                  >
                    {cad("-")}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        style={centerModalStyle}
      >
        <Box
          sx={{
            ...centerModalStyle,
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            width: esMovil ? "90%" : "75%",
            bgcolor: "background.paper",
            p: 4,
            backdropFilter: "0, 0, 0, 1",
          }}
        >
          <Box sx={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h5" sx={{ mb: 3 }} color="primary.main">
              {cad("RESPUESTAS")}
            </Typography>
          </Box>
          {esMovil ? mobileView() : desktopView()}
        </Box>
      </Modal>
    </>
  );
};

export default TablaRespuestasInfo;
