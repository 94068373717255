import React, { useContext, useEffect, useState } from "react";
import { AprLayout } from "../layout/AprLayout";
import { cfg } from "../../configuracion";
import { AutContext } from "../../aut/context/AutContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  IconButton,
  CardActions,
  Fab,
  useTheme,
  alpha,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import TablaRespuestasInfo from "../../ui/components/TablaRespuestasInfo";
import { getRespuestas } from "../../aut/services/getRespuestas";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../ui/components/utils/Spinner";
import { TablaMotivoInfo } from "../../ui/components/TablaMotivoInfo";
import { TablaAreasInfo } from "../../ui/components/TablaAreasInfo";
import { TablaDocumentosInfo } from "../../ui/components/TablaDocumentosInfo";

const solicitudPeticiones = async (idioma, idResidente, token) => {
  let respuesta = {
    datos: null,
    mensaje: null,
  };

  if (!idioma || !idResidente) {
    return respuesta;
  }

  try {
    const url = `${cfg.apiUrl}/peticiones/${idResidente}`;
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    if (resp.ok) {
      const datos = await resp.json();
      respuesta.datos = datos.datos || datos;
    } else {
      const errorMsg = await resp.text();
      respuesta.mensaje = `Error: ${resp.status} - ${errorMsg}`;
    }
  } catch (error) {
    respuesta.mensaje = "Error en la solicitud";
  }

  return respuesta;
};

const borrarPeticion = async (idioma, id, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const resp = await fetch(cfg.apiUrl + "/peticion/" + id, {
      method: "DELETE",
      headers: {
        "Accept-Language": idioma,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    if (resp.status === 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos };
    } else {
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};

export const PeticionesPage = () => {
  const { usuario } = useContext(AutContext);
  const { cad, idioma } = useContext(IdiomaContext);
  const [peticiones, setPeticiones] = useState([]);
  const [refrescarPeticiones, setRefrescarPeticiones] = useState(0);
  const [idPeticionCambiar, setIdPeticionCambiar] = useState(0);
  const [abrirDialogoBorrar, setAbrirDialogoBorrar] = useState(false);
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const esMovil = useMediaQuery("(max-width:768px)");
  const theme = useTheme(); // Obtener el tema actual

  useEffect(() => {
    if (usuario && usuario.id) {
      setFilaSeleccionada(null);
      setLoading(true);

      solicitudPeticiones(idioma, usuario.id, usuario.authToken)
        .then((resultado) => {
          if (resultado && resultado.datos) {
            const peticionesOrdenadas = resultado.datos.sort(
              (a, b) => b.id - a.id
            );
            setPeticiones(peticionesOrdenadas);
            console.log(peticionesOrdenadas);
            if (peticionesOrdenadas.length > 0 && !esMovil) {
              setFilaSeleccionada(peticionesOrdenadas[0]);
            }
          } else {
            console.error(
              "Error al obtener las peticiones:",
              resultado.mensaje
            );
          }
        })
        .catch((error) =>
          console.error("Error en la solicitud de peticiones:", error)
        )
        .finally(() => setLoading(false));
    } else {
      console.error("usuario.id no está definido");
    }

    const intervalId = setInterval(() => {
      setRefrescarPeticiones((prev) => prev + 1);
    }, 3600000);

    return () => clearInterval(intervalId);
  }, [usuario?.id, refrescarPeticiones, idioma]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilaSeleccionada(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilaSeleccionada(null);
  };

  const eliminarPeticionDelEstado = (id) => {
    setPeticiones(peticiones.filter((pet) => pet.id !== id));
  };

  const onCerrarDialogoBorrar = (aceptar) => {
    if (aceptar) {
      borrarPeticion(idioma, idPeticionCambiar, usuario.authToken)
        .then(() => {
          eliminarPeticionDelEstado(idPeticionCambiar);
          setIdPeticionCambiar(0);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setAbrirDialogoBorrar(false);
        });
    } else {
      setIdPeticionCambiar(0);
      setAbrirDialogoBorrar(false);
    }
  };

  const onBorrarClick = (id) => {
    setIdPeticionCambiar(id);
    setAbrirDialogoBorrar(true);
  };

  const { data: seleccionRespuestas } = useQuery({
    queryKey: [
      "getRespuestas",
      {
        idPeticion: filaSeleccionada?.id,
        idioma: idioma,
        token: usuario.authToken,
      },
    ],
    queryFn: () =>
      getRespuestas(filaSeleccionada?.id, idioma, usuario.authToken),
    enabled: !!filaSeleccionada?.id,
  });

  const desktopView = () => (
    <AprLayout>
      <Grid
        container
        item
        xs={10}
        sx={{
          mt: 3,
          justifyContent: "space-between",
          alignItems: "center",
          visibility: loading ? "hidden" : "visible",
        }}
      >
        <Grid item>
          <Typography align="left" variant="h4" color="primary.main">
            {cad("PETICIONES")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={() => setRefrescarPeticiones((prev) => prev + 1)}
            sx={{
              backgroundColor: "button.secondary.main",
              color: "white.main",
              mr: 2,
              opacity: 0.6,
              transition: "opacity 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "button.secondary.hover",
                opacity: 1,
              },
            }}
          >
            {cad("ACTUALIZAR")}
          </Button>
          <Button
            component={Link}
            to="/crearpeticion"
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: "button.primary.main",
              color: "white.main",
              opacity: 0.6,
              transition: "opacity 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "button.primary.hover",
                opacity: 1,
              },
            }}
          >
            {cad("AÑADIR")}
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Grid
          container
          item
          xs={10}
          sx={{
            mt: 3,
            pt: 2,
            pl: 4,
            pb: 4,
            pr: 4,
            borderRadius: 2,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "secondary.main",
          }}
        >
          <Grid
            container
            item
            xs={8}
            sx={{
              mt: 2,
              height: "fit-content",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("Nº_PETICION")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("TIPO_PETICION")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("MATRICULA")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("FECHA_PETICION")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("DESDE")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("HASTA")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("ESTADO")}
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }} align="center">
                      {cad("TIPO_PERMISO")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {peticiones
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        onClick={() => {
                          setFilaSeleccionada(row);
                          /* console.log("%Fila seleccionada:", row); */
                        }}
                        selected={filaSeleccionada === row}
                        hover
                        sx={{
                          backgroundColor:
                            filaSeleccionada === row
                              ? alpha(theme.palette.secondary.main, 0.3)
                              : "inherit",
                          "&.Mui-selected": {
                            backgroundColor: alpha(
                              theme.palette.secondary.main,
                              0.1
                            ),
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: alpha(
                              theme.palette.secondary.main,
                              0.2
                            ),
                          },
                        }}
                      >
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell align="center">
                          {row.tipoPeticion === "AV" && (
                            <Tooltip title={cad("ALTA_VEHICULO")}>
                              <AddCircleOutlineIcon />
                            </Tooltip>
                          )}
                          {row.tipoPeticion === "RV" && (
                            <Tooltip title={cad("RENOVACION_VEHICULO")}>
                              <RestartAltIcon />
                            </Tooltip>
                          )}
                          {row.tipoPeticion === "BV" && (
                            <Tooltip title={cad("BAJA_VEHICULO")}>
                              <RemoveCircleOutlineIcon />
                            </Tooltip>
                          )}
                          {row.tipoPeticion === "AR" && (
                            <Tooltip title={cad("ALTA_RESIDENTE")}>
                              <HomeOutlinedIcon />
                            </Tooltip>
                          )}
                          {row.tipoPeticion === "RR" && (
                            <Tooltip title={cad("RENOVACION_RESIDENTE")}>
                              <OtherHousesOutlinedIcon />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.matricula || "-"}
                        </TableCell>
                        <TableCell align="center">{row.fecha || "-"}</TableCell>
                        <TableCell align="center">
                          {(row.fechaDesde && row.fechaDesde.split(" ")[0]) ||
                            "-"}
                        </TableCell>
                        <TableCell align="center">
                          {(row.fechaHasta && row.fechaHasta.split(" ")[0]) ||
                            "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.estado === "0" && (
                            <Tooltip title={cad("PENDIENTE")}>
                              <HourglassEmptyIcon />
                            </Tooltip>
                          )}
                          {row.estado === "1" && (
                            <Tooltip title={cad("ACEPTADA")}>
                              <CheckIcon />
                            </Tooltip>
                          )}
                          {row.estado === "2" && (
                            <Tooltip title={cad("RECHAZADA")}>
                              <ClearIcon />
                            </Tooltip>
                          )}
                          {row.estado === "3" && (
                            <Tooltip title={cad("BORRADA")}>
                              <RemoveCircleOutlineIcon />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.tipoPermiso === "T" && (
                            <Tooltip title={cad("TEMPORAL")}>
                              <CalendarMonthOutlinedIcon />
                            </Tooltip>
                          )}
                          {row.tipoPermiso === "P" && (
                            <Tooltip title={cad("PERMANENTE")}>
                              <KeyOutlined />
                            </Tooltip>
                          )}
                          {!row.tipoPermiso && "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[8]}
                component="div"
                count={peticiones.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} / ${count}`
                }
              />
            </TableContainer>
          </Grid>

          {filaSeleccionada && !loading && (
            <Grid container item xs={4} sx={{ mt: 2, pl: 5, gap: "1em" }}>
              {filaSeleccionada.tipoPeticion === "BV" ? (
                <Grid item xs={12}>
                  <TablaRespuestasInfo respuestas={seleccionRespuestas || []} />
                </Grid>
              ) : (
                <>
                  {filaSeleccionada.tipoPeticion !== "AR"
                    && filaSeleccionada.tipoPeticion !== "RR" && (<>
                  <Grid item xs={12}>
                    <TablaMotivoInfo
                      nombreMotivo={filaSeleccionada?.nombreMotivo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TablaAreasInfo
                      areas={filaSeleccionada.areas}
                      mostrarEstado={true}
                    />
                  </Grid>
                  </>)}
                  <Grid item xs={12}>
                    <TablaDocumentosInfo
                      documentos={filaSeleccionada.documentos}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TablaRespuestasInfo
                      respuestas={seleccionRespuestas || []}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </AprLayout>
  );

  const mobileView = () => (
    <AprLayout>
      <Grid
        container
        item
        xs={12}
        sx={{ my: 2, ml: 1, visibility: loading ? "hidden" : "visible" }}
      >
        <Grid item xs={9}>
          <Typography align="left" variant="h4" color="primary.main">
            {cad("PETICIONES")}
          </Typography>
        </Grid>
      </Grid>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Grid container item xs={12} sx={{ mt: 2, gap: 1 }}>
            {peticiones.map((row) => {
              return (
                <Accordion key={row.id} sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${row.id}-content`}
                    id={`panel-${row.id}-header`}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "secondary.main",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {`${cad("Nº_PETICION")}: ${row.id} - `}
                      {row.tipoPeticion === "AV" && (
                        <AddCircleOutlineIcon
                          color="secondary.main"
                          sx={{ ml: 1 }}
                        />
                      )}
                      {row.tipoPeticion === "RV" && (
                        <RestartAltIcon color="secondary.main" sx={{ ml: 1 }} />
                      )}
                      {row.tipoPeticion === "BV" && (
                        <RemoveCircleOutlineIcon
                          color="secondary.main"
                          sx={{ ml: 1 }}
                        />
                      )}
                      {row.tipoPeticion === "AR" && (
                        <HomeOutlinedIcon color="primary" sx={{ ml: 1 }} />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{ mr: 1, fontWeight: "bold" }}
                      >
                        {`${cad("TIPO_PETICION")}:`}
                      </Typography>
                      {row.tipoPeticion === "AV" && (
                        <Tooltip title={cad("ALTA_VEHICULO")}>
                          <span>
                            <Typography variant="body1">
                              {cad("ALTA_VEHICULO")}
                            </Typography>
                          </span>
                        </Tooltip>
                      )}
                      {row.tipoPeticion === "RV" && (
                        <Tooltip title={cad("RENOVACION_VEHICULO")}>
                          <span>
                            <Typography variant="body1">
                              {cad("RENOVACION_VEHICULO")}
                            </Typography>
                          </span>
                        </Tooltip>
                      )}
                      {row.tipoPeticion === "BV" && (
                        <Tooltip title={cad("BAJA_VEHICULO")}>
                          <span>
                            <Typography variant="body1">
                              {cad("BAJA_VEHICULO")}
                            </Typography>
                          </span>
                        </Tooltip>
                      )}
                      {row.tipoPeticion === "AR" && (
                        <Tooltip title={cad("ALTA_RESIDENTE")}>
                          <span>
                            <Typography variant="body1">
                              {cad("ALTA_RESIDENTE")}
                            </Typography>
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {cad("MATRICULA")}:
                      </span>{" "}
                      {row.matricula || "-"}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {cad("FECHA_PETICION")}:
                      </span>{" "}
                      {row.fecha || "-"}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {cad("DESDE")}:
                      </span>{" "}
                      {(row.fechaDesde && row.fechaDesde.split(" ")[0]) || "-"}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {cad("HASTA")}:
                      </span>{" "}
                      {(row.fechaHasta && row.fechaHasta.split(" ")[0]) || "-"}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {`${cad("ESTADO")}:`}
                      </Typography>
                      {row.estado === "0" && (
                        <Tooltip title={cad("PENDIENTE")}>
                          <HourglassEmptyIcon sx={{ ml: 1 }} />
                        </Tooltip>
                      )}
                      {row.estado === "1" && (
                        <Tooltip title={cad("ACEPTADA")}>
                          <CheckIcon sx={{ ml: 1, color: "success.main" }} />
                        </Tooltip>
                      )}
                      {row.estado === "2" && (
                        <Tooltip title={cad("RECHAZADA")}>
                          <ClearIcon sx={{ ml: 1, color: "error.main" }} />
                        </Tooltip>
                      )}
                      {row.estado === "3" && (
                        <Tooltip title={cad("BORRADA")}>
                          <RemoveCircleOutlineIcon sx={{ ml: 1 }} />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{ mr: 1, fontWeight: "bold" }}
                      >
                        {`${cad("TIPO_PERMISO")}:`}
                      </Typography>
                      {row.tipoPermiso === "T" && cad("TEMPORAL")}
                      {row.tipoPermiso === "P" && cad("PERMANENTE")}
                    </Box>
                    {row.tipoPeticion !== "AR" && (
                      <CardActions>
                        <Tooltip title={cad("INFORMACION")}>
                          <IconButton
                            color="inherit"
                            onClick={() => setFilaSeleccionada(row)}
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </>
      )}

      <Dialog
        open={Boolean(filaSeleccionada)}
        onClose={() => setFilaSeleccionada(null)}
        aria-labelledby="informacion-dialog-title"
        aria-describedby="informacion-dialog-description"
        fullWidth
      >
        <DialogTitle id="informacion-dialog-title">
          {`${cad("PETICION")} ${filaSeleccionada?.id}`}
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          {filaSeleccionada?.tipoPeticion === "BV" ? (
            <TablaRespuestasInfo respuestas={seleccionRespuestas || []} />
          ) : (
            <>
              {filaSeleccionada?.tipoPeticion !== "AR" 
                && filaSeleccionada?.tipoPeticion !== "RR" && (
                <TablaMotivoInfo
                  nombreMotivo={filaSeleccionada?.nombreMotivo}
                />
              )}
              {filaSeleccionada?.tipoPeticion !== "AR" 
                && filaSeleccionada?.tipoPeticion !== "RR" && (
                <TablaAreasInfo
                  areas={filaSeleccionada?.areas}
                  mostrarEstado={true}
                />
              )}
              <TablaDocumentosInfo documentos={filaSeleccionada?.documentos} />
              <TablaRespuestasInfo respuestas={seleccionRespuestas || []} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFilaSeleccionada(null)}>
            {cad("CERRAR")}
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          position: "fixed",
          top: 120,
          right: 16,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          zIndex: 10,
          visibility: loading ? "hidden" : "visible",
        }}
      >
        <Fab
          onClick={() => setRefrescarPeticiones((prev) => prev + 1)}
          sx={{
            color: "white.main",
            backgroundColor: "button.secondary.main",
            opacity: 0.6,
            "&:hover": {
              backgroundColor: "button.secondary.hover",
              opacity: 1,
            },
            "&:active": {
              opacity: 1,
            },
          }}
        >
          <RefreshIcon />
        </Fab>
        <Fab
          component={Link}
          to="/crearpeticion"
          sx={{
            backgroundColor: "button.primary.main",
            color: "white.main",
            opacity: 0.6,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "button.primary.hover",
              opacity: 1,
            },
            "&:active": {
              opacity: 1,
            },
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
    </AprLayout>
  );

  return esMovil ? mobileView() : desktopView();
};
