import { cfg } from "../../configuracion";

const getDocumentosRechazoResidente = async (idResidente, idioma, token) => {
  if (!idResidente) return null;
  try {
    const url = `${cfg.apiUrl}/peticion/documentosrechazados/${idResidente}`;
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": idioma,
        "Authorization": `Bearer ${token}`,
        
        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });

    if (!resp.ok) throw new Error(`Error ${resp.status}: ${resp.statusText}`);

    const data = await resp.json();

    if (!data.ok || !data.datos) {
      console.log("No hay documentos rechazados que rectificar.");
      return [];
    }

    console.log("%Documentos rechazados que rectificar:", data.datos);
    return data.datos;
  } catch (error) {
    console.error("Error fetching documentos rechazados:", error);
    throw error;
  }
};

export default getDocumentosRechazoResidente;
