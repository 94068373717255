import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import { IdiomaSelector } from "../../ui/components/IdiomaSelector";
import { Link } from "react-router-dom";

export const AutLayout = ({ children }) => {
  const { cfg, datosGenerales } = useContext(AppContext);
  const { idiomas, idioma, cambiarIdioma } = useContext(IdiomaContext);

  const esMovil = useMediaQuery("(max-width:768px)");

  const organismo = datosGenerales.organismo;
  const zona = datosGenerales.titulo;

  const onCambiaIdioma = (nuevoIdioma) => {
    cambiarIdioma(nuevoIdioma);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        color: "black",
        minHeight: "100vh",
        backgroundColor: "primary.main",
        backgroundImage: `url(${cfg.apiUrl + "/public/fondo"})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        pt: 4,
        overflow: "hidden",
      }}
    >
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: "rgba(255,255,255,1)",
          width: "96vw",
          maxWidth: "96vw",
          borderRadius: 2,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "black.main",
          color: "black.main",
          pl: esMovil ? 1 : 2,
          pr: esMovil ? 1 : 2,
          pt: 1,
          pb: 1,
          marginBottom: 2,
          display: "flex",
          flexWrap: "nowrap",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          item
          xs={9}
          alignItems="center"
          sx={{ overflow: "hidden" }}
        >
          <Grid
            item
            xs={"auto"}
            sx={{
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: esMovil ? "center" : "flex-start",
            }}
          >
            <Box
              component={Link}
              to="/"
              borderRadius={3}
              sx={{
                width: !esMovil ? "auto" : "65px",
                marginRight: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                alt="Logo"
                src={cfg.apiUrl + "/public/logo1"}
                sx={{
                  width: "100%",
                  borderRadius: 3,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs sx={{ minWidth: 0 }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: esMovil ? 20 : 36,
                ml: esMovil ? 0 : 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "inherit",
              }}
            >
              {organismo}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: esMovil ? 16 : 24,
                ml: esMovil ? 0 : 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "inherit",
              }}
            >
              {zona}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs="auto"
          sx={{
            textAlign: "right",
            flexShrink: 0,
          }}
        >
          <IdiomaSelector
            idiomas={idiomas}
            idiomaSeleccionado={idioma}
            onSeleccionCambia={onCambiaIdioma}
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: 3,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
