import { useContext, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { IdiomaContext } from "../../context/IdiomaContext";

export const TablaAreasInfo = ({ areas, mostrarEstado }) => {
  const { cad } = useContext(IdiomaContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "primary.main" }}>
              {cad("AREAS_SIN_TMAX")}
            </TableCell>
            {mostrarEstado && (
              <TableCell align="center" sx={{ color: "primary.main" }}>
                {cad("ESTADO")}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {areas &&
            areas.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ pt: 0, pb: 0, width: "75%" }}
                >
                  {row.nombre}
                </TableCell>
                {mostrarEstado && (
                  <TableCell align="center" sx={{ pt: 0, pb: 0, width: "25%" }}>
                    {row.aceptada === "0" && (
                      <Tooltip title={cad("PENDIENTE")}>
                        <HourglassEmptyIcon />
                      </Tooltip>
                    )}
                    {row.aceptada === "1" && (
                      <Tooltip title={cad("ACEPTADA")}>
                        <CheckIcon />
                      </Tooltip>
                    )}
                    {row.aceptada === "2" && (
                      <Tooltip title={cad("RECHAZADA")}>
                        <ClearIcon />
                      </Tooltip>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
