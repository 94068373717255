import { AprLayout } from "../layout/AprLayout"
import { cfg } from '../../configuracion'
import { useContext, useEffect, useState } from "react";
import { AutContext } from "../../aut/context/AutContext";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useFormulario } from "../../hooks/useFormulario";
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from "../../ui/components/AlertDialog";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const solicitudDatosResidente = async (idioma, idResidente) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const url = cfg.apiUrl + '/datosresidente?idResidente=' + encodeURIComponent(idResidente);
        const resp = await fetch(url, {
            method: 'GET',
            headers: { 
                'Accept-Language': idioma,
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            }
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
}

const formatearFecha = (fecha) => {
    if (!fecha) return;
    fecha = fecha.trim();
    if (!fecha) return;
    
    const splFechaHora = fecha.split(' ');
    let splFecha = splFechaHora[0].split('-');
    if (splFecha.length!==3) {
        splFecha = splFechaHora.split('/');
    }

    const mes = splFecha[1].padStart(2, '0');
    let dia;
    let anio;

    if (splFecha[0].length===4) {
        anio = splFecha[0].padStart(4, '0');
        dia = splFecha[2].padStart(2, '0');
    } else {
        anio = splFecha[2].padStart(4, '0');
        dia = splFecha[0].padStart(2, '0');
    }

    return `${dia}/${mes}/${anio} 00:00:00`;
}

const fechaHoyFormateadaDateInput = () => {
    const hoy  = new Date();
    const dia = hoy.getDate().toString().padStart(2,'0');
    const mes = (hoy.getMonth() + 1).toString().padStart(2,'0');
    const anio = hoy.getFullYear().toString().padStart(4,'0');
    return `${anio}-${mes}-${dia}`;
}

const guardarPermiso = async (idioma, json) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        console.log(json);
        const resp = await fetch(cfg.apiUrl +'/permiso', {
            method: 'POST',
            headers: { 
                'Accept-Language': idioma,
                'Content-Type': 'application/json',
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            },
            body: JSON.stringify(json)
        });
        if (resp.status === 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        console.log('ERROR fetch permisos: ', error);
        respuesta = { ...respuesta, error };
    }
    return respuesta;
}

const borrarPermiso = async (idioma, id) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const resp = await fetch(cfg.apiUrl +'/permiso?id=' + id, {
            method: 'DELETE',
            headers: { 
                'Accept-Language': idioma,
                'Content-Type': 'application/json',
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            },
            //body: JSON.stringify(json)
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
}

export const AprTemporalesPage  = () => {
    const { datosGenerales } = useContext(AppContext);
    const { sesionIniciada, usuario, iniciarSesion, cerrarSesion } = useContext(AutContext);
    const { cad } = useContext(IdiomaContext);
    const [ datosResidente, setDatosResidente ] = useState(null);
    const [mostrarError, setMostrarError] = useState(null);
    const [erroresForm, setErroresForm] = useState({});
    const [alert, setAlert] = useState({ titulo: '', texto:'' });
    const {estadoForm, onCambiaCampo} = useFormulario({
        area: 0, 
        matricula: '', 
        fechaDesde: fechaHoyFormateadaDateInput()
    });
    const [idPermisoBorrar, setIdPermisoBorrar] = useState(0);
    const [abrirDialogoBorrar, setAbrirDialogoBorrar] = useState(false);

    useEffect( () => {
        solicitudDatosResidente('es', usuario.id)
        .then(({datos}) => {
            setDatosResidente(datos);
        })
        .catch(error => console.log(error));
    }, []);

    const onCambiaMatricula = ({target}) => {
        const {name, value} = target;
        console.log('onCambiaMatricula', name, value);
        const nValue = value.toUpperCase()
            .replace(/[^@#*ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]/g, '');
        console.log('onCambiaMatricula2', name, value, nValue);
        onCambiaCampo({target: {name, value: nValue}});
    };

    const validarArea = (value) => {
        return (!value || value<=0) ? cad('Área no válida') : ''
    }

    const onBlurArea = ({target}) => {
        const { name, value } = target;
        const msg = validarArea(value);
        
        setErroresForm({
            ...erroresForm,
            [name]: msg
        });
    }

    const validarMatricula = (value) => {
        return (!value || value.length<=5) ? cad('Matrícula no válida') : ''
    }

    const onBlurMatricula = ({target}) => {
        const { name, value } = target;
        console.log('onBlurMatricula', name, value, target);
        const msg = validarMatricula(value);
        
        setErroresForm({
            ...erroresForm,
            [name]: msg
        });
    }

    const validarFecha = (value) => {
        return value ? '' : cad('Fecha no válida'); 
    }

    const onBlurFecha = ({target}) => {
        const { name, value } = target;
        console.log('onBlurMatricula', name, value, target);
        const msg = validarFecha(value);
        
        setErroresForm({
            ...erroresForm,
            [name]: msg
        });
    }

    const agregarPermisoTemporalLocal = (perm) => {
        const datos = {
            ...datosResidente,
            permisosTemporales : [
                ...datosResidente.permisosTemporales,
                perm
            ]
        }
        setDatosResidente(datos);
    }


    const onEnviar = (event) => {
        event.preventDefault();
        
        if (erroresForm.matricula || erroresForm.are 
            || erroresForm.fechaDesde) {
            return;
        }

        let area = estadoForm?.area;
        if (!area && areas?.length && areas?.length===1) {
            area = areas[0].id;
        }

        if (validarArea(area) 
            || validarMatricula(estadoForm.matricula) 
            || validarFecha(estadoForm.fechaDesde)) {

            onBlurArea({target: { name: 'area', value: area}});
            onBlurMatricula({target: { name: 'matricula', value: estadoForm.matricula}});
            onBlurFecha({target: { name: 'fechaDesde', value: estadoForm.fechaDesde}});
            setAlert({ titulo: 'Datos incorrectos', texto: 'Faltan campos por rellenar o la información proporcionada es incorrecta.' });
            return;
        }

        const json = {
            "id": 0,
            "idArea": area,
            "matricula": estadoForm.matricula,
            "fechaDesde": formatearFecha(estadoForm.fechaDesde),
            "fechaHasta": null,
            "idResidente": usuario.id,
        };

        guardarPermiso('es', json).then(resp => {
            console.log('Respuesta: ', resp);
            if (resp?.datos) {
                agregarPermisoTemporalLocal(json);
                setAlert({ titulo: 'Permiso guardado correctamente', texto: '' });
            }
            if (resp?.error) {
                console.error('Error respuesta petición', resp?.error);
                setAlert({ titulo: 'Se ha producido un error guardando permiso', texto: '' });
            }
        });
    }

    const eliminarPermisoDelEstado = (id) => {
        setDatosResidente({
            ...datosResidente,
            permisosTemporales: datosResidente.permisosTemporales
                .filter(per => per.id !== id)
        });
    }

    const onCerrarDialogoBorrar=(aceptar) => {
        if (aceptar) {
            borrarPermiso('es', idPermisoBorrar)
            .then(() => {
                console.log('Borrar ' + idPermisoBorrar);
                eliminarPermisoDelEstado(idPermisoBorrar);
                setIdPermisoBorrar(0);
            })
            .catch(error => console.error(error))
            .finally(() => {setAbrirDialogoBorrar(false);});
        } else {
            setIdPermisoBorrar(0);
            setAbrirDialogoBorrar(false);
        }
    }

    const onBorrarClick = (id) => {
        console.log('Borrando... ' + id);
        setIdPermisoBorrar(id);
        setAbrirDialogoBorrar(true);
    }

    const areasPru = [
        {id: 542, nombre: 'Area 51'}, 
        {id: 2, nombre: 'Arrea por ahí'},
        {id: 3, nombre: 'Area jacta est'},
        {id: 4, nombre: 'Areal black'},
    ];
    const areas = Object.entries(datosGenerales?.zonas).map(( [k, v] ) => ({ id: Number(k), nombre: v }));


    return(
        <>
        <AprLayout>
            <Grid container item xs={10} sx={{ mt:3 }}>
                <Typography variant="h4" color="primary.main" >Permisos temporales</Typography>
            </Grid>
            <Grid container item xs={10}
                justifyContent={"space-between"}
                sx={{
                    mt:3,
                    pt: 2, pl: 4, pb: 4, pr: 4,
                    borderRadius: 2,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'secondary.main',
                    //backgroundColor: 'primary.main',                    
                }}> 

                <Grid container item xs={6} sx={{ mt:2 }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Borrar</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} >Matricula</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Área</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Fecha</TableCell>
                                    <TableCell sx={{ color: 'primary.main' }} align="center">Validado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {datosResidente?.permisosTemporales.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">
                                        <IconButton onClick={() => onBorrarClick(row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">{row.matricula}</TableCell>
                                    <TableCell align="center">
                                        {(areas.find(area => area.id === row.idArea)?.nombre) || '(' + row.idArea + ')'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.fechaDesde && row.fechaDesde.split(' ')[0]}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.validado ? 
                                            row.validado === '1' ? <CheckIcon color={'primary.main'} /> : <ClearIcon color={'error'} />
                                            : <HourglassEmptyIcon color={'warning'} />
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid container item xs={4} 
                    sx={{ mt:5, ml: 10,}} 
                    direction={"column"} alignItems={"flex-start"} spacing={3}
                >
                    <Grid item>
                        <Typography variant="h5" color="primary.main" >Añadir permiso</Typography>
                    </Grid>
                    <Grid item container 
                        component="form"
                        sx={{
                            pr: 3,
                            borderRadius: 4,
                            borderColor:'secondary.main',
                            borderWidth: 2,
                            borderStyle: 'solid'
                        }}
                        onSubmit={ onEnviar }
                    >
                        <Grid item xs={12}>
                            <FormControl fullWidth
                                error = { Boolean(erroresForm.area) } 
                            > 
                                <InputLabel id="demo-simple-select-label">Área</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={
                                        estadoForm.area
                                        || ((areas?.length === 1) ? areas[0].id : '0')
                                    }
                                    name="area"
                                    label="Área"
                                    disabled={ (!areas?.length) || areas?.length==1 }
                                    onChange={onCambiaCampo}
                                    onBlur={onBlurArea}
                                >
                                    {
                                        (Boolean(!areas?.length) || areas.length >1)
                                        && (<MenuItem key="0" value="0"><em>(Seleccione opción...)</em></MenuItem>)
                                    }
                                    {
                                        (areas?.length) ?
                                            areas.map(area => (
                                                <MenuItem key={area.id} value={area.id}>{area.nombre}</MenuItem>
                                            ))
                                            : ''
                                    }
                                </Select>
                                <FormHelperText>{ erroresForm.area }</FormHelperText>
                            </FormControl>
                        </Grid>
                    
                        <Grid item xs={ 12 } sx={{ mt: 2 }}>
                            <TextField 
                                error = { Boolean(erroresForm.matricula) }
                                helperText = { erroresForm.matricula } 
                                label={'Matrícula'}
                                name="matricula"
                                type="text"
                                placeholder={'Matrícula'}
                                onChange={onCambiaMatricula}
                                onBlur={onBlurMatricula}
                                autoComplete={'off'}
                                value={estadoForm.matricula}
                                fullWidth
                                inputProps={{ 
                                    style: { textTransform: "uppercase" },
                                    maxLength: 10
                                }}
                            />
                        </Grid>
                        <Grid item xs={ 12 } sx={{ mt: 2 }}>
                            <TextField
                                name ="fechaDesde"
                                label="Fecha"
                                type="date"
                                //defaultValue="2022-08-08"
                                onChange={onCambiaCampo}
                                onBlur={onBlurFecha}
                                value={estadoForm.fechaDesde}
                                fullWidth
                                InputLabelProps={{ shrink: true, }}
                                inputProps={{ min: fechaHoyFormateadaDateInput() }}
                            />
                        </Grid>
{/*
                        <Grid item xs={ 12 } sx={{ mt: 2 }}>
                            <DesktopDatePicker fullWidth
                                label="Fecha"
                                inputFormat="MM/dd/yyyy"
                                value={estadoForm.fechaDesde}
                                onChange={onCambiaCampo}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
*/}
                        <Grid container spacing={2} sx={{mb:2, mt:1}} justifyContent={"flex-end"}>
                            <Grid item>
                                <Button 
                                    variant='contained' 
                                    //fullWidth
                                    startIcon={<AddIcon />}
                                    type={'submit'}
                                >
                                    {cad('AÑADIR PERMISO')}
                                </Button>
                            </Grid>
                        </Grid>
                            
                    </Grid>
                </Grid>
            </Grid>
        </AprLayout>
        <AlertDialog titulo={alert.titulo} texto = {alert.texto} />
        <Dialog
            open={abrirDialogoBorrar}
            onClose={() => onCerrarDialogoBorrar(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {'¿Seguro que quiere borrar el permiso ' + 
                datosResidente?.permisosTemporales.find(
                    per => per.id === idPermisoBorrar)?.matricula + '?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Tenga en cuenta que si borra el permiso, en caso de querer recuperarlo 
                    tendrá que volver a someterse al proceso de validación.
                    <br />
                    ¿Desea borrarlo igualmente?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCerrarDialogoBorrar(false)}>No</Button>
                <Button onClick={() => onCerrarDialogoBorrar(true)} autoFocus>Sí</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}