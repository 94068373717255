import React, { createContext, useContext } from "react";

// Crear el contexto
const FileContext = createContext();

// Crear un proveedor para el contexto
export const FileProvider = ({ children }) => {

  // Función para comprimir archivos (principalmente imágenes)
  const resizeFile = (file, maxWidth, maxHeight, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Mantener la proporción original
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Reducir la calidad de la imagen hasta que sea menor de 100KB
        let quality = 0.9; // Comenzar con 90% de calidad
        const targetSize = 100 * 1024; // 100 KB en bytes

        const compressFile = () => {
          canvas.toBlob((blob) => {
            if (blob.size <= targetSize || quality <= 0.1) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              callback(compressedFile);
            } else {
              quality -= 0.1;
              compressFile();
            }
          }, file.type, quality);
        };

        compressFile();
      };
    };
  };

  return (
    <FileContext.Provider value={{ resizeFile }}>
      {children}
    </FileContext.Provider>
  );
};

export const useFileContext = () => useContext(FileContext);
