import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { types } from "./types";
import { IdiomaContext } from "./IdiomaContext";
import { obtenerClavesIdioma } from "./idiomaClaves";
import { cfg } from "../configuracion";
import { useMutation, useQuery } from "@tanstack/react-query";
import { buscarCadenasPorClave } from "../aut/services/buscarCadenasPorClave";
import { getIdiomasPorLocale } from "../aut/services/getIdiomasPorLocale";
import { AutContext } from "../aut/context/AutContext";
import updateIdiomaResidente from "../aut/services/updateIdiomaResidente";
import { PublicTokenContext } from "./PublicTokenContext";

/* const solicitudCadenas = async (idioma, claves = []) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const url = cfg.apiUrl + '/cadenas';
        const resp = await fetch(url, {
            method: 'POST',
            headers: { 
                'Accept-Language': idioma,
                'Content-Type': 'application/json',
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"

            },
            body: JSON.stringify({locale: idioma, claves})
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
} */

/* const solicitudIdiomas = async (idioma) => {
    let respuesta = {
        datos: null,
        error: null
    }
    try {
        const url = cfg.apiUrl + '/idiomas?locale=' + encodeURIComponent(idioma);
        const resp = await fetch(url, {
            method: 'GET',
            headers: { 
                'Accept-Language': idioma,
                'Authorization': cfg.autToken,

                // Deshabilitar provisionalmente la caché
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0"
            }
        });
        if (resp.status == 200) {
            let datos = await resp.json();
            respuesta = { ...respuesta, datos };
        } else {
            respuesta = { ...respuesta, error: resp };
        }
    } catch (error) {
        respuesta = { ...respuesta, error };
    }
    return respuesta;
} */

/* const inicializar = () => {

    let estado = {
        idioma: 'es',
        defecto: 'es',
        cadenas: {}, //{"ERR_USUARIO_NO_VALIDO":"Usuario no válido","ERR_CONTRASEÑA_CORTA":"Contraseña muy corta","NIF_CIF":"NIF/CIF","HE_OLVIDADO_MI_CONTRASEÑA":"He olvidado mi contraseña","ERR_ERROR_ACCESO":"Error de acceso","REGISTRARSE":"Registrarse","CONTRASEÑA":"Contraseña","ENTRAR":"Entrar","POR_FAVOR_ESPERE":"Por favor, espere...","IDENTIFICACION_ERRONEA":"Usuario o clave incorrectos"},
        idiomas: {} //{"de":"Alemán","ast":"Bable","vlca":"Valenciano","pt":"Portugués","gl":"Gallego","en":"Inglés","it":"Italiano","fr":"Francés","mlrq":"Balear","es":"Español","eu":"Euskera","en_us":"Inglés (EEUU)","arg":"Aragonés","gnc":"Guanche","ca":"Catalán"}
    };

    return estado;
} */

const claves = obtenerClavesIdioma();

export const IdiomaProvider = ({ children }) => {
  //const [estado, dispatch] = useReducer(idiomaReducer, {}, inicializar);
  const { usuario, iniciarSesion, updateSesion } = useContext(AutContext) || {};
  const { tokenCliente } = useContext(PublicTokenContext);
  const [idioma, setIdioma] = useState("es");

  //const [cargandoIdiomas, setCargandoIdiomas] = useState(false);
  //const [cargandoCadenas, setCargandoCadenas] = useState(false);

  /* const recargarIdiomas = (idioma) => {
    setCargandoIdiomas(true);
    solicitudIdiomas(idioma)
        .then(({ datos }) => {
            dispatch({
                type: types.cambiarIdiomas,
                payload: {
                    defecto: datos.codigoDefecto,
                    idiomas: datos.idiomas
                }
            });
        })
        .catch(error => console.log(error))
        .finally(() => setCargandoIdiomas(false));
} */

  /* const recargarCadenas = (idioma) => {
        const claves = obtenerClavesIdioma();
        setCargandoCadenas(true);
        solicitudCadenas(idioma, claves)
            .then(({ datos }) => {
                dispatch({
                    type: types.cambiarCadenas,
                    payload: datos
                });
            })
            .catch(error => console.log(error))
            .finally(() => setCargandoCadenas(false));
    } */

  const { data: idiomas, isLoading: cargandoIdiomas } = useQuery({
    queryKey: ["getIdiomasPorLocale", { idioma: idioma, token: tokenCliente }],
    queryFn: () => getIdiomasPorLocale(idioma, tokenCliente),
  });

  const {
    data: cadenas,
    isLoading: cargandoCadenas,
    isSuccess: cadenasCargadas,
  } = useQuery({
    queryKey: [
      "buscarCadenasPorClave",
      { idioma: idioma, token: tokenCliente },
    ],
    queryFn: () => buscarCadenasPorClave(idioma, tokenCliente),
  });

  /*
        Función asíncrona para llamar al servicio PUT de idioma.
        Además, una vez finalizada la llamada con éxito, modifica la sesión del usuario 
        en el estado y localStorage) utilizando la función inciarSesion
    */
  const updateIdiomaResidenteAsync = async (idiomaResidente) => {

    if (usuario && usuario?.id) {
      const { authToken } = usuario;
      try {
        // Llamamos a la función que actualiza el idioma en el backend
        /*   console.log(`Actualizando el idioma del residente. ID Usuario: ${usuario.id}, Idioma Residente: ${idiomaResidente}, Accept-Language: ${idioma}, Token: ${authToken}`);   */
        const { idiomaActualizado, idiomaRespuesta } =
          await updateIdiomaResidente(
            usuario?.id, // ID del usuario
            idiomaResidente, // El idioma al que queremos cambiar
            idiomaResidente, // El idioma en el que queremos la respuesta
            authToken // Token de autenticación
          );

        // Actualizamos la sesión del usuario con el idioma actualizado
        updateSesion({ ...usuario, idioma: idiomaActualizado });
      } catch (error) {
        console.error("Error al actualizar el idioma del residente:", error);
      }
    }
  };

  const cambiarIdioma = (nuevoIdioma) => {
    setIdioma(nuevoIdioma);
    updateIdiomaResidenteAsync(nuevoIdioma);

    if (!usuario) {
      sessionStorage.setItem("idiomaCliente", nuevoIdioma);
    }
  };

  // Fuerza a sobreescribir el idioma actual, independientemente de si hubo cambios o no.
  /*   useEffect(() => {
    const checkIdiomaSessionStorage = sessionStorage.getItem('idiomaCliente') || 'es';
        if (!usuario) {
      setIdioma(checkIdiomaSessionStorage);
    }
  }, [usuario]); */

  const cad = useCallback(
    (clave) => {
      if (!cadenasCargadas) return;
      if (!cadenas) return clave;
      return cadenas[clave] || clave;
    },
    [cadenas, cadenasCargadas]
  );

  useEffect(() => {
    if (usuario && usuario?.idioma) setIdioma(usuario.idioma);
  }, [usuario]);

  /*
    useEffect(() => {
        cambiarIdioma('ca');
    }, []);
    */

  return (
    <IdiomaContext.Provider
      value={{
        cadenas,
        idioma,
        idiomas,
        defecto: "es",
        cargando: cargandoIdiomas || cargandoCadenas,
        cambiarIdioma,
        cad,
      }}
    >
      {children}
    </IdiomaContext.Provider>
  );
};
