import { cfg } from "../../configuracion";

//cambiar token aqui
export const updateResidente = async (idioma, json, token) => {
  let respuesta = {
    datos: null,
    error: null,
  };
  try {
    const url = cfg.apiUrl + "/residente";
    /*  console.log("%Datos enviados:", json);   */
    const resp = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
      body: JSON.stringify(json),
    });
    console.log("%Respuesta del servidor:", resp);
    if (resp.status === 200) {
      let datos = await resp.json();
      console.log("%Datos recibidos del servidor:", datos);
      respuesta = { ...respuesta, datos };
    } else {
      respuesta = { ...respuesta, error: "Error: " + resp.status };
      console.log("%Error en la respuesta del servidor:", respuesta.error);
    }
  } catch (error) {
    respuesta = { ...respuesta, error };
  }
  return respuesta;
};
