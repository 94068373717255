import { Alert, Grid, LinearProgress, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { AutContext } from "../../aut/context/AutContext";
import { PerfilForm } from "../../ui/components/PerfilForm";
import { AprLayout } from "../layout/AprLayout";
import { cfg } from "../../configuracion";
import { md5 } from "../../aut/md5";
import { IdiomaContext } from "../../context/IdiomaContext";
import { updateResidente } from "../../aut/services/updateResidente";

export const AprPerfilPage = () => {
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [mostrarCompletado, setMostrarCompletado] = useState(null);
  const { cad, idioma } = useContext(IdiomaContext);
  const { usuario, iniciarSesion } = useContext(AutContext);

  const onDatosEmitidos = async (ret) => {
    console.log("% Datos emitidos desde PerfilForm:", ret); // Log para verificar la recepción de datos
    setMostrarCargando(true);
    setMostrarError(null);
    console.log("Datos emitidos:", ret);

    // Extrae token del usuario
    const {authToken} = usuario;
    const res = await updateResidente(
      idioma,
      {
        ...ret,
        idioma: idioma,
        clave: ret.clave ? md5(ret.clave) : null,
      },
      // Mandamos el token del usuario para autenticación.
      authToken
    );
    if (res?.datos) {
      setMostrarCompletado("Sus datos han sido guardados con éxito");
      /*
      const { nif, clave } = ret;
      iniciarSesion(nif, md5(clave));
      */
    }
    setMostrarCargando(false);
  };

  return (
    <AprLayout>
      <Grid
        container
        item
        xs={10}
        sx={{
          mt: 5,
          p: 2,
          borderRadius: 2,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "secondary.main",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            pl: 2,
            pt: 1,
            pb: 2,
            color: "primary.main",
          }}
        >
          {cad("MIS_DATOS")}
        </Typography>
        <PerfilForm perfil={usuario} onEmitirDatos={onDatosEmitidos} />
      </Grid>
      <Grid item xs={8} mt={5}>
        {mostrarCargando && (
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{ backgroundColor: "white" }}
          >
            {cad("POR_FAVOR_ESPERE")}
            <LinearProgress color="success" />
          </Alert>
        )}
      </Grid>
      <Grid item xs={8} mt={1}>
        {mostrarError && (
          <Alert variant="filled" severity="error">
            {mostrarError}
          </Alert>
        )}
      </Grid>
      <Grid item xs={8} mt={1}>
        {mostrarCompletado && (
          <Alert variant="filled" severity="success">
            {mostrarCompletado}
          </Alert>
        )}
      </Grid>
    </AprLayout>
  );
};
