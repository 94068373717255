import { useContext, useEffect, useReducer, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { types } from "../types/types";
import { AutContext } from "./AutContext";
import { autReducer } from "./autReducer";
import { cfg } from "../../configuracion";
import { postLogin } from "../services/postLogin";
import { IdiomaContext } from "../../context";
import { getResidenteIdioma } from "../services/getResidenteIdioma"

const inicializar = () => {
  const usuario = JSON.parse(
    localStorage.getItem(cfg.prefijoLocalStorage + "usuario")
  );
  return {
    sesionIniciada: !!usuario,
    usuario,
  };
};

const nombreCompleto = (usu) => {
  if (!usu) return "";
  let nombre = usu.nombre || "";
  if (nombre && usu.apellido1) {
    nombre += " " + usu.apellido1;
  }
  nombre = nombre || usu.nif;
  nombre = nombre || usu.correoe;
  nombre = nombre || "";
  return nombre;
};

const iniciales = (usu) => {
  if (!usu) return "";
  let iniciales = "";

  iniciales = usu.nombre ? usu.nombre[0] : "";
  iniciales = iniciales + (usu.apellido1 ? usu.apellido1[0] : "");
  iniciales = iniciales.toLocaleUpperCase();

  return iniciales;
};

export const AutProvider = ({ children }) => {
  const [autEstado, dispatch] = useReducer(autReducer, {}, inicializar);
  const [loading, setLoading] = useState(true);

  const updateSesion = (usu) => {
    const usuCompleto = {
      ...usu,
      nombreCompleto: nombreCompleto(usu),
      iniciales: iniciales(usu),
    };

    const action = {
      type: types.iniciar,
      payload: usuCompleto,
    };
    localStorage.setItem(
      cfg.prefijoLocalStorage + "usuario",
      JSON.stringify(usuCompleto)
    );
    dispatch(action);
  };

  const iniciarSesion = async (usuario, clave, tipo) => {

    // Eliminar la cookie JSESSIONID para el Wildfly
    document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.omnivisionseguridad.com";

    const idiomaCliente = sessionStorage.getItem('idiomaCliente') || 'es';

    console.log("%Datos que envia el FE al servidor:", usuario, clave, tipo, idiomaCliente);

    const resp = await postLogin(usuario, clave, tipo, idiomaCliente);

    console.log("%Datos intento de login", resp);
    if (resp?.datos) {
      if (resp?.datos?.error) {
        throw new Error(resp?.datos?.error);
      }
    }
    if (resp?.error) {
      throw new Error(resp?.error);
    }
    updateSesion(resp.datos);
  };

  const cerrarSesion = () => {
    // Guardamos el valor del idioma del usuario, para sincronizarlo con el idioma cliente (!usuario).
    const idiomaActual = autEstado.usuario?.idioma;
    // Almacenamos el último idioma del usuario. para sincronizarlo con el idioma cliente.
    sessionStorage.setItem('idiomaCliente', idiomaActual);
    // Eliminamos al usuario del localStorage.
    localStorage.removeItem(cfg.prefijoLocalStorage + "usuario");

    // Eliminar la cookie JSESSIONID para el Wildfly
    document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.omnivisionseguridad.com";

    
    dispatch({ type: types.cerrar });
  };

  useEffect(() => {
    const sincronizarIdiomaResidente = async () => {
      if (autEstado.usuario) {
        try {
          const idiomaActualizado = await getResidenteIdioma(
            autEstado.usuario.id,
            autEstado.usuario.idioma,
            autEstado.usuario.authToken
          );
  
          if (idiomaActualizado && autEstado.usuario.idioma !== idiomaActualizado) {
  
              const usuarioActualizado = {
                ...autEstado.usuario,
                idioma: idiomaActualizado,
              };
              updateSesion(usuarioActualizado);
              
                setLoading(false);

          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error("Error al obtener el idioma del residente:", error);
          setLoading(false);
        }
      }
    };
  
    sincronizarIdiomaResidente();
  }, [autEstado.usuario]);

  return (
    <AutContext.Provider
      value={{ ...autEstado, iniciarSesion, cerrarSesion, updateSesion, loading }}
    >
      {children}
    </AutContext.Provider>
  );
};
