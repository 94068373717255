import { Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import {
  AprInicioPage,
  AprTemporalesPage,
  AprPermanentesPage,
  AprPerfilPage,
} from "../pages";
import { ValPermisosPage } from "../pages/ValPermisosPage";
import { PermisosPage } from "../pages/PermisosPage";
import { PeticionesPage } from "../pages/PeticionesPage";
import { CrearPeticionPage } from "../pages/CrearPeticionPage";
import ModificacionPerfilPage from "../pages/ModificacionPerfilPage";
import { AutContext } from "../../aut/context/AutContext"; // Asegúrate de que esta ruta sea correcta

export const AprRoutes = () => {
  const { usuario } = useContext(AutContext);

  if (usuario && (usuario.estado === "0" || usuario.estado === "2" || usuario.estado === "3")) {
    return (
      <Routes>
        <Route path="/modificar-perfil" element={<ModificacionPerfilPage />} />
        <Route path="*" element={<Navigate to="/modificar-perfil" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AprInicioPage />} />
      <Route path="temporales" element={<AprTemporalesPage />} />
      {/* <Route path="permanentes" element={<AprPermanentesPage />} /> */}
      <Route path="perfil" element={<AprPerfilPage />} />
      <Route path="valpermisos" element={<ValPermisosPage />} />
      <Route path="permisos" element={<PermisosPage />} />
      <Route path="peticiones" element={<PeticionesPage />} />
      <Route path="crearpeticion" element={<CrearPeticionPage />} />
      <Route path="modificar-perfil" element={<ModificacionPerfilPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
