import { cfg } from "../../configuracion";

export const getRespuestas = async (idPeticion, idioma, token) => {
  if (!idPeticion) return [];

  try {
    const url = cfg.apiUrl + "/peticion";
    const resp = await fetch(`${url}/${idPeticion}/respuestas`, {
      method: "GET",
      headers: {
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });

    if (resp.status == 200) {
      const data = await resp.json();
      /* console.log("%Respuesta", data); */
      return data.datos;
    }

    throw new Error(resp.statusText);
  } catch (err) {
    throw err;
  }
};
