import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Link,
  Alert,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  Backdrop,
  CircularProgress,
  Box,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  AccountCircle,
  Visibility,
  VisibilityOff,
  Input as InputIcon,
} from "@mui/icons-material";
import { AutContext } from "../context/AutContext";
import { AutLayout } from "../layout/AutLayout";
import { md5 } from "../md5";
import { useFormulario } from "../../hooks/useFormulario";
import { IdiomaContext } from "../../context/IdiomaContext";
import "animate.css";
import CustomCaptcha from "../../ui/components/utils/CustomCaptcha";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import InfoIcon from "@mui/icons-material/Info";
import { cfg } from "../../configuracion";
import { getVersionApi } from "../services/getVersionApi";
import { PublicTokenContext } from "../../context/PublicTokenContext";

export const EntrarPage = () => {
  const navigate = useNavigate();
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [mostrarClave, setMostrarClave] = useState(false);
  const [mostrarDialogInfo, setMostrarDialogInfo] = useState(false);
  const [versionAPI, setVersionAPI] = useState(null);
  const { tokenCliente } = useContext(PublicTokenContext);

  const { iniciarSesion } = useContext(AutContext);
  const [erroresForm, setErroresForm] = useState({});
  const [selectTipoNif, setSelectTipoNif] = useState(false);
  const [tipoResidente, setTipoResidente] = useState("E");

  const { idioma, cad } = useContext(IdiomaContext);

  const esMovil = useMediaQuery("(max-width:768px)");

  const [captchaValido, setCaptchaValido] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(true);

  const { nif, clave, estadoForm, onCambiaCampo } = useFormulario({
    nif: "",
    clave: "",
    tipo: "",
  });

  const isValidNIF = (nif) => {
    const nifRegex = /^[0-9]{8}[A-Z]$/;
    return nifRegex.test(nif);
  };

  const toggleDialogInfo = () => {
    setMostrarDialogInfo((prevState) => !prevState);
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };
  }, []);

  const handleCaptchaChange = (value) => {
    const esValido = validateCaptcha(value, false);
    setCaptchaValido(esValido);
    if (esValido) {
      setErroresForm((previo) => {
        return { ...previo, captcha: false };
      });
      setCaptchaVisible(false);
    }
  };

  const validarNif = (value) => {
    const resultado =
      !value || value.length <= 5 ? cad("ERR_NIF_CIF_INCORRECTO") : "";
    return resultado;
  };

  const onBlurNif = ({ target }) => {
    const { name, value } = target;
    const msg = validarNif(value);
    setSelectTipoNif(isValidNIF(value));

    console.log("RESULTADO NIF", msg);
    setErroresForm((prev) => {
      return {
        ...prev,
        [name]: msg,
      };
    });
  };

  const onCambiaNif = ({ target }) => {
    const { name, value } = target;
    const nValue = value
      .toUpperCase()
      .replace(/[^@#*ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]/g, "");
    const isNIF = isValidNIF(nValue);

    setSelectTipoNif(isNIF);
    if (!isNIF) {
      setTipoResidente("E");
    } else {
      setTipoResidente("");
    }

    onCambiaCampo({ target: { name, value: nValue } });
  };

  const onCambiaRadio = (event) => {
    setTipoResidente(event.target.value);
    onCambiaCampo(event);
  };

  const validarClave = (value) => {
    return !value || value.trim().length <= 0
      ? cad("ERR_CAMPO_OBLIGATORIO")
      : "";
  };

  const onBlurClave = ({ target }) => {
    const { name, value } = target;
    const msg = validarClave(value);

    setErroresForm((prev) => {
      return {
        ...prev,
        [name]: msg,
      };
    });
  };

  const onBlurCaptcha = ({ target }) => {
    const { name, value } = target;
    const msg = !validateCaptcha(value, false)
      ? cad("ERR_CAMPO_OBLIGATORIO")
      : "";
    setErroresForm((prev) => {
      return {
        ...prev,
        [name]: msg,
      };
    });
  };

  const onCambiaMostrarClave = () => {
    setMostrarClave(!mostrarClave);
  };

  const onEntrar = async (event) => {
    event.preventDefault();

    if (erroresForm.nif || erroresForm.clave) {
      return;
    }

    if (validarNif(estadoForm.nif) || validarClave(estadoForm.clave)) {
      onBlurNif({ target: { name: "nif", value: estadoForm.nif } });
      onBlurClave({ target: { name: "clave", value: estadoForm.clave } });
      return;
    }

    setMostrarCargando(true);

    try {
      if (!isValidNIF(estadoForm.nif)) {
        estadoForm.tipo = "E";
      }

      /*       
      console.log("%Datos enviados al servidor: ", {
        nif: estadoForm.nif,
        clave: md5(estadoForm.clave),
        tipo: estadoForm.tipo,
        idioma
        }
      ); 
    */

      setMostrarError(null);

      await iniciarSesion(
        estadoForm.nif,
        md5(estadoForm.clave),
        estadoForm.tipo,
        idioma
      );
    } catch (err) {
      setMostrarError(err.message);
    }

    setMostrarCargando(false);
  };

  useEffect(() => {
    // En caso de que exista algún error, establece el error a null y "simula un login" (con los datos incorrectos a proposito). Para capturar el error en el nuevo idioma.
    if (mostrarError) {
      setMostrarError(null);

      const fakeLogin = async () => {
        try {
          await iniciarSesion(
            estadoForm.nif,
            md5(estadoForm.clave),
            estadoForm.tipo,
            idioma
          );
        } catch (err) {
          setMostrarError(err.message);
        }
      };

      fakeLogin();
    }
  }, [idioma]);

  useEffect(() => {
    if (erroresForm.nif)
      onBlurNif({ target: { name: "nif", value: estadoForm.nif } });
    if (erroresForm.clave)
      onBlurClave({ target: { name: "clave", value: estadoForm.clave } });
  }, [cad]);

  useEffect(() => {
    const fetchVersionAPI = async () => {
      const version = await getVersionApi(tokenCliente);
      setVersionAPI(version);
    };

    fetchVersionAPI();
  }, []);

  return (
    <AutLayout>
      <Grid container item xs={12} justifyContent="center">
        <Grid
          item
          className={"box-shadow"}
          lg={3}
          sm={12}
          sx={{
            color: "primary.main",
            backgroundColor: "white.main",
            borderColor: "secondary.main",
            borderWidth: 2,
            borderStyle: "solid",
            px: esMovil ? 2 : 3,
            py: esMovil ? 1 : 3,
            mt: { lg: 5, sm: 2 },
            borderRadius: 2,
            pb: 0,
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ m: 0, p: 0 }}>
              <Typography
                variant="h5"
                sx={{ visibility: esMovil ? "hidden" : "visible" }}
                color={"black.main"}
              >
                {cad("APR_TITULO_LOGIN")}
              </Typography>
            </Box>
            <Box
              onClick={() => toggleDialogInfo()}
              sx={{
                animation: "spin 3s infinite linear",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotateY(0deg)",
                  },
                  "100%": {
                    transform: "rotateY(360deg)",
                  },
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InfoIcon
                sx={{
                  fontSize: esMovil ? "2rem" : "2.5rem",
                  cursor: "pointer",
                  color: "inherit",
                }}
              />
            </Box>
          </Grid>

          {!esMovil && (
          <Grid item xs={12}>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          )}

          <form onSubmit={onEntrar} autoComplete={"off"}>
            <Grid container>
              <Grid item xs={12} sx={{ mt: esMovil ? 1 : 2, mb: 1 }}>
                <TextField
                  error={Boolean(erroresForm.nif)}
                  helperText={erroresForm.nif}
                  label={cad("NIF_CIF")}
                  name="nif"
                  type="text"
                  placeholder={cad("NIF_CIF")}
                  onChange={onCambiaNif}
                  onBlur={onBlurNif}
                  value={nif}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: { textTransform: "uppercase" },
                    maxLength: 15,
                  }}
                />
              </Grid>

              {selectTipoNif && (
                <Grid item xs={12} sx={{ mt: 1, mb: 1, color: "black.main" }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {cad("TIPO_RESIDENTE")}
                    </FormLabel>
                    <RadioGroup
                      sx={{ display: "flex", flexDirection: "row", ml: 1 }}
                      aria-label="tipo"
                      name="tipo"
                      value={tipoResidente}
                      onChange={onCambiaRadio}
                    >
                      <FormControlLabel
                        value="P"
                        control={<Radio />}
                        label={cad("ES_PARTICULAR")}
                      />
                      <FormControlLabel
                        value="A"
                        control={<Radio />}
                        label={cad("ES_AUTONOMO")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                <TextField
                  error={Boolean(erroresForm.clave)}
                  helperText={erroresForm.clave}
                  label={cad("CONTRASEÑA")}
                  name="clave"
                  type={mostrarClave ? "text" : "password"}
                  onChange={onCambiaCampo}
                  onBlur={onBlurClave}
                  placeholder={cad("CONTRASEÑA")}
                  value={clave}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onCambiaMostrarClave}
                          onMouseDown={onCambiaMostrarClave}
                        >
                          {mostrarClave ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {captchaVisible && (
                <CustomCaptcha
                  reloadText={cad("GENERAR_OTRO_CAPTCHA")}
                  reloadColor="#347445"
                  loadCaptchaEnginge={loadCaptchaEnginge}
                  erroresForm={erroresForm}
                  estadoForm={estadoForm}
                  onBlur={onBlurCaptcha}
                  onCaptchaChange={handleCaptchaChange}
                  label={cad("TECLEE_EL_TEXTO")}
                />
              )}

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    disabled={!captchaValido || !tipoResidente}
                    fullWidth
                    startIcon={<InputIcon />}
                    type={"submit"}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white.main",
                      opacity: !captchaValido || !tipoResidente ? 0.6 : 1,
                      transition: "opacity 0.3s ease-in-out",
                      "&.hover": {
                        backgroundColor: "primary.hover",
                        color: "white.hover",
                        opacity: 1,
                      },
                    }}
                  >
                    {cad("ENTRAR")}
                  </Button>
                </Grid>

                {mostrarError && (
                  <Grid item xs={12}>
                    <Alert
                      variant="filled"
                      severity="error"
                      sx={{
                        pt: 0.5,
                        pb: 0.5,
                        textAlign: "center",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 1,
                        color: "white.main",
                      }}
                    >
                      {mostrarError}
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Divider sx={{ mb: 1 }} />
                </Grid>

                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={0}
                  color={"black.main"}
                >
                  <Grid item>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to="/aut/recuperar"
                      sx={{ fontSize: "0.875rem" }}
                    >
                      {cad("HE_OLVIDADO_MI_CONTRASEÑA")}
                    </Link>
                  </Grid>

                  <Grid item>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to="/aut/reenviarcorreo"
                      sx={{ fontSize: "0.875rem" }}
                    >
                      {cad("NO_RECIBIDO_CORREO_CONFIRMACION")}
                    </Link>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      mt: "4px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      align="center"
                      fontSize={"0.875rem"}
                    >
                      {cad("NO_TIENES_CUENTA")} -{" "}
                      <Link
                        component={RouterLink}
                        to="/aut/registrar"
                        sx={{
                          fontSize: "0.875rem",
                          color: "primary.main",
                          "&.hover": { color: "primary.hover" },
                        }}
                      >
                        {cad("REGISTRARSE")}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid item mt={4}>
        {mostrarCargando && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6" sx={{ mt: 2 }}>
                {cad("POR_FAVOR_ESPERE")}
              </Typography>
            </Box>
          </Backdrop>
        )}
      </Grid>

      <Dialog
        open={mostrarDialogInfo}
        onClose={() => setMostrarDialogInfo(false)}
      >
        <DialogTitle>
          {cad("INFORMACION")}
          <Divider sx={{ my: 1 }} />
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            {`${cad("APR_DESCRIPCION")}.`}
          </Typography>

          <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "500" }}>
            {`${cad("VERSION")}:`}
          </Typography>

          <Typography variant="subtitle2" sx={{ mt: 1, ml: 1 }}>
            - <strong>API</strong>: {versionAPI}
          </Typography>
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            - <strong>Web</strong>: {cfg.versionWeb}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDialogInfo()}>{cad("CERRAR")}</Button>
        </DialogActions>
      </Dialog>
    </AutLayout>
  );
};
