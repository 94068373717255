import { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { IdiomaContext } from "../../context/IdiomaContext";
import { useTheme } from "@emotion/react";

export const TablaDocumentos = ({
  documentos,
  ficheros,
  onBorrar,
  onCargar,
}) => {
  const { cad } = useContext(IdiomaContext);
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 2, color: "primary.main" }}>
              {cad("DOCUMENTOS")}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell sx={{ pl: 2, color: "primary.main", textAlign: "end" }}>
              {cad("FICHEROS")}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documentos &&
            Object.entries(documentos)
              .map(([k, v]) => ({
                id: Number(k),
                nombre: v,
                nombreFichero: null,
                fichero: null,
              }))
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{ pt: 0, pr: 0, pb: 0 }}
                    component="th"
                    scope="row"
                  >
                    {row.nombre}
                  </TableCell>
                  <TableCell sx={{ p: 0 }} align="center">
                    {ficheros && ficheros.find((e) => e.id === row.id) && (
                      <IconButton onClick={() => onBorrar(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Grid item xs={12} key={row.id}>
                      <input
                        type="file"
                        onChange={(event) => onCargar(event, row.id)}
                        style={{ display: "none" }}
                        id={`file-input-${row.id}`}
                      />
                      <label htmlFor={`file-input-${row.id}`}>
                        <IconButton component="span">
                          <CloudUploadIcon />
                        </IconButton>
                      </label>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ p: 0 }} component="th" scope="row">
                    {ficheros?.find((e) => e.id === row.id)?.fichero?.name}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {Boolean(
                      ficheros?.find((e) => e.id === row.id)?.fichero
                    ) ? (
                      <Tooltip title={cad("LISTO")}>
                        <CheckIcon sx={{ color: theme.palette.success.main }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title={cad("FALTANTE")}>
                        <ClearIcon sx={{ color: theme.palette.error.main }} />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
