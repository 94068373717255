import { cfg } from "../../configuracion";

export const getResidenteIdioma = async (idUsuario, idioma, token) => {
  try {

    const url = `${cfg.apiUrl}/residente/${idUsuario}/idioma`;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": idioma, // Idioma de la respuesta
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });

    if (!resp.ok) {
      const errorData = await resp.json();
      throw new Error(
        `Error ${resp.status}: ${resp.statusText} - ${errorData.mensaje}`
      );
    }

    const data = await resp.json();

    return data.datos;
    
  } catch (error) {
    console.error("Error al obtener el idioma del residente:", error.message);
    throw new Error(
      "Error al obtener el idioma del residente: " + error.message
    );
  }
};
