export const cfg = {
  nombreApp: "Sofias Ciudadano",
  apiCliente: "web_ciudadano",
  apiSecreto: "integrador_001",
  baseUrl: "/",
  prefijoLocalStorage: "sofias.apr.",
  //apiUrl: 'http://192.168.100.119:8080/api/v1',
  //apiUrl: 'http://sofias.sicamvisiona.com/apr/api/v1',
  //apiUrl: 'http://postgres.sicamvisiona.com:8080/api/v1',
  //apiUrl: 'http://postgres.sicamvisiona.com/api/v1',
  //apiUrl: 'http://195.235.57.121:8090/api/v1',  //Cimadevilla
  //apiUrl: 'http://172.22.115.102/api/v1', //Murcia
  //apiUrl: 'http://boadilla.ciudadanos.omnivisionseguridad.com:8080/api/v1', //Boadilla
  //apiUrl: 'http://boadilla.ciudadanos.omnivisionseguridad.com/api/v1', //Boadilla pto 80
  //apiUrl: 'https://boadilla.arearesidencial.com/api/v1', //Boadilla proxy ssl OVH
  // recaptchaKey: '6LeMdF4hAAAAAJCdmK6YExmcNtxfyXXzY_M5nnQr'

  // ---------------------------------------------------------------------------------------

  versionWeb: "41",

  // NO TOCAR, variable inicializada que se especifica su valor a través del servicio "getVersionApi".
  versionAPI: "",

  // Ruta local y ruta de despliegue.
  //apiUrl: "http://localhost:8080/api/v1",
  apiUrl: '/api/v1', //relativa,
  //apiUrl: "https://pre4.sofias.pro/api/v1"

  // ---------------------------------------------------------------------------------------
};
