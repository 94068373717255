import { cfg } from "../../configuracion";

const getMotivoRechazoResidente = async (idResidente, idioma, token) => {
  if (!idResidente) return null;
  try {
    const url = `${cfg.apiUrl}/peticion/respuestaresidente/${idResidente}`;
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": idioma,
        "Authorization": `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });

    if (!resp.ok) throw new Error(`Error ${resp.status}: ${resp.statusText}`);

    const data = await resp.json();
    if (!data.ok || !data.datos) throw new Error("Error en la respuesta del servidor");
    
    console.log("%Datos:", data.datos)
    return data.datos;
  } catch (error) {
    throw error;
  }
};

export default getMotivoRechazoResidente;
