import { createTheme } from "@mui/material";
import { getPalette } from "./getPalette";

export const MainPalette = (paletaColores) => {
  const colores = getPalette(paletaColores);

  return createTheme({
    palette: {
      primary: {
        main: colores.colorPrimario,
      },
      secondary: {
        main: colores.colorSecundario,
      },
      tertiary: {
        main: colores.colorTerciario,
      },

      black: {
        main: "#2b2b3b",
        hover: "#47475b"
      },

      gray: {
        main: "#757575"
      },

      white: {
        main: "#fff",
        hover: "#e1e1e1"
      },

      success: {
        main: "#28a745",
        hover: "#218838", 
      },

      error: {
        main: "#f44336",
        hover: "#e13b2f"
      },

      pending: {
        main: "#fdd835",
      },

      button: {
        primary: {
          main: "#007BFF",
          hover: "#0056b3",
        },
        secondary: {
          main: "#FFC107",
          hover: "#FFA000",
        },

        tertiary: {
          main: "#f9f9f9",
          hover: "#008394"
        }
      }
    },
  });
};