import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Button, Box, Alert } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';

const NotFoundPage = () => {
  const theme = useTheme();

  const handleGoHome = () => {
    window.location.href = 'https://giffiles.alphacoders.com/531/53131.gif';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        padding: 3,
      }}
    >
      <Grid container item xs={12} mt={15} justifyContent="space-evenly">
          <ErrorOutlineIcon sx={{ fontSize: 80, color: '#ff1744', mb: 2 }} />
          <Typography variant="h1" component="h1" sx={{ mb: 2 }}>
            403
          </Typography>
        <Grid
          container
          item
          xs={5}
          direction="column"
          sx={{ height: 'fit-content' }}
        >
          <Grid
            container
            item
            xs={5}
            p={3}
            sx={{
              backgroundColor: theme.palette.secondary.trans,
              borderRadius: 2,
            }}
          >
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFoundPage;

