import { Link as RouterLink } from "react-router-dom";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Link,
  Alert,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grow,
  useMediaQuery,
} from "@mui/material";
import { AutLayout } from "../layout/AutLayout";
import { IdiomaContext } from "../../context/IdiomaContext";
import { AppContext } from "../../context/AppContext";
import { PerfilForm } from "../../ui/components/PerfilForm";
import saveResidente from "../services/saveResidente";
import { PublicTokenContext } from "../../context/PublicTokenContext";

export const RegistrarPage = () => {
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(null);
  const [mostrarCompletado, setMostrarCompletado] = useState(null);
  const [privacidad, setPrivacidad] = useState(false);
  const [mostrarTextoPrivacidad, setMostrarTextoPrivacidad] = useState(false);

  const { cfg, datosGenerales } = useContext(AppContext);
  const { cad, idioma } = useContext(IdiomaContext);
  const { tokenCliente } = useContext(PublicTokenContext);

  const onDatosEmitidos = async (ret) => {
    setMostrarCargando(true);
    setMostrarError(null);
    setMostrarCompletado(null);
    try {
      await saveResidente(ret, idioma, tokenCliente);
      setMostrarCompletado(cad("APR_MENSAJE_INFORMATIVO_POST_REGISTRO"));
    } catch (err) {
      console.error(err);
      setMostrarError(err.message);
    }
    setMostrarCargando(false);
  };

  const onErrorFormulario = (error) => {
    setMostrarError(error);
  };

  const onCambiaPrivacidad = ({ target }) => {
    if (target.checked) {
      setMostrarError(null);
    }
    setPrivacidad(target.checked);
  };

  const onClickPoliticaPrivacidad = (e) => {
    e.preventDefault();
    setMostrarTextoPrivacidad(true);
  };

  const onCerrarTextoPrivacidad = () => {
    setMostrarTextoPrivacidad(false);
  };

  const esMovil = useMediaQuery("(max-width:768px)");

  return (
    <AutLayout>
      <Grid container item xs={12} mt={2} justifyContent="space-evenly">
        <Grid
          item
          className={"box-shadow"}
          lg={10}
          xs={12}
          sx={{
            color: "black.main",
            backgroundColor: "white.main",
            borderColor: "secondary.main",
            borderWidth: 2,
            borderStyle: "solid",
            padding: 3,
            borderRadius: 2,
          }}
        >
          {!esMovil && (
            <Typography variant="h5" sx={{ mb: 2, color: "black.main" }}>
              {cad("REGISTRO")}
            </Typography>
          )}

          {mostrarError && (
            <Grow in={mostrarError !== null}>
              <Alert
                variant="filled"
                severity="error"
                sx={{
                  mt: 2,
                  mb: 2,
                  ml: 2,
                  mr: 2,
                  pt: 0.5,
                  pb: 0.5,
                }}
              >
                {mostrarError}
              </Alert>
            </Grow>
          )}

          {mostrarCompletado && (
            <Typography
              variant="body1"
              sx={{ mb: 2 }}
              dangerouslySetInnerHTML={{ __html: mostrarCompletado }}
            />
          )}

          {!mostrarCompletado && (
            <PerfilForm
              privacidad={privacidad}
              onEmitirDatos={onDatosEmitidos}
              onError={onErrorFormulario}
            />
          )}

          {!esMovil ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link component={RouterLink} color="inherit" to="/">
                {cad("INICIO")}
              </Link>

              {!mostrarCompletado && (
                <FormControlLabel
                  control={<Checkbox onChange={onCambiaPrivacidad} />}
                  label={
                    <p style={{ margin: 0, fontSize: "0.875rem" }}>
                      {cad("ACEPTO_LA")}
                      <Link onClick={(e) => onClickPoliticaPrivacidad(e)}>
                        {cad("POLITICA_PRIVACIDAD")}
                      </Link>
                    </p>
                  }
                  sx={{ mr: 0, mt: 0 }}
                />
              )}
            </Grid>
          ) : (
            <Grid container direction="column" alignItems="flex-start">
              {!mostrarCompletado && (
                <FormControlLabel
                  control={<Checkbox onChange={onCambiaPrivacidad} />}
                  label={
                    <p style={{ margin: 0, fontSize: "0.875rem" }}>
                      {cad("ACEPTO_LA")}
                      <Link onClick={(e) => onClickPoliticaPrivacidad(e)}>
                        {cad("POLITICA_PRIVACIDAD")}
                      </Link>
                    </p>
                  }
                  sx={{ mt: 0, mb: 2 }}
                />
              )}

              <Link component={RouterLink} color="inherit" to="/">
                {cad("INICIO")}
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item mt={10}>
        {mostrarCargando && (
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{ backgroundColor: "white.main" }}
          >
            {cad("POR_FAVOR_ESPERE")}
            <LinearProgress color="success" />
          </Alert>
        )}
      </Grid>
      <Dialog
        open={mostrarTextoPrivacidad}
        onClose={onCerrarTextoPrivacidad}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {cad("POLITICA_PRIVACIDAD")}
        </DialogTitle>
        <DialogContent dividers={false}>
          <div
            dangerouslySetInnerHTML={{
              __html: cad("APR_TEXTO_POLITICA_PRIVACIDAD"),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCerrarTextoPrivacidad}>
            {cad("HE_LEIDO_Y_ACEPTO")}
          </Button>
        </DialogActions>
      </Dialog>
    </AutLayout>
  );
};
