import { useContext, useEffect, useReducer, useState } from "react";
import { types } from "./types";
import { AppContext } from "./AppContext";
import { appReducer } from "./appReducer";
import { cfg } from "../configuracion";
import { PublicTokenContext } from "./PublicTokenContext";
import { getPalette } from "../theme/getPalette";

const solicitudDatosGenerales = async (idioma, token) => {

  let respuesta = {
    datos: null,
    error: null,
  };

  try {
    const url = cfg.apiUrl + "/datosgenerales";

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": idioma,
        Authorization: `Bearer ${token}`,

        // Deshabilitar provisionalmente la caché
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0"
      },
    });

    if (resp.status === 200) {
      let datos = await resp.json();
      respuesta = { ...respuesta, datos };
    } else {
      console.error("Error al solicitar los datos generales:", resp.status);
      respuesta = { ...respuesta, error: resp };
    }
  } catch (error) {
    console.error("Error en solicitudDatosGenerales:", error);
    respuesta = { ...respuesta, error };
  }

  return respuesta.datos;
};

const inicializar = () => {
  let estado = {
    cfg,
    datosGenerales: null,
    paletaColores: null,
  };
  return estado;
};

export const AppProvider = ({ children }) => {
  const [estado, dispatch] = useReducer(appReducer, {}, inicializar);
  const [cargandoDatosGenerales, setCargandoDatosGenerales] = useState(true);
  const { tokenCliente, isLoading: tokenLoading } =
    useContext(PublicTokenContext);

  useEffect(() => {
    if (!tokenLoading && tokenCliente) {
      const recargarDatosGenerales = async (idioma, token) => {
        setCargandoDatosGenerales(true);
        try {
          const datos = await solicitudDatosGenerales(idioma, token);

          if (datos && datos.datos) {

            // Enviamos los datos al estado global
            dispatch({
              type: types.guardarDatosGenerales,
              payload: datos,
            });

            const { paletaColores } = datos.datos;
            if (paletaColores) {
              getPalette(paletaColores);
            }
          } 
        
        } catch (error) {
          console.error("Error al cargar los datos generales:", error);
        } finally {
          setCargandoDatosGenerales(false);
        }
      };

      const idioma = "es";
      recargarDatosGenerales(idioma, tokenCliente);
    } else {
      console.error("403: Access denied");
    }
  }, [tokenCliente, tokenLoading]);

  if (cargandoDatosGenerales) {
    return;
  }

  const { paletaColores } = estado.datosGenerales || {};

  return (
    <AppContext.Provider
      value={{ ...estado, cargando: cargandoDatosGenerales, paletaColores }}
    >
      {children}
    </AppContext.Provider>
  );
};
