import { Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AutContext } from "../aut/context/AutContext";
import NotFoundPage from "./../aut/pages/NotFoundPage";
import { PublicTokenContext } from "../context/PublicTokenContext";
import { Grid } from "@mui/material";
import Spinner from "../ui/components/utils/Spinner";

export const PublicaRoute = ({ children }) => {
  const { sesionIniciada } = useContext(AutContext);
  const { tokenCliente, isLoading } = useContext(PublicTokenContext);

  if (sesionIniciada) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Spinner />
      </Grid>
    );
  }

  if (tokenCliente) {
    return children;
  }

  return <NotFoundPage />;
};
